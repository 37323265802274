import axios from 'axios';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import './index.scss';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import { SelectedItemsProvider } from './modules/agent/Util/SelectedItemsContext';

axios.defaults.baseURL = '/';
axios.defaults.withCredentials = true;

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	// <React.StrictMode>
		<Provider store={store}>
		<SelectedItemsProvider>
			<App />
		</SelectedItemsProvider>
		</Provider>
	// </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
