import { Popover, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { CartItem } from '../../../shared/models/cart';
import ItemsSummaryQuantity from '../Checkout/ItemsSummaryQuantity';
import { Container } from '../../../shared/models/container';
import './ItemsSummary.css';
import axios from 'axios';
import ItemsDetailsSummary from './ItemsDetailsSummary';

const ItemsSummary = (props: { setMessageType: any; setMessageText: any; setOpen: any }) => {
	const navigate = useNavigate();
	const { setMessageText, setMessageType, setOpen } = props;
	const cartItemsRedux: CartItem[] = useSelector((state: any) => state.cart.value);
	const [itemDetails, setItemDetails] = useState<CartItem>(new CartItem());
	const [openPopOver, setOpenPopOver] = useState(false);

	const [cartItems, setCartItems] = useState(cartItemsRedux);
	const [containerImage, setContainerImage] = useState<any>([]);

	useEffect(() => {
		setCartItems(cartItemsRedux);
	}, [cartItemsRedux, cartItems]);
	const currency = useSelector((state: any) => state.currency.value);

	useEffect(() => {
		const fetchData = async () => {
		  const transformedArray = await Promise.all(
			cartItems.map(async (item) => {
				if (item?.defaultImage !== null) {
					try {
					// Make an API call using Axios when item is not empty
					const response = await axios.get(`/api/download/${item?.defaultImage?.s3ImageName}`, {
						method: 'GET',
						responseType: 'blob'
					});
					// Return the API response data or any specific property you need
					const blob = new Blob([response.data]);
					const imageUrl = URL.createObjectURL(blob);
					return imageUrl;
					} catch (error) {
					console.error('Error fetching data:', error);
					return null; // Return null or handle error case appropriately
					}
				} else {
					return null; // Return null for empty items
				}
				})
		  );
	
		  setContainerImage(transformedArray);
		};
	
		fetchData();
	}, [cartItems]);

	const handleClose = () => {
		setOpenPopOver(false);
	};
	
	const handleItemClick = (cartItem: CartItem) => {
		setItemDetails(cartItemsRedux.find((x) => x.orderDetailsId === cartItem.orderDetailsId) ?? new CartItem());
		setOpenPopOver(true);
	};

	const getContainerImage = (index : Number) => {
		const defaultImage = <img src="/media/container-logo.png" />;
		let imageSource = '/media/container-logo.png';
		const containerS3Image = containerImage?.at(index);
		if(containerS3Image !== null){
			return <img src={containerS3Image} />
		}
		return <img src={imageSource} />;
			/* let imageUrl;
			if (displayedContainer?.defaultImage === null || displayedContainer?.defaultImage?.s3ImageName === null) {
				return defaultImage;
			} else {
				axios
					.get(`/api/download/${displayedContainer?.defaultImage?.s3ImageName}`, {
						method: 'GET',
						responseType: 'blob'
					})
					.then((response) =>{
						if(response !== null){
							const blob = new Blob([response.data]);
							imageUrl = URL.createObjectURL(blob);
						}
					})
					.catch((error) => {
						console.error('Error fetching Blob:', error);
					})
				return <img src={imageUrl} />
			} */
	}

	return (
		<div className="items">
			<Table className="items-table">
				<TableHead className="items-head">
					<TableRow className="items-row">
						<TableCell className="items-cell">Details</TableCell>
						<TableCell className="items-cell">Quantity</TableCell>
						<TableCell className="items-cell">Price</TableCell>
						<TableCell className="items-cell">Total</TableCell>
					</TableRow>
				</TableHead>
				<TableBody className="items-body">
					{cartItems &&
						cartItems?.map((cartItem: CartItem, index: number) => {
							return (
								<TableRow key={index} className="items-body-row">
									<TableCell className="items-body-cell" onClick={() => handleItemClick(cartItem)} style={{cursor: 'pointer'}}>
										{/* Left */}
										<div className="items-body-cellLeft">
											{getContainerImage(index)}
										</div>

										{/* Right */}
										<div className="items-body-cellRight">
											{/* Top */}
											<div className="items-body-cellRight-row">
												{cartItem.productType ?? ''},{' '}
												{cartItem.productSize}"{' '}
												{cartItem.productCategory},{' '}
												{cartItem.productCondition ?? ''}
											</div>

											{/* Middle */}
											<div className="items-body-cellRight-row">
												{cartItem.productCity ?? ''}
												,&nbsp;
												{cartItem.productCountry ?? ''}
											</div>
											{/* Middle */}
											<div className="items-body-cellRight-row">
												{cartItem.productDepotName}
											</div>
										</div>
									</TableCell>
									<TableCell className="items-body-cell">
										<ItemsSummaryQuantity
											cartItem={cartItem}
											setOpen={setOpen}
											setMessageText={setMessageText}
											setMessageType={setMessageType}
											setCartItems={setCartItems}
											cartItems={cartItems}
										/>
									</TableCell>
									<TableCell className="items-body-cell">
										{currency + ' '}
										{Math.round(
											cartItem?.prices?.find(
											(amount: { currency: any }) =>
												amount.currency === currency
											)?.price ?? 0
										)}
									</TableCell>
									<TableCell className="items-body-cell">
										{currency + ' '}
										{Math.round(cartItem.totals?.find(
											(amount: { currency: any }) =>
												amount.currency === currency
										)?.price ?? 0)}
									</TableCell>
								</TableRow>
							);
						})}
				</TableBody>
			</Table>

			{/* Continue Shopping */}
			<div className="items-shopping">
				<button onClick={() => navigate('/listProducts')} className="items-shopping-button">
					Continue Shopping
				</button>
			</div>
			<Popover
				open={openPopOver}
				onClose={handleClose}
				anchorReference={'none'}
				disableAutoFocus={true}
				disableEnforceFocus={true}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center'
				}}
			>
				<ItemsDetailsSummary
					itemDetails={itemDetails}
					handleClose={handleClose}
				/>
			</Popover>
		</div>
	);
};

export default ItemsSummary;
