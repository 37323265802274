import EditIcon from '@mui/icons-material/Edit';
import { debounce } from 'lodash';
import {
	Backdrop,
	Button,
	CircularProgress,
	createTheme,
	FormControl,
	InputLabel,
	MenuItem,
	Popover,
	Select,
	TextField,
	ThemeProvider
} from '@mui/material';
import axios from 'axios';
import 'chart.js/auto';
import MUIDataTable, {
	FilterType,
	MUIDataTableCustomHeadRenderer,
	SelectableRows
} from 'mui-datatables';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { Bar, Line } from 'react-chartjs-2';
import {
	getMuiTheme,
	inputTextHeaderTemplate,
	noSelectHeaderTemplate
} from '../../../shared/components/DataTable/TableUtils';
import { Company } from '../../../shared/models/company';
import { User } from '../../../shared/models/user';
import colors from '../../../shared/styles/_variables.module.scss';
import AdminLayout from '../../admin/Util/AdminLayout';
import AgentLayout from '../Util/AgentLayout';
import './ListCompanies.scss';
import UpdateCompany from './UpdateCompany';
import { useDispatch } from 'react-redux';
import { setMenuActive } from '../../../redux/features/menuActive';

const ListCompanies = () => {
	//get userinfo from session storage
	// we use this instead of redux in order to get userinfo faster
	let userInfo: any = window.sessionStorage.getItem('user');
	userInfo = JSON.parse(userInfo);
	const dispatch = useDispatch();
	dispatch(setMenuActive('five'));
	const [param, setParam] = useState({
		address: '',
		sortBy: 'name',
		sortOrder: 'asc'
	});

	const [chartParam, setChartParam] = useState({
		year: new Date().getFullYear(),
		company: '',
		address: ''
	});
	const getMuiTheme = () =>
	createTheme({
		components: {
			MuiPaper: {
				styleOverrides: {
					root: {
						boxShadow: 'none !important',
						backgroundColor: 'transparent',
						cursor: 'default !important',
					},
					elevation: {
						background: '#fafbfc'
					}
				}
			},
			MUIDataTable: {
				styleOverrides: {
					root: {
						borderRadius: '10px',
						margin: '0px 10px',
						cursor: 'default !important',
					}
				}
			},
			MuiInputBase: {
				styleOverrides: {
					root: { width: '109px', height: '35px' }
				}
			},
			MuiButton: {
				styleOverrides: {
					root: { marginRight: '10px' }
				}
			},
			MuiTableRow: {
				styleOverrides: {
					root: {cursor: 'default !important', '&:hover': { cursor: 'default !important', backgroundColor: 'transparent !important' } },
					head: {cursor: 'default !important',  '&:hover': { cursor: 'default !important', backgroundColor: 'transparent !important' } },
					footer: {cursor: 'default !important',  '&:hover': { cursor: 'default !important', backgroundColor: 'transparent !important' } }
				}
			},
			MUIDataTableBodyCell: {
				styleOverrides: {
					stackedCommon: {
						paddingTop: '10px',
						borderBottom: '0',
						height: '45px',
						justifyContent: 'center !important',
						display: 'flex',
						borderLeft: `1px solid ${colors.colorFillPrimaryRedHover}`,
						whiteSpace: 'normal',
						textOverflow: 'ellipsis',
						overflow: 'hidden'
					},
					stackedHeader: {
						display: 'none !important'
					},
					root: {
						textAlign: 'center',
						color: colors.colorFillPrimaryBlue
					}
				}
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						position: 'relative',
						borderBottom: '0',
						padding: '10px 0px !important',
						minWidth: '120px',
						maxWidth: '300px',
						width: '300px',
						verticalAlign: 'bottom'
					}
				}
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						height: '35px',
						width: '80%',
						justifyContent: 'center',
						alignSelf: 'center'
					}
				}
			},
			MUIDataTableToolbar: {
				styleOverrides: {
					root: {
						color: colors.colorFillPrimaryBlue
					}
				}
			},
			MUIDataTableBodyRow: {
				styleOverrides: {
					root: {
						cursor: 'default !important', '&:hover': { cursor: 'default !important', backgroundColor: 'transparent' },
						boxShadow: 'rgb(221, 222, 223) 5px 5px 5px'
					}
				}
			},
			MuiTablePagination: {
				styleOverrides: {
					selectLabel: {
						marginTop: 'auto'
					},
					displayedRows: {
						marginTop: 'auto'
					}
				}
			},
			MuiList: {
				styleOverrides: {
					root: {
						backgroundColor: 'white'
					}
				}
			},
			MUIDataTableSelectCell: {
				styleOverrides: {
					headerCell: {
						backgroundColor: 'transparent'
					},
					root: {
						width: '50px !important',
						maxWidth: '50px !important'
					}
				}
			},
			MuiSvgIcon: {
				styleOverrides: {
					root: {
						':hover': {
							cursor: 'pointer'
						},
						color: '#04246a'
					}
				}
			}
		}
	});
	const [companyData, setCompanyData] = useState<Company[]>([]);
	const [companyObject, setCompanyObject] = useState<Company>({});
	const [openAddPopover, setOpenAddPopover] = useState(false);
	const [openEditPopover, setOpenEditPopover] = useState(false);
	const [sorting, setSorting] = useState({ sortBy: 'name', sortOrder: 'asc' });
	const [isLoading, setIsLoading] = useState(true);
	const [pageSize, setPageSize] = useState<number>(10);
	const [totalRecords, setTotalRecords] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [chartData, setChartData] = useState<any>(null);
	const [lineChartData, setLineChartData] = useState<any>(null);

	const handleInputChange = (fieldName: string, event: any) => {
		const value = event.target.value;
		setCurrentPage(1);
		setChartParam({
			...chartParam,
			[fieldName]: value
		});
		setParam({
			...param,
			[fieldName]: value
		});
	};

	const handleYearChange = (event: any) => {
		const selectedYear = parseInt(event.target.value, 10);
		setChartParam({
		  ...chartParam,
		  year: selectedYear
		});
	};

	const onEditRule = (tableMeta: any) => {
		setCompanyObject(companyData.find((e) => e.id === tableMeta.rowData[0]) ?? {});
		setOpenEditPopover(true);
	};

	const addCompany = () => {
		setCompanyObject({});

		setOpenAddPopover(true);
	};

	const callUpdateCompany = (newCompany: Company, selectedBuyers: User[] , deselectedBuyers: User[]) => {
		setCompanyObject(newCompany);
		axios
			.put('/api/container/companies/' + newCompany.id, newCompany)
			.then(() => {
				setOpenEditPopover(false);
				getData();
			})
			.catch(() => setOpenEditPopover(false));
		deselectedBuyers?.map((user: User) => {
			axios.patch('/api/container/users/' + user.ccgId, { companyId: '' })
			.catch((err) => console.log(err));
		});
		selectedBuyers?.map((user: User) => {
			axios.patch('/api/container/users/' + user.ccgId, { companyId: newCompany.id })
			.catch((err) => console.log(err));
		});
	};

	const callAddCompany = (newVat: Company, selectedBuyers: User[]) => {
		setCompanyObject(newVat);
		axios
			.post('/api/container/companies', newVat)
			.then(() => {
				setOpenAddPopover(false);
				axios
				.get('/api/container/companies')
				.then((response) =>{
					const allCompanies = response.data;
					const filteredData = allCompanies.filter((item:any )=> item.name === newVat.name);
					selectedBuyers?.map((user: User) => {
						axios.patch('/api/container/users/' + user.ccgId, { companyId: filteredData[0].id });
					});
					getData();
				})
			})
			.catch(() => setOpenAddPopover(false));
	};

	/** get data for table with pagination */
	const getData = () => {
		let headers = undefined;
		const minValue = (currentPage - 1) * pageSize;
		const maxValue = minValue + pageSize - 1;
		headers = { range: `${minValue}-${maxValue}` };
		setIsLoading(true);
		let tempParam = param;
		if (userInfo?.role !== 'admin') {
			tempParam = {
				...param,
				address: userInfo?.location.split(',')[0]
			};
		}
		if (userInfo?.role === 'admin' || (tempParam['address'] && tempParam['address'] !== '')) {
			axios
				.get('/api/container/companies', {
					headers,
					params: tempParam,
					paramsSerializer: {
						serialize: (params) => {
							return qs.stringify(params, { arrayFormat: 'comma', encode: false });
						}
					}
				})
				.then((response) => {
					const rangeVal = response?.headers['content-range'];
					const rangeSplit = rangeVal.toString().split('/');
					const totalElements = parseInt(rangeSplit[1]);
					setTotalRecords(totalElements);
					setPageSize(10);
					setCompanyData(response.data);
					setIsLoading(false);
				})
				.catch(() => {
					setCompanyData([]);
					setIsLoading(false);
				});
		}
	};

	/** get data for charts */
	const getChartData = () => {
		setIsLoading(true);
		let tempChartParam = chartParam;
		if (userInfo?.role !== 'admin') {
			tempChartParam = {
				...chartParam,
				address: userInfo?.location.split(',')[0]
			};
		}
		if (
			userInfo?.role === 'admin' ||
			(tempChartParam['address'] && tempChartParam['address'] !== '')
		) {
			axios
				.get('/api/container/companies/dashboards', {
					params: tempChartParam,
					paramsSerializer: {
						serialize: (params) => {
							return qs.stringify(params, { arrayFormat: 'comma', encode: false });
						}
					}
				})
				.then((response) => {
					const { bookings, containers } = response.data;

					// Bar chart data
					const barChartData = {
						labels: Object.keys(containers),
						datasets: [
							{
								label: 'Number of containers bought',
								data: Object.values(containers),
								backgroundColor: 'rgba(53, 162, 235, 0.5)'
							}
						]
					};
					setChartData(barChartData);

					// Line chart data
					const lineChartData = {
						labels: Object.keys(bookings),
						datasets: [
							{
								label: 'Number of bookings',
								data: Object.values(bookings),
								borderColor: 'rgb(53, 162, 235)',
								backgroundColor: 'rgba(53, 162, 235, 0.5)'
							}
						]
					};
					setLineChartData(lineChartData);
				})
				.catch(() => {
					setIsLoading(false);
					// Bar chart data
					const barChartData = {
						labels: [],
						datasets: [
							{
								label: 'Number of containers bought',
								data: [],
								backgroundColor: 'rgba(53, 162, 235, 0.5)'
							}
						]
					};
					setChartData(barChartData);
					// Line chart data
					const lineChartData = {
						labels: [],
						datasets: [
							{
								label: 'Number of bookings',
								data: [],
								borderColor: 'rgb(53, 162, 235)',
								backgroundColor: 'rgba(53, 162, 235, 0.5)'
							}
						]
					};
					setLineChartData(lineChartData);
				});
		}
	};

	// Debounce the API call with a delay of 100 milliseconds
	const debouncedGetData = debounce(getData, 100);
	const debouncedGetChartData = debounce(getChartData, 100);

	useEffect(() => {
		setCurrentPage(1);
		setParam({
			...param,
			sortBy: sorting.sortBy,
			sortOrder: sorting.sortOrder
		});
	}, [sorting]);
	useEffect(() => {
		debouncedGetData();
		debouncedGetChartData();
	}, [param, chartParam]);

	const clearFilters = () => {
		const tempParam = {
			id: '',
			address: '',
			sortBy: 'name',
			sortOrder: 'asc'
		};
		setParam(tempParam);
		setCurrentPage(1);
		getData();
	};

	/** column definition */
	const columnDefs = [
		{
			label: 'Id',
			name: 'id',
			options:{
				display: false
			}
			/* options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					inputTextHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						param,
						handleInputChange,
						sorting,
						setSorting
					)
			} */
		},
		{
			label: 'Name',
			name: 'name',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					inputTextHeaderTemplate(columnMeta.label || '',
					columnMeta.name,
					param,
					(value) => handleInputChange('company', value),
					sorting,
					setSorting)
			}
		},
		{
			label: 'Address',
			name: 'address',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					inputTextHeaderTemplate(columnMeta.label || '',
					columnMeta.name,
					param,
					(value) => handleInputChange(columnMeta.name, value),
					sorting,
					setSorting)
			}
		},
		{
			label: 'Manage',
			name: '',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<EditIcon
							onClick={(e) => {
								e.stopPropagation();
								onEditRule(tableMeta);
							}}
						/>
					);
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '')
			}
		}
	];

	const options = {
		selectableRows: 'none' as SelectableRows,
		customToolbar: () => {
			return (
				<div>
					{/* <Button sx={{ color: colors.colorFillPrimaryBlue }}>Export</Button>
					<Button onClick={clearFilters} sx={{ color: colors.colorFillPrimaryBlue }}>
						Clear Filters
					</Button> */}
					<Button
						onClick={() => setOpenAddPopover(true)}
						sx={{
							color: 'white',
							background: colors.colorFillPrimaryBlue,
							'&:hover': {
								background: '#bb0101'
							}
						}}
						hidden={userInfo?.role === 'agent'}
					>
						Add Company
					</Button>
				</div>
			);
		},
		filterType: 'dropdown' as FilterType,
		viewColumns: false,
		print: false,
		download: false,
		search: false,
		filter: false,
		textLabels: {
			body: {
				noMatch: isLoading ? <div></div> : 'No Companies available'
			},
			pagination: {
				next: 'Next >',
				previous: '< Previous',
				rowsPerPage: 'Total items per page',
				displayRows: 'of'
			}
		},
		rowsPerPageOptions: [],
		count: totalRecords,
		serverSide: true,
		onChangePage(currentPage: number) {
			setCurrentPage(currentPage + 1);
			getData();
			getChartData();
		}
	};

	const chartOptions = {
		responsive: true,
		scales: {
			y:{
				beginAtZero:true,
			}
		},
		plugins: {
			legend: {
				display: false,
				position: 'top' as const
			},
			title: {
				display: true,
				text: 'Number of containers bought'
			}
		},
		ticks: {
			beginAtZero: true,
			precision: 0,
		},

	};

	const lineChartOptions = {
		responsive: true,
		scales: {
			y:{
				beginAtZero:true,
			}
		},
		ticks: {
			beginAtZero: true,
			precision: 0,
		},
		plugins: {
			legend: {
				display: false,
				position: 'top' as const,
			},
			title: {
				display: true,
				text: 'Number of bookings',
			},
		}
	};

	const listCompaniesTemplate: JSX.Element = (
		<>
			{isLoading && (
				<Backdrop
					sx={{
						color: colors.colorFillPrimaryBlue,
						zIndex: (theme) => theme.zIndex.drawer + 1
					}}
					open={isLoading}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
			)}
			<ThemeProvider
				theme={createTheme({
					components: {
						MuiPopover: {
							styleOverrides: {
								paper: {
									borderRadius: '20px',
									border: '1px solid #04246a',
									padding: '10px'
								}
							}
						}
					}
				})}
			>
				<Popover
					open={openAddPopover}
					onClose={() => setOpenAddPopover(false)}
					anchorReference={'none'}
					disableAutoFocus={true}
					disableEnforceFocus={true}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<UpdateCompany
						open={openAddPopover}
						setOpen={setOpenAddPopover}
						companyObject={companyObject}
						updateCompanyData={callAddCompany}
						action={'Add'}
					/>
				</Popover>
				<Popover
					open={openEditPopover}
					onClose={() => setOpenEditPopover(false)}
					anchorReference={'none'}
					disableAutoFocus={true}
					disableEnforceFocus={true}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<UpdateCompany
						open={openEditPopover}
						setOpen={setOpenEditPopover}
						companyObject={companyObject}
						updateCompanyData={callUpdateCompany}
						action={'Edit'}
					/>
				</Popover>
			</ThemeProvider>
			<div>
				{/* Title */}
				<div style={{display: 'flex', padding: '10px 0'}}>
					<p style={{ fontSize: '22px', fontWeight: '600', color: '#04246a', margin: '10px 10px 0 0' }}>
						Companies
					</p>
					<FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">Year</InputLabel>
							<Select
								required
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={chartParam.year}
								onChange={handleYearChange}
								label="Year"
								style={{ maxWidth: '100px' }}
							>
								{Array.from({ length: 2 }, (_, index) => (
									<MenuItem key={index} value={new Date().getFullYear() - index}>
										{new Date().getFullYear() - index}
									</MenuItem>
									))}
							</Select>
					</FormControl>
				</div>
				<div className="charts-container">
					<div className="chart-wrapper">
						{chartData && chartData.labels && (
							<div>
								<Bar
									data={chartData}
									options={chartOptions}
									className="doughnut-chart-buyers"
								/>
							</div>
						)}
					</div>
					<div className="chart-wrapper">
						{lineChartData && lineChartData.labels && (
							<div>
								<Line
									data={lineChartData}
									options={lineChartOptions}
									className="doughnut-chart-buyers"
								/>
							</div>
						)}
					</div>
				</div>
				<div>
					<ThemeProvider theme={getMuiTheme()}>
						<MUIDataTable
							title=""
							data={companyData}
							columns={columnDefs}
							options={options}
						/>
					</ThemeProvider>
				</div>
			</div>
		</>
	);
	return (
		<>
			{userInfo?.role === 'agent' && <AgentLayout>{listCompaniesTemplate}</AgentLayout>}
			{userInfo?.role === 'admin' && <AdminLayout>{listCompaniesTemplate}</AdminLayout>}
		</>
	);
};

export default ListCompanies;
