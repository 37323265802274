import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlined from '@mui/icons-material/EditOutlined';
import { Button, createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import axios from 'axios';
import MUIDataTable, {
	FilterType,
	MUIDataTableCustomHeadRenderer,
	SelectableRows
} from 'mui-datatables';
import qs from 'qs';
import { useEffect, useState } from 'react';
import {
	getMuiTheme,
	inputTextHeaderTemplate,
	noSelectHeaderTemplate
} from '../../../shared/components/DataTable/TableUtils';
import { AgFilterParam } from '../../../shared/models/Param';
import { Location } from '../../../shared/models/location';
import { User } from '../../../shared/models/user';
import colors from '../../../shared/styles/_variables.module.scss';
import AlertCmpt from '../../buyer/Util/AlertCmpt';
import AdminLayout from '../Util/AdminLayout';
import DeleteAgentDialog from './AdminDeleteAgentDialog';
import AdminEditAgent from './AdminEditAgent';
import './AdminManageUsers.css';
import { useDispatch } from 'react-redux';
import { setMenuActive } from '../../../redux/features/menuActive';

const AdminManageUsers = () => {
	const paramInitial = {
		role: 'agent',
		firstName: '',
		lastName: '',
		email: '',
		address: '',
		status: 'active',
		sortBy: 'USERNAME',
		sortOrder: 'asc'
	};

	const userInitialValues = {
		id: 0,
		ccgId: '',
		username: '',
		firstName: '',
		lastName: '',
		email: '',
		address: '',
		location: '',
		status: '',
		role: '',
		createdAt: '',
		updatedAt: '',
		companyId: '',
		defaultCurrency: ''
	};

	const getMuiTheme = () =>
	createTheme({
		components: {
			MuiPaper: {
				styleOverrides: {
					root: {
						boxShadow: 'none !important',
						backgroundColor: 'transparent',
						cursor: 'default !important',
					},
					elevation: {
						background: '#fafbfc'
					}
				}
			},
			MUIDataTable: {
				styleOverrides: {
					root: {
						borderRadius: '10px',
						margin: '0px 10px',
						cursor: 'default !important',
					}
				}
			},
			MuiInputBase: {
				styleOverrides: {
					root: { width: '109px', height: '35px' }
				}
			},
			MuiButton: {
				styleOverrides: {
					root: { marginRight: '10px' }
				}
			},
			MuiTableRow: {
				styleOverrides: {
					root: {cursor: 'default !important', '&:hover': { cursor: 'default !important', backgroundColor: 'transparent !important' } },
					head: {cursor: 'default !important',  '&:hover': { cursor: 'default !important', backgroundColor: 'transparent !important' } },
					footer: {cursor: 'default !important',  '&:hover': { cursor: 'default !important', backgroundColor: 'transparent !important' } }
				}
			},
			MUIDataTableBodyCell: {
				styleOverrides: {
					stackedCommon: {
						paddingTop: '10px',
						borderBottom: '0',
						height: '45px',
						justifyContent: 'center !important',
						display: 'flex',
						borderLeft: `1px solid ${colors.colorFillPrimaryRedHover}`,
						whiteSpace: 'normal',
						textOverflow: 'ellipsis',
						overflow: 'hidden'
					},
					stackedHeader: {
						display: 'none !important'
					},
					root: {
						textAlign: 'center',
						color: colors.colorFillPrimaryBlue
					}
				}
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						position: 'relative',
						borderBottom: '0',
						padding: '10px 0px !important',
						minWidth: '120px',
						maxWidth: '300px',
						width: '300px',
						verticalAlign: 'bottom'
					}
				}
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						height: '35px',
						width: '80%',
						justifyContent: 'center',
						alignSelf: 'center'
					}
				}
			},
			MUIDataTableToolbar: {
				styleOverrides: {
					root: {
						color: colors.colorFillPrimaryBlue
					}
				}
			},
			MUIDataTableBodyRow: {
				styleOverrides: {
					root: {
						cursor: 'default !important', '&:hover': { cursor: 'default !important', backgroundColor: 'transparent' },
						boxShadow: 'rgb(221, 222, 223) 5px 5px 5px'
					}
				}
			},
			MuiTablePagination: {
				styleOverrides: {
					selectLabel: {
						marginTop: 'auto'
					},
					displayedRows: {
						marginTop: 'auto'
					}
				}
			},
			MuiList: {
				styleOverrides: {
					root: {
						backgroundColor: 'white'
					}
				}
			},
			MUIDataTableSelectCell: {
				styleOverrides: {
					headerCell: {
						backgroundColor: 'transparent'
					},
					root: {
						width: '50px !important',
						maxWidth: '50px !important'
					}
				}
			},
			MuiSvgIcon: {
				styleOverrides: {
					root: {
						':hover': {
							cursor: 'pointer'
						},
						color: '#04246a'
					}
				}
			}
		}
	});

	const [param, setParam] = useState<AgFilterParam>(paramInitial);
	const [sorting, setSorting] = useState({ sortBy: 'USERNAME', sortOrder: 'asc' });
	const [users, setUsers] = useState<User[]>([]);
	const [selectedUser, setSelectedUser] = useState<User>(userInitialValues);
	const [isLoading, setIsLoading] = useState(true);
	const [open, setOpen] = useState(false);
	const [messageType, setMessageType] = useState('');
	const [messageText, setMessageText] = useState('');
	const [openPopOver, setOpenPopOver] = useState(false);
	const [pageSize, setPageSize] = useState<number>(10);
	const [totalRecords, setTotalRecords] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [confirmed, setConfirmed] = useState(false);
	
	const dispatch = useDispatch();
	dispatch(setMenuActive('two'));

	useEffect(() => {
		getUsers(param);
	}, [param]);

	useEffect(() => {
		setCurrentPage(1);
		setParam({
			...param,
			sortBy: sorting.sortBy,
			sortOrder: sorting.sortOrder
		});
	}, [sorting]);

	const handleRowClick = (rowData: any) => {
		setOpenPopOver(true);
	};

	const clearFilters = () => {
		setParam(paramInitial);
	};

	const handleDepotChange = (depots: Location[]) => {
		const body = depots.map((depot) => {
			return { depot: depot.depot };
		});

		axios.put('/api/container/locations/agents/' + selectedUser.ccgId ?? '', body);
	};

	const handleDeleteAgent = () => {
		setParam(paramInitial);
	};

	const options = {
		selectableRows: 'none' as SelectableRows,
		customToolbar: () => {
			return (
				<div>
					{/* <Button sx={{ color: colors.colorFillPrimaryBlue }}>Export</Button> */}
					{/* <Button onClick={clearFilters} sx={{ color: colors.colorFillPrimaryBlue }}>
						Clear Filters
					</Button> */}
				</div>
			);
		},
		filterType: 'dropdown' as FilterType,
		viewColumns: false,
		print: false,
		download: false,
		search: false,
		filter: false,
		/* onRowClick: (rowData: any) => handleRowClick(rowData), */
		textLabels: {
			body: {
				noMatch: isLoading ? <div></div> : 'No Agents Available'
			}
		},
		rowsPerPageOptions: [],
		count: totalRecords,
		serverSide: true,
		onChangePage(currentPage: number) {
			setCurrentPage(currentPage + 1);
		}
	};

	const handleInputTextChange = (event: any) => {
		const name = event.target.name;
		const filterTemp = { ...param, [name]: event.target.value };
		setParam(filterTemp);
		setCurrentPage(1);
		getUsers(filterTemp);
	};

	const columnDefs = [
		{
			label: '',
			name: 'firstName',
			options: {
				display: false
			}
		},
		{
			label: '',
			name: 'lastName',
			options: {
				display: false
			}
		},
		{
			label: 'Full Name',
			name: 'username',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '')
			}
		},
		{
			label: 'Email',
			name: 'email',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '')
			}
		},
		{
			label: 'Address',
			name: 'address',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '')
			}
		},
		{
			label: 'Actions',
			name: 'actions',
			options:{
				display:false
			}
			/* options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<div>
							<EditOutlined
								sx={{ marginRight: '10px' }}
								onClick={(e) => {
									e.stopPropagation();
									onEditRow(tableMeta);
								}}
							/>
							<DeleteOutlineIcon
								onClick={(e) => {
									e.stopPropagation();
									setSelectedUser(users[tableMeta?.rowIndex] ?? null);
									setConfirmed(true);
								}}
							/>
						</div>
					);
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '')
			} */
		}
	];

	const onEditRow = (rowMetaData: any) => {
		setOpenPopOver(true);
		const user = users[rowMetaData.rowIndex] ?? null;

		setSelectedUser(user);
	};

	const getUsers = (filterTemp: any) => {
		let headers = undefined; 
		let typeHeaders = {type: 'application/json'};
		const minValue = (currentPage - 1) * pageSize;
		const maxValue = minValue + pageSize - 1;
		headers = { range: `${minValue}-${maxValue}` };
		axios
			.get('/api/container/users', {
				params: filterTemp,
				headers,
				paramsSerializer: {
					serialize: (params) => {
						return qs.stringify(params, { arrayFormat: 'comma', encode: false });
					}
				}
			})
			.then((response) => {
				const rangeVal = response?.headers['content-range'];
				const rangeSplit = rangeVal.toString().split('/');
				const totalElements = parseInt(rangeSplit[1]);
				setTotalRecords(totalElements);
				setPageSize(10);
				setUsers(response.data);
				setIsLoading(false);
			})
			.catch(() => {
				setUsers([]);
				setIsLoading(false);
			});
	};

	return (
		<AdminLayout>
			{openPopOver && (
				<AdminEditAgent
					open={openPopOver}
					setOpen={setOpenPopOver}
					user={selectedUser}
					updateAgentDepots={handleDepotChange}
				/>
			)}
			<div className="adminManageUsers">
				{/* Title */}
				<div className="adminManageUsers-top">
					<p>List of Agents</p>
				</div>
				<div>
					<AlertCmpt
						open={open}
						setOpen={setOpen}
						messageType={messageType}
						messageText={messageText}
					/>

					<div>
						<ThemeProvider theme={getMuiTheme()}>
							<MUIDataTable
								title={''}
								data={users}
								columns={columnDefs}
								options={options}
							/>
						</ThemeProvider>
					</div>
				</div>
			</div>
			<DeleteAgentDialog
				openDialog={confirmed}
				setOpenDialog={setConfirmed}
				handleAction={handleDeleteAgent}
				userName={selectedUser?.firstName + ' ' + selectedUser?.lastName}
			/>
		</AdminLayout>
	);
};

export default AdminManageUsers;
