import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextField,
	Typography
} from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import { NotificationTypeEnum } from '../../../shared/models/notificationType';
import { useCallOrdersNotificationApi } from '../../../shared/util/SharedMethods';
import AlertCmpt from '../../../modules/buyer/Util/AlertCmpt';

const CancelBookingConfirmation = (props: any) => {
	const { confirmed, setConfirmed, clickedOrderId, agentUid, getData } = props;
	const [reason, setReason] = useState('');
	const [messageType, setMessageType] = useState('');
	const [messageText, setMessageText] = useState('');
	const [open, setOpen] = useState(false);
	const maxCharacters = 255;

	const handleClose = () => {
		setReason('');
		setConfirmed(false);
	};

	function confirmDelete() {
		if (reason === null || reason === ''){
			setMessageType('error');
			setMessageText('Please specify a reason');
			setOpen(true);

		}else{
			axios
				.put('/api/container/orders/' + clickedOrderId, {
						cancelledBy: agentUid,
						cancelReason: reason,
						status: 'cancelled'
				})
				.then(() => {
					setReason('');
					setConfirmed(false);
					getData();
					useCallOrdersNotificationApi(NotificationTypeEnum.ORDERCANCELLED, clickedOrderId);
				})
				.catch((err) => {
					handleClose();
					setMessageType('error');
					setMessageText(err.response.data.description ?? 'Failed to delete the order');
					setOpen(true);

				});
			}
	}
	const changeReason = (e: any) => {
		setReason(e.target.value);
	};

	return (
		<div>
			<AlertCmpt
				open={open}
				setOpen={setOpen}
				messageType={messageType}
				messageText={messageText}
			/>
			<Dialog
				open={confirmed}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				fullWidth
				maxWidth="sm"
				PaperProps={{
				  sx: {
					minWidth: '500px', padding: '10px'
				  },
				}}
			>
				<DialogTitle id="alert-dialog-title">
					{'Are you sure you want to delete this booking?'}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						You will be deleting order number {clickedOrderId}
					</DialogContentText>
					<TextField
						required
						id="reason"
						label="Reason"
						value={reason}
						onChange={(e) => {
							if (e.target.value.length <= maxCharacters) {
								setReason(e.target.value);
							}
						}}
						style={{ marginTop: '15px', width: '100%' }}
						multiline
						rows={6}
					/>
					<Typography color={reason.length > maxCharacters ? 'error' : 'textSecondary'}
						style={{ marginBottom: '15px', fontSize: '13px' }}>
						{reason.length}/{maxCharacters} characters
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={confirmDelete} autoFocus>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default CancelBookingConfirmation;
