import axios from 'axios';
import { NotificationTypeEnum } from '../models/notificationType';

export const useShouldBeChecked = (item: any, filter: any): boolean => {
	if (filter === undefined || filter.length === 0) return false;

	return filter.find((element: any) => {
		return element.toLowerCase() === item.toLowerCase();
	});
};

export const useCallOrdersNotificationApi = (
	notificationType: NotificationTypeEnum,
	orderId: number
) => {
	axios
		.post(`/api/notifications/orders/${orderId}`, { notificationType: notificationType })
		.then((result: any) => {
			console.log(result);
		})
		.catch((err: any) => {
			console.log(err);
		});
};
