import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export interface Types {
	containerType: string
}

export interface TypesState {
	types: Types[];
    isLoading: boolean;
    error: string;
}

const initialState: TypesState = {
	types: [] as Types[],
	isLoading: false,
	error: '',
};


export const fetchTypes = createAsyncThunk(
	'types/fetchTypes',
	async () => {
		const response = await axios.get(`/api/container/referentials/types`);
		const { data } = response;
		return data;
	}
);

export const typesSlice = createSlice({
	name: 'types',
	initialState,
	reducers: {
	},
	extraReducers: (builder) => {
		builder.addCase(fetchTypes.pending, (state) => {
			return {
				...state,
				error: '',
				isLoading: true
			};
		});
		builder.addCase(fetchTypes.fulfilled, (state, action) => {
			return {
				...state,
				isLoading: false,
				types: action.payload,
			};
		});
		builder.addCase(fetchTypes.rejected, (state, action) => {
			return {
				...state,
				isLoading: false,
				error: action.error.message !== undefined ? action.error.message : 'Error'
			};
		});
	}

});
export default typesSlice.reducer;
