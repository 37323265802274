import { Backdrop, CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import { debounce } from 'lodash';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import AdminLayout from '../../../modules/admin/Util/AdminLayout';
import AgentLayout from '../../../modules/agent/Util/AgentLayout';
import AlertCmpt from '../../../modules/buyer/Util/AlertCmpt';
import colors from '../../../shared/styles/_variables.module.scss';
import ListContainers from '../../components/ContainerHandlers/ListContainers';
import ViewChosenContainers from '../../components/ContainerHandlers/ViewChosenContainers';
import FilesTab from '../../components/EditOrders/FilesTab';
import { CartItem } from '../../models/cart';
import { Container } from '../../models/container';
import { Document } from '../../models/document';
import { Product } from '../../models/product';
import './ManageOrder.css';
import { useDispatch } from 'react-redux';
import { setMenuActive } from '../../../redux/features/menuActive';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}
const ManageOrders = () => {
	const [value, setValue] = React.useState(0);
	const { state } = useLocation();
	const user: any = state;
	const [saved, setIsSaved] = useState(true);
	const [status, setStatus] = useState('');
	const params = useParams();
	const [orderDetails, setOrderDetails] = useState<Array<CartItem>>([]);
	const [productsData, setProductsData] = useState<
		Array<{
			orderQuantity: number;
			orderDetailsId: number;
			product: Product;
		}>
	>([]);
	const [containerByProductMap, setContainerByProductMap] = useState<Map<number, Container[]>>(
		new Map()
	);
	const [bookedContainerArrayByProductId, setBookedContainerArrayByProductId] = useState<
		Map<number, Container[]>
	>(new Map());
	const [bookedContainersByOrderDetailsId, setBookedContainersByOrderDetailsId] = useState<
		Container[]
	>([]);
	const [open, setOpen] = useState(false);
	const [messageType, setMessageType] = useState('');
	const [messageText, setMessageText] = useState('');
	const [isLoading, setIsLoading] = useState(true);
	const [documents, setDocuments] = useState(Array<Document>());

	const uniqueById = (items: any[], id: string) => {
		const set = new Set();
		return items.filter((item: any) => {
			const isDuplicate = set.has(item[id]);
			set.add(item[id]);
			return !isDuplicate;
		});
	};
	
	const dispatch = useDispatch();
	if (user?.role === 'admin') {
		dispatch(setMenuActive('three'));
	}
	else{
		dispatch(setMenuActive('four'));
	}

	useEffect(() => {
		axios
			.get('/api/container/orders', { params: { orderId: params.orderId }})
			.then((response) => {
				setStatus(response.data[0].status);
			})
			.catch((e) => console.log(e));
	}, []);

	useEffect(() => {
		axios
			.get(`/api/container/orderDetails?orderId=${params.orderId}`)
			.then((response) => {
				setOrderDetails(response.data);
			})
			.catch((e) => console.log(e));
	}, [params]);

	useEffect(() => {
		const orderDetailsIds: any[] = [];
		orderDetails.map((orderDetail: CartItem) => {
			if (
				orderDetail.productId !== undefined ||
				orderDetail.productId !== -1 ||
				orderDetail.productId !== null
			) {
				orderDetailsIds.push(orderDetail.orderDetailsId);
				axios
					.get('/api/container/products', {
						params: { productId: orderDetail.productId ?? -1 },
						paramsSerializer: {
							serialize: (params) => {
								return qs.stringify(params, {
									arrayFormat: 'comma',
									encode: false
								});
							}
						}
					})
					.then((response) => {
						setProductsData((prev) => {
							return uniqueById(
								[
									...prev,
									{
										orderQuantity: orderDetail.quantity ?? 0,
										orderDetailsId: orderDetail.orderDetailsId ?? -1,
										product: response.data[0]
									}
								],
								'orderDetailsId'
							);
						});
					})
					.catch((e) => {
						console.log(e);
					});
			}
		});
		getBookedContainersByOrderDetailsId(orderDetailsIds);
	}, [orderDetails, saved]);

	const getData = () => {
		productsData.map((product: { orderDetailsId: number; product: Product }) => {
			if (product.product.id !== undefined && product.product.id !== -1) {
				const param = {
					productId: product?.product?.id ?? -1,
					orderId: params?.orderId,
					status: ['booked,available']
				};
				axios
					.get('/api/container/containers', {
						params: param,
						paramsSerializer: {
							serialize: (param) => {
								return qs.stringify(param, {
									arrayFormat: 'comma',
									encode: false
								});
							}
						}
					})
					.then((response) => {
						setIsLoading(false);
						setContainerByProductMap((prev) => {
							return prev.set(product.product.id ?? -1, [...response.data]);
						});
						const bookedContainers = response.data.filter((container: Container) => {
							return container.status?.toLowerCase() === 'booked';
						});

						if (bookedContainers.length > 0) {
							setBookedContainerArrayByProductId((prev) => {
								return prev.set(
									product.product.id ?? -1,
									uniqueById(
										[
											...(prev.get(product.product.id ?? -1) ?? []),
											...bookedContainers
										],
										'id'
									)
								);
							});
						}
					})
			}
		});
		//setIsLoading(false);
	};
	

	// Debounce the API call with a delay of 100 milliseconds
	const debouncedGetData = debounce(getData, 100);
	useEffect(() => {
		debouncedGetData();
	}, [productsData]);

	const getBookedContainersByOrderDetailsId = (orderDetailsIds: any[]) => {
		setIsLoading(true);
		if (orderDetailsIds.length > 0) {
			const params = {
				orderDetailsIds: orderDetailsIds
			};
			axios
				.get('/api/container/containers', {
					params: params,
					paramsSerializer: {
						serialize: (params) => {
							return qs.stringify(params, {
								arrayFormat: 'comma',
								encode: false
							});
						}
					}
				})
				.then((response) => {
					setBookedContainersByOrderDetailsId(response.data);
					setIsLoading(false);
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};

	useEffect(() => {
		getDocuments();
	}, [bookedContainersByOrderDetailsId]);

	const getDocuments = () => {
		//setIsLoading(true);

		axios
			.get(`api/documents/documents?orderId=${params.orderId}`)
			.then((response) => {
				setDocuments(response.data);
				//setIsLoading(false);
			})
			.catch(() => {
				//setIsLoading(false);
				console.log('Could not get documents');
			});
	};

	function a11yProps(index: number) {
		return {
			id: `simple-tab-${index}`,
			'aria-controls': `simple-tabpanel-${index}`
		};
	}
	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};

	function TabPanel(props: TabPanelProps) {
		const { children, value, index, ...other } = props;

		return (
			<div
				role="tabpanel"
				hidden={value !== index}
				id={`simple-tabpanel-${index}`}
				aria-labelledby={`simple-tab-${index}`}
				{...other}
				style={{ flex: '1' }}
			>
				{value === index && (
					<Box sx={{ flex: '1', height: '100%' }}>
						<Typography sx={{ height: '100%' }}>{children}</Typography>
					</Box>
				)}
			</div>
		);
	}

	const manageOrderTemplate: JSX.Element = (
		<div className="manageOrder">
			<AlertCmpt
				open={open}
				setOpen={setOpen}
				messageType={messageType}
				messageText={messageText}
			/>
			{isLoading && (
				<Backdrop
					sx={{
						color: colors.colorFillPrimaryBlue,
						zIndex: (theme) => theme.zIndex.drawer + 1
					}}
					open={isLoading}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
			)}
			{/* Title */}
			<div className="manageOrder-top">
				<p>Manage Order #{params.orderId}</p>
			</div>

			{/* Content */}
			<div className="manageOrder-bottom">
				<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
					<Tabs
						value={value}
						onChange={handleChange}
						aria-label="basic tabs example"
						TabIndicatorProps={{ style: { background: '#04246a' } }}
					>
						<Tab label="Containers" className="manageOrder-tab" {...a11yProps(0)} />
						<Tab label="Files" className="manageOrder-tab" {...a11yProps(1)} />
					</Tabs>
				</Box>
				<TabPanel value={value} index={0}>
					<>
						{!saved ? (
							<ListContainers
								productsData={productsData}
								orderDetails={orderDetails}
								orderId={params.orderId}
								bookedContainerArrayByProductId={bookedContainerArrayByProductId}
								containerByProductMap={containerByProductMap}
								setIsSaved={setIsSaved}
								user={user}
								setOpen={setOpen}
								setMessageType={setMessageType}
								setMessageText={setMessageText}
								uniqueById={uniqueById}
								getData={getData}
							/>
						) : (
							<ViewChosenContainers
								bookedContainersByOrderDetailsId={bookedContainersByOrderDetailsId}
								setIsSaved={setIsSaved}
								status = {status}
							/>
						)}
					</>
				</TabPanel>
				<TabPanel value={value} index={1}>
					<FilesTab
						documents={documents}
						setIsLoading={setIsLoading}
						getDocuments={getDocuments}
						orderId={params?.orderId}
						status = {status}
					/>
				</TabPanel>
			</div>
		</div>
	);

	return (
		<>
			{user.role === 'agent' && <AgentLayout>{manageOrderTemplate}</AgentLayout>}
			{user.role === 'admin' && <AdminLayout>{manageOrderTemplate}</AdminLayout>}
		</>
	);
};

export default ManageOrders;
