import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { setLoggedInUser } from '../../../redux/features/user';
import Nav from '../../../shared/components/Navigation/Nav';
import Footer from '../../../shared/layout/Footer/Footer';
import { User } from '../../../shared/models/user';
import Menu from './Menu';
import './admin.scss';

const AdminLayout = (props: any) => {

	return (
		<div>
			<Nav />
			<div
				className="container-fluid"
				style={{
					backgroundColor: '#f8f9fa',
					position: 'relative'
				}}
			>
				<div style={{ marginTop: '70px' }}>
					<Menu />
					<main className="col-md-9 ms-sm-auto col-lg-10" style={{ minHeight: '80vh' }}>
						<p>{props.title}</p>
						<div>{props.children}</div>
					</main>
				</div>
			</div>
			<Footer />
		</div>
	);
};

export default AdminLayout;
