import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export interface Categories {
	category: string;
}

export interface CategoriesState {
	categories: Categories[];
    isLoading: boolean;
    error: string;
}

const initialState: CategoriesState = {
	categories: [] as Categories[],
	isLoading: false,
	error: '',
};


export const fetchCategories = createAsyncThunk(
	'categories/fetchCategories',
	async () => {
		const response = await axios.get(`/api/container/referentials/categories`);
		const { data } = response;
		return data;
	}
);

export const categoriesSlice = createSlice({
	name: 'categories',
	initialState,
	reducers: {
	},
	extraReducers: (builder) => {
		builder.addCase(fetchCategories.pending, (state) => {
			return {
				...state,
				error: '',
				isLoading: true
			};
		});
		builder.addCase(fetchCategories.fulfilled, (state, action) => {
			return {
				...state,
				isLoading: false,
				categories: action.payload,
			};
		});
		builder.addCase(fetchCategories.rejected, (state, action) => {
			return {
				...state,
				isLoading: false,
				error: action.error.message !== undefined ? action.error.message : 'Error'
			};
		});
	}

});
export default categoriesSlice.reducer;
