import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useNavigate } from 'react-router-dom';
import { setLoggedInUser } from '../../../redux/features/user';
import Nav from '../../../shared/components/Navigation/Nav';
import Footer from '../../../shared/layout/Footer/Footer';
import { User } from '../../../shared/models/user';
import BuyerDashboard from './BuyerDashboard';

const BuyerLayout = (props?: any) => {
	return (
		<div
			style={{
				backgroundColor: '#f8f9fa',
				position: 'relative'
			}}
		>
			<Nav />

			<main style={{ minHeight: '80vh' }}>
				<div style={{ marginTop: '65px' }}>{props.children}</div>
			</main>
			<Footer />
		</div>
	);
};

export default BuyerLayout;
