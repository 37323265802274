import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Button, Grid, Popover, ThemeProvider, createTheme } from '@mui/material';
import axios from 'axios';
import { useState } from 'react';
import UploadFilesPopover from '../../../modules/buyer/OrderManagement/UploadFilesPopover';
import colors from '../../../shared/styles/_variables.module.scss';
import { Document } from '../../models/document';
import { NotificationTypeEnum } from '../../models/notificationType';
import { useCallOrdersNotificationApi } from '../../util/SharedMethods';
import FileConfirmationDialog from './FileConfirmationDialog';
import './FilesTab.css';

type TriggerType = 'delete' | 'validate';

const FilesTab = (props: {
	getDocuments: any;
	documents: Array<Document>;
	setIsLoading: any;
	orderId: any;
	status: string;
}) => {
	const { orderId, documents, getDocuments, setIsLoading, status } = props;
	let disabled = false;
	if(status === 'Closed'){
		disabled = true
	}
	let userData: any = window.sessionStorage.getItem('user');
	userData = JSON.parse(userData);
	const [openUploadPopover, setOpenUploadPopover] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [trigger, setTrigger] = useState<TriggerType>();
	const [currentDocument, setCurrentDocument] = useState<Document>();
	const validatePayment = (doc: Document) => {
		setIsLoading(true);
		const documentId = doc.id;
		const url = `/api/documents/documents/${documentId}/status`;

		axios
			.patch(url, {
				status: 'Validated',
				userCcgid: userData?.ccgId
			})
			.then(() => {
				getDocuments();

				const orderUrl = '/api/container/orders/' + orderId;

				const body = {
					paymentStatus: 'Validated'
				};
				axios.put(orderUrl, body);

				if (doc?.documentType?.name?.toLowerCase() === 'payment proof')
					useCallOrdersNotificationApi(NotificationTypeEnum.PAYMENTVALIDATED, orderId);
				else if (doc?.documentType?.name?.toLowerCase() === 'custom clearance')
					useCallOrdersNotificationApi(
						NotificationTypeEnum.CUSTOMSCLEARANCEVALIDATED,
						orderId
					);

				setIsLoading(false);
			})
			.catch(() => {
				setIsLoading(false);
			});
	};

	const deleteDocument = (doc: Document) => {
		const documentId = doc.id;
		const docUrl = `/api/documents/documents/${documentId}/status`;
		axios
			.patch(docUrl, {
				status: 'Deleted',
				userCcgid: userData?.ccgId
			})
			.then(() => {
				getDocuments();

				const orderUrl = '/api/container/orders/' + doc.orderId;

				const body = {
					paymentStatus: 'none'
				};
				axios.put(orderUrl, body);
				// send notification that Payment proof not validated
				if (doc?.documentType?.name?.toLowerCase() === 'payment proof')
					useCallOrdersNotificationApi(NotificationTypeEnum.PAYMENTNOTVALIDATED, orderId);
				else if (doc?.documentType?.name?.toLowerCase() === 'custom clearance')
					useCallOrdersNotificationApi(
						NotificationTypeEnum.CUSTOMSCLEARANCENOTVALIDATED,
						orderId
					);

				setIsLoading(false);
			})
			.catch(() => {
				setIsLoading(false);
			});
	};

	const handleDocumentDownload = async (doc: Document) => {
		console.log('s3DocumentName', doc.s3DocumentName)
		await axios
				.get(`/api/download/${doc.s3DocumentName}`, {
					method: 'GET',
					responseType: 'blob'
				})
				.then((response) =>{
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = window.document.createElement('a');
					link.href = url;
					link.setAttribute(
						'download',
						doc.name ?? ''
					);

					// Append to html link element page
					document.body.appendChild(link);

					// Start download
					link.click();
					// Clean up and remove the link
					link?.parentNode?.removeChild(link);
				});
	};

	const handleTrigger = (type: TriggerType) => {
		setTrigger(type);
	};

	const handleAction = () => {
		if (currentDocument) {
			switch (trigger) {
				case 'delete':
					deleteDocument(currentDocument);
					break;
				case 'validate':
					validatePayment(currentDocument);
					break;
				default:
					// Default case when trigger is undefined or not one of the expected types
					break;
			}
		}
	};

	return (
		<div className="filesTab">
			<div className="filesTab-top">
				<ThemeProvider
					theme={createTheme({
						components: {
							MuiPopover: {
								styleOverrides: {
									paper: {
										borderRadius: '20px',
										border: '1px solid #04246a',
										padding: '10px'
									}
								}
							}
						}
					})}
				>
					<Popover
						open={openUploadPopover}
						onClose={() => {
							getDocuments();
							setOpenUploadPopover(false);
						}}
						anchorReference={'none'}
						disableAutoFocus={true}
						disableEnforceFocus={true}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<UploadFilesPopover
							orderId={orderId}
							handleClose={() => {
								getDocuments();
								setOpenUploadPopover(false);
							}}
							profileUser={userData}
							downloadDocument={handleDocumentDownload}
						/>
					</Popover>
				</ThemeProvider>
				<div className="filesTab-top-row">
					<p>Uploaded Files</p>

					<Button
						disabled = {disabled}
						onClick={() => setOpenUploadPopover(true)}
						className="filesTab-top-row"
						sx={{ display: 'flex', maxWidth: '200px' }}
					>
						<p>Upload Document</p>
						<UploadFileIcon
							sx={{
								color: colors.colorFillPrimaryBlue
							}}
						/>
					</Button>
				</div>
				{documents?.map((doc: Document) => (
					<>
						<div className="filesTab-top-row">
							<a
								style={{ cursor: 'pointer' }}
								onClick={() => {
									handleDocumentDownload(doc);
								}}
							>
								{doc.name}
							</a>
						</div>
						<div className="filesTab-top-row">
							{doc &&
							doc.status === 'Uploaded' &&
							(doc?.documentType?.name?.toLowerCase() === 'payment proof' ||
								doc?.documentType?.name?.toLowerCase() === 'custom clearance') ? (
								/* Case 1- Payment Proof request */
								<Grid className="PActionBar" container>
									{/* Validate Payment Button */}
									<Grid item xs={3}>
										<Button
											variant="outlined"
											className="filesTab-bottom-validateBtn"
											// type="button"
											// className="btn btn-primary"
											onClick={() => {
												handleTrigger('validate');
												setCurrentDocument(doc);
												setOpenDialog(true);
											}}
										>
											Validate {doc?.documentType?.name}
										</Button>
									</Grid>

									{/* Ask for Re-upload Button */}
									<Grid item xs={2}>
										<Button
											variant="outlined"
											className="filesTab-bottom-reuploadBtn"
											// type="button"
											// className="btn btn-primary"
											onClick={() => {
												handleTrigger('delete');
												setCurrentDocument(doc);
												setOpenDialog(true);
											}}
										>
											Ask for Re-upload
										</Button>
									</Grid>
								</Grid>
							) : /* Case 1- Payment Proof accepted / Reuploaded */
							doc &&
							  (doc?.documentType?.name?.toLowerCase() === 'payment proof' ||
									doc?.documentType?.name?.toLowerCase() === 'custom clearance') ? (
								doc.status == 'Validated' ? (
									<span className="lblAccepted filesTab-row-validateStatus">
										Validated
									</span>
								) : (
									<span className="lblReupload filesTab-row-reuploadStatus">
										Pending Buyer Reupload
									</span>
								)
							) : (
								<></>
							)}
						</div>
					</>
				))}

				<FileConfirmationDialog
					openDialog={openDialog}
					setOpenDialog={setOpenDialog}
					handleAction={handleAction}
					currentDocumentName={currentDocument?.documentType?.name ?? ''}
					action={trigger}
				/>
			</div>
		</div>
	);
};

export default FilesTab;
