import { Grid } from '@mui/material';
import './Footer.scss';
import { FooterList } from './data/FooterList';

const Footer = () => {
	const downloadTermsAndConditions = () => {
		// using Java Script method to get PDF file
		fetch('/terms-and-conditions.pdf', {
			headers: {
				'Content-Type': 'application/pdf',
				Accept: 'application/pdf'
			}
		}).then((response) => {
			if (response.status !== 404) {
				response.blob().then((blob) => {
					// Creating new object of PDF file
					const fileURL = window.URL.createObjectURL(blob);
					// Setting various property values
					const alink = document.createElement('a');
					alink.href = fileURL;
					alink.download = 'General Terms and Conditions of Sale CMACGM.pdf';
					alink.click();
				});
			}
		});
	};

	const year = new Date().getFullYear();
	const footerList = FooterList.map(({ url, icon }, index) => {
		return (
			<li key={index} className="footer-item">
				<a href={url} target="_blank" className="footer-item-link footer-icons">
					{icon}
				</a>
			</li>
		);
	});

	return (
		<Grid
			container
			item
			display="flex"
			direction="column"
			alignItems="center"
			justifyContent="center"
			sx={{
				paddingLeft: { md: '20px!important' },
				width: '100%',
				borderTop: '2px solid #f3f5f7',
				height: '70px !important',
				backgroundColor: 'white',
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between'
			}}
		>
			<div className="footer">
				<div className="footer-left">
					<a
						onClick={downloadTermsAndConditions}
						style={{
							color: '#04246a',
							marginBottom: '1px'
						}}
						className="terms-condition"
					>
						Terms and conditions
					</a>
					&nbsp;&nbsp;&nbsp;
					<a
						target="_blank"
						rel="noopener"
						href="https://www.cma-cgm.com/legal-terms/privacy-policy"
						style={{
							color: '#04246a',
							marginBottom: '10px'
						}}
						className="terms-condition"
					>
						Privacy Notice
					</a>
					&nbsp;&nbsp;&nbsp;
					<a
						target="_blank"
						rel="noopener"
						href="https://www.cma-cgm.com/legal-terms"
						style={{
							color: '#04246a',
							marginBottom: '10px'
						}}
						className="terms-condition"
					>
						Legal Terms
					</a>
				</div>
				<div className="footer-middle">
					<a
						target="_blank"
						rel="noopener"
						href="https://www.cmacgm-group.com"
						style={{
							color: '#04246a',
							marginBottom: '10px'
						}}
						className="terms-condition"
					>
						Visit our corporate website
					</a>
					<div className="footer-bottom">
						<p>© {year} CMA CGM Group</p>
					</div>
				</div>
				<div className="footer-right">
					{/* <p>Get Connected:</p> */}
					<ul className="footer-right-list">{footerList}</ul>
				</div>
			</div>
		</Grid>
	);
};

export default Footer;
