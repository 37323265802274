import { Box, Button, CircularProgress, Modal, ThemeProvider, createTheme } from '@mui/material';
import axios from 'axios';
import { debounce } from 'lodash';
import MUIDataTable, {
	DisplayData,
	FilterType,
	MUIDataTableCustomHeadRenderer,
	SelectableRows
} from 'mui-datatables';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminLayout from '../../../modules/admin/Util/AdminLayout';
import AgentLayout from '../../../modules/agent/Util/AgentLayout';
import {
	dateHeaderTemplate,
	getMuiTheme,
	inputTextHeaderTemplate,
	multipleInputHeaderTemplate,
	noSelectHeaderTemplate,
	selectHeaderTemplate
} from '../../../shared/components/DataTable/TableUtils';
import { SaleHistory } from '../../../shared/models/salesHistory';
import { User } from '../../../shared/models/user';
import colors from '../../../shared/styles/_variables.module.scss';
import { fetchSizes } from '../../../redux/features/size';
import { setMenuActive } from '../../../redux/features/menuActive';

const SalesHistory = (props: any) => {
	const dispatch = useDispatch();
	//get userinfo from session storage
	// we use this instead of redux in order to get userinfo faster
	const userinfo: any = window.sessionStorage.getItem('user');
	const [user, setUser] = useState<User>(JSON.parse(userinfo));
	const currency = useSelector((state: any) => state.currency.value);
	const sizesState = useSelector((state: any) => state.sizes);

	if (user?.role === 'admin') {
		dispatch(setMenuActive('four'));
	}
	else{
		dispatch(setMenuActive('five'));
	}

	const paramInitialState = {
		orderAmount: [0, 10000],
		sortBy: 'orderDate',
		sortOrder: 'desc', 
		unitPrice:  [0, 10000]
	};

	/** column definition */
	const columnDefs = [
		{
			label: 'Order Id',
			name: 'orderId',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					inputTextHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						param,
						handleChange,
						sorting,
						setSorting
					)
			}
		},
		{
			label: 'Order Total Amount',
			name: 'orderAmount',
			options: {
				customBodyRenderLite: (dataIndex: any) => {
					return (
						(salesHistoryData.at(dataIndex)?.orderCurrency + ' ' ?? '') +
						salesHistoryData.at(dataIndex)?.orderAmount
					);
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) => (
					multipleInputHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						param,
						handleChange,
						sorting,
						setSorting
					)
				),
			}
		},
		{
			label: 'Size',
			name: 'size',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return tableMeta.rowData[2] + 'ft' ?? '';
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					selectHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						sizeOptions,
						param,
						handleChange,
						sorting,
						setSorting
					)
			}
		},
		{
			label: 'Container Number',
			name: 'containerNumber',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					inputTextHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						param,
						handleChange,
						sorting,
						setSorting
					)
			}
		},
		{
			label: 'Container Price',
			name: 'unitPrice',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
				multipleInputHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						param,
						handleChange,
						sorting,
						setSorting
					)
			}
		},
		{
			label: 'Sold Date',
			name: 'orderDate',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					dateHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						handleDateChange,
						param,
						sorting,
						setSorting
					),
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return value ?? tableMeta.rowData[3] ?? '';
				}
			}
		},
		// {
		// 	label: 'Booking Status',
		// 	name: 'bookingStatus',
		// 	options: {
		// 		customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
		// 			selectHeaderTemplate(
		// 				columnMeta.label || '',
		// 				columnMeta.name,
		// 				bookingStatusOptions,
		// 				param,
		// 				handleChange,
		// 				sorting,
		// 				setSorting
		// 			)
		// 	}
		// },
		{
			label: 'Company Name',
			name: 'companyName',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					inputTextHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						param,
						handleChange,
						sorting,
						setSorting
					)
			}
		}
	];

	const sizeOptions = sizesState.sizes.map((item:any) => ({
		label: item.size + 'ft',
		value: item.size
	}));

	const getMuiTheme = () =>
	createTheme({
		components: {
			MuiPaper: {
				styleOverrides: {
					root: {
						boxShadow: 'none !important',
						backgroundColor: 'transparent',
						cursor: 'default !important',
					},
					elevation: {
						background: '#fafbfc'
					}
				}
			},
			MUIDataTable: {
				styleOverrides: {
					root: {
						borderRadius: '10px',
						margin: '0px 10px',
						cursor: 'default !important',
					}
				}
			},
			MuiInputBase: {
				styleOverrides: {
					root: { width: '109px', height: '35px' }
				}
			},
			MuiButton: {
				styleOverrides: {
					root: { marginRight: '10px' }
				}
			},
			MuiTableRow: {
				styleOverrides: {
					root: {cursor: 'default !important', '&:hover': { cursor: 'default !important', backgroundColor: 'transparent !important' } },
					head: {cursor: 'default !important',  '&:hover': { cursor: 'default !important', backgroundColor: 'transparent !important' } },
					footer: {cursor: 'default !important',  '&:hover': { cursor: 'default !important', backgroundColor: 'transparent !important' } }
				}
			},
			MUIDataTableBodyCell: {
				styleOverrides: {
					stackedCommon: {
						paddingTop: '10px',
						borderBottom: '0',
						height: '45px',
						justifyContent: 'center !important',
						display: 'flex',
						borderLeft: `1px solid ${colors.colorFillPrimaryRedHover}`,
						whiteSpace: 'normal',
						textOverflow: 'ellipsis',
						overflow: 'hidden'
					},
					stackedHeader: {
						display: 'none !important'
					},
					root: {
						textAlign: 'center',
						color: colors.colorFillPrimaryBlue
					}
				}
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						position: 'relative',
						borderBottom: '0',
						padding: '10px 0px !important',
						minWidth: '120px',
						maxWidth: '300px',
						width: '300px',
						verticalAlign: 'bottom'
					}
				}
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						height: '35px',
						width: '80%',
						justifyContent: 'center',
						alignSelf: 'center'
					}
				}
			},
			MUIDataTableToolbar: {
				styleOverrides: {
					root: {
						color: colors.colorFillPrimaryBlue
					}
				}
			},
			MUIDataTableBodyRow: {
				styleOverrides: {
					root: {
						cursor: 'default !important', '&:hover': { cursor: 'default !important', backgroundColor: 'transparent' },
						boxShadow: 'rgb(221, 222, 223) 5px 5px 5px'
					}
				}
			},
			MuiTablePagination: {
				styleOverrides: {
					selectLabel: {
						marginTop: 'auto'
					},
					displayedRows: {
						marginTop: 'auto'
					}
				}
			},
			MuiList: {
				styleOverrides: {
					root: {
						backgroundColor: 'white'
					}
				}
			},
			MUIDataTableSelectCell: {
				styleOverrides: {
					headerCell: {
						backgroundColor: 'transparent'
					},
					root: {
						width: '50px !important',
						maxWidth: '50px !important'
					}
				}
			},
			MuiSvgIcon: {
				styleOverrides: {
					root: {
						':hover': {
							cursor: 'pointer'
						},
						color: '#04246a'
					}
				}
			}
		}
	});

	/** for filtering */
	const [param, setParam] = useState<any>(paramInitialState);
	/** for sorting */
	const [sorting, setSorting] = useState({ sortBy: 'orderDate', sortOrder: 'desc' });
	/** table data */
	const [salesHistoryData, setSalesHistoryData] = useState<SaleHistory[]>([]);
	/** to show/ not show progress bar */
	const [isLoading, setIsLoading] = useState(true);
	/** how many record per page */
	const [pageSize, setPageSize] = useState<number>(10);
	/** total records returned by api */
	const [totalRecords, setTotalRecords] = useState<number>(0);
	/** current page number */
	const [currentPage, setCurrentPage] = useState(1);
	/** progress circular bar when editing a record and waiting setUserfor success */
	const [progressBar, setProgressBar] = useState(false);

	const handleDateChange = (newValue: any) => {
		const filterTemp = {
			...param,
			orderDate: newValue
		};

		setParam(filterTemp);
	};

	/** get data for table with pagination */
	const getData = (currentPageInner?: number) => {
		let headers = undefined;
		const minValue = ((currentPageInner ?? currentPage) - 1) * pageSize;
		const maxValue = minValue + pageSize - 1;
		headers = { range: `${minValue}-${maxValue}` };
		setIsLoading(true);
		let tempParam = param;
		if (user?.role !== 'admin') {
			tempParam = {
				...param,
				country: user?.location.split(',')[0]
			};
		}
		if (user?.role === 'admin' || (tempParam['country'] && tempParam['country'] !== '')) {
			axios
				.get('/api/container/containers/salesHistory', {
					params: tempParam,
					headers,
					paramsSerializer: {
						serialize: (params) => {
							return qs.stringify(params, { arrayFormat: 'comma', encode: false });
						}
					}
				})
				.then((response) => {
					const rangeVal = response?.headers['content-range'];
					const rangeSplit = rangeVal.toString().split('/');
					const totalElements = parseInt(rangeSplit[1]);
					setTotalRecords(totalElements);
					setPageSize(10);
					setSalesHistoryData(response.data);
					setIsLoading(false);
				})
				.catch(() => {
					setSalesHistoryData([]);
					setIsLoading(false);
					setTotalRecords(0);
				});
		} else {
			setSalesHistoryData([]);
		}
	};
	// Debounce the API call with a delay of 100 milliseconds
	const debouncedGetData = debounce(getData, 100);
	useEffect(() => {
		debouncedGetData();
	}, [param, user?.role]);
	
	useEffect(() => {
		if (param.sortBy !== sorting.sortBy || param.sortOrder !== sorting.sortOrder)
		setCurrentPage(1);
			setParam({
				...param,
				sortBy: sorting.sortBy,
				sortOrder: sorting.sortOrder
			});
	}, [sorting]);

	useEffect(() => {
		dispatch(fetchSizes());
	},[])

	/** mui datatable options */
	const options = {
		selectableRows: 'none' as SelectableRows,
		textLabels: {
			body: {
				noMatch: isLoading ? <CircularProgress /> : 'No orders match your search'
			},
			pagination: {
				next: 'Next >',
				previous: '< Previous',
				rowsPerPage: 'Total items per page',
				displayRows: 'of'
			}
		},
		page: currentPage - 1,
		rowsPerPageOptions: [],
		count: totalRecords,
		serverSide: true,
		onChangePage(currentPage: number) {
			setCurrentPage(currentPage + 1);
			setIsLoading(true);
			getData(currentPage + 1);
		},
		customToolbar: () => {
			return (
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
					<Button onClick={clearFilters} sx={{ color: colors.colorFillPrimaryBlue }}>
						Clear Filters
					</Button>
				</div>
			);
		},
		customToolbarSelect: (
			selectedRows: {
				data: Array<{ index: number; dataIndex: number }>;
				lookup: { [key: number]: boolean };
			},
			displayData: DisplayData,
			setSelectedRows: (rows: number[]) => void
		) => {
			return (
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
					<Button onClick={clearFilters} sx={{ color: colors.colorFillPrimaryBlue }}>
						Clear Filters
					</Button>
				</div>
			);
		},
		filterType: 'dropdown' as FilterType,
		viewColumns: false,
		print: false,
		download: false,
		search: false,
		filter: false
	};

	/** this is called on filtering */
	const handleChange = (event: any) => {
		setCurrentPage(1);
		let headers = undefined;
		const minValue = (currentPage - 1) * pageSize;
		const maxValue = minValue + pageSize - 1;
		headers = { range: `${minValue}-${maxValue}` };
		const filterTemp = { ...param, [event.target.name]: event.target.value };
		const temp = {
			...filterTemp,
			country: user?.location.split(',')[0]
		};
		if (user && user?.role === 'admin') {
			setParam(filterTemp);
		} else {
			setParam(temp);
		}
		axios
			.get('/api/container/containers/salesHistory', {
				params: filterTemp,
				headers,
				paramsSerializer: {
					serialize: (params) => {
						return qs.stringify(params, { arrayFormat: 'comma', encode: false });
					}
				}
			})
			.then((response) => {
				setSalesHistoryData(response?.data);
				setIsLoading(false);
			})
			.catch(() => {
				setSalesHistoryData([]);
				setIsLoading(false);
			});
	};

	/** clear all filters */
	const clearFilters = () => {
		setCurrentPage(1);
		let headers = undefined;
		const temp = {
			...paramInitialState,
			country: user?.location.split(',')[0]
		};
		if (user && user?.role === 'admin') {
			setParam(paramInitialState);
		} else {
			setParam(temp);
		}
		const minValue = (currentPage - 1) * pageSize;
		const maxValue = minValue + pageSize - 1;
		headers = { range: `${minValue}-${maxValue}` };

		axios
			.get('/api/container/containers/salesHistory', {
				params: user && user?.role === 'admin' ? paramInitialState : temp,
				headers,
				paramsSerializer: {
					serialize: (params) => {
						return qs.stringify(params, { arrayFormat: 'comma', encode: false });
					}
				}
			})
			.then((response) => {
				setSalesHistoryData(response?.data);
				setIsLoading(false);
			})
			.catch(() => {
				setSalesHistoryData([]);
				setIsLoading(false);
			});
	};

	const salesHistoryTemplate: JSX.Element = (
		<>
			{progressBar && (
				<div>
					<Modal
						className="modal-popup-progress-bar"
						open={progressBar}
						onClose={() => setProgressBar(false)}
					>
						<Box>
							<CircularProgress />
						</Box>
					</Modal>
				</div>
			)}
			<div className="manageBooking">
				{/* Title */}
				<div className="manageBooking-top">
					<p>Sales History</p>
				</div>
				{/* Table */}
				<div>
					<ThemeProvider theme={getMuiTheme()}>
						<MUIDataTable
							title=""
							data={salesHistoryData ? salesHistoryData : []}
							columns={columnDefs}
							options={options}
						/>
					</ThemeProvider>
				</div>
			</div>
		</>
	);

	return (
		<>
			{user?.role === 'agent' && <AgentLayout>{salesHistoryTemplate}</AgentLayout>}
			{user?.role === 'admin' && <AdminLayout>{salesHistoryTemplate}</AdminLayout>}
		</>
	);
};

export default SalesHistory;

