import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabsUnstyled from '@mui/base/TabsUnstyled';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { styled } from '@mui/system';
import * as React from 'react';
import { CartItem } from '../../../shared/models/cart';
import { OrderDetail } from '../../../shared/models/orderDetail';
import ItemsSummary from '../OrderManagement/ItemsSummary';
import '../OrderManagement/ItemsSummary.css';
import OrderSummary from '../OrderManagement/OrderSummary';
import './CheckoutTabs.css';
import Confirmation from './Confirmation';

const Tab = styled(TabUnstyled)`
	&.${tabUnstyledClasses.selected} {
		background-color: #04246a;
		color: #fff;
	}

	&.${buttonUnstyledClasses.disabled} {
		opacity: 0.5;
		cursor: not-allowed;
	}
`;

const TabPanel = styled(TabPanelUnstyled)`
	width: 100%;
	font-family: IBM Plex Sans, sans-serif;
	font-size: 0.875rem;
`;

const TabsList = styled(TabsListUnstyled)``;

export default function UnstyledTabsCustomized(props: {
	cartItems: CartItem[];
	orderDetail: OrderDetail[];
	setMessageType: any;
	setMessageText: any;
	setOpen: any;
}) {
	const { cartItems, orderDetail, setMessageType, setMessageText, setOpen } = props;
	const [confirmed, setConfirmed] = React.useState(false);
	const [selected, setSelected] = React.useState(0);

	return (
		<>
			<div className="checkoutTabs">
				<TabsUnstyled value={selected} className="checkoutTabs-tabs">
					<TabsList className="checkoutTabs-tabList">
						<Tab className="checkoutTabs-tab">
							<ShoppingCartOutlinedIcon /> &nbsp; Cart
						</Tab>
						<Tab disabled={!confirmed} className="checkoutTabs-tab">
							<CheckCircleOutlinedIcon /> &nbsp; Confirmation
						</Tab>
					</TabsList>
					<TabPanel value={0} className="checkoutTabs-tabPanel">
						{/* Top */}
						<div className="checkoutTabs-tabPanelTop">
							<p>Your Items</p>
						</div>

						{/* Bottom */}
						<div className="checkoutTabs-tabPanelBottom">
							{/* Bottom left */}
							<div className="checkoutTabs-tabPanelBottomLeft">
								<ItemsSummary
									setMessageText={setMessageText}
									setMessageType={setMessageType}
									setOpen={setOpen}
								/>
							</div>

							{/* Bottom right */}
							<div className="checkoutTabs-tabPanelBottomRight">
								<OrderSummary
									cartItems={cartItems}
									orderDetail={orderDetail}
									setConfirmed={setConfirmed}
									setSelected={setSelected}
								/>
							</div>
						</div>
					</TabPanel>
					<TabPanel value={1} className="checkoutTabs-tabPanel">
						{/* Top */}
						<div className="checkoutTabs-tabPanelTop">
							<p>Your Items</p>
						</div>

						{/* Bottom */}
						<div className="checkoutTabs-tabPanelBottom">
							{/* Bottom left */}
							<div className="checkoutTabs-tabPanelBottomLeft">
								<ItemsSummary
									setMessageText={setMessageText}
									setMessageType={setMessageType}
									setOpen={setOpen}
								/>
							</div>

							{/* Bottom right */}
							<div className="checkoutTabs-tabPanelBottomRight">
								<OrderSummary
									cartItems={cartItems}
									orderDetail={orderDetail}
									setConfirmed={setConfirmed}
									setSelected={setSelected}
								/>
							</div>
						</div>
						<Confirmation
							confirmed={confirmed}
							setConfirmed={setConfirmed}
							setSelected={setSelected}
							orderDetail={orderDetail}
						/>
					</TabPanel>
				</TabsUnstyled>
			</div>
		</>
	);
}
