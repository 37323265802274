import { createSlice } from '@reduxjs/toolkit';

export const userSlice = createSlice({
	name: 'loggedInUser',
	initialState: {
		value: {}
	},
	reducers: {
		setLoggedInUser: (state, action) => {
			state.value = action.payload;
		}
	}
});
export const { setLoggedInUser } = userSlice.actions;
export default userSlice.reducer;
