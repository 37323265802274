export const categoryOptions = [
	{ label: 'Reefer', value: 'reefer' },
	{ label: 'Dry', value: 'dry' },
	{ label: 'Flat Rack & Open Top', value: 'flat rack and open top' },
	{ label: 'Genset & Chassis', value: 'genset and chassis' },
	{ label: 'Pallet Wide', value: 'pallet wide' },
	{ label: 'Other', value: 'other' }
];

export const countryOptions = [
	{ label: 'Spain', value: 'spain' },
	{ label: 'Greece', value: 'greece' },
	{ label: 'Hungary', value: 'hungary' },
	{ label: 'Lithuania', value: 'lithuania' },
	{ label: 'Portugal', value: 'portugal' },
	/* { label: 'United States', value: 'united states' },
	{ label: 'Italy', value: 'italy' },
	{ label: 'United Kingdom', value: 'united kingdom' }, */
];

export const sizeOptions = [
	{ label: '20ft', value: '20' },
	{ label: '40ft', value: '40' },
	{ label: '45ft', value: '45' }
];

export const typeOptions = [
	{ label: 'HC', value: 'HC' },
	{ label: 'ST', value: 'ST' },
	{ label: 'N/A', value: 'N/A' }
];

export const conditionOptions = [
	{ label: 'Re-Use', value: 're-use' },
	{ label: 'Repurpose', value: 'repurpose' },
	{ label: 'Recycle', value: 'recycle' }
];

export const documentStatusOptions = [
	{ label: 'Not Uploaded', value: 'notUploaded' },
	{ label: 'Uploaded', value: 'uploaded' },
	{ label: 'Validated', value: 'validated' }
];

export const totalAmountOptions = [
	{ label: '0 - 10,000', value: '0,10000' },
	{ label: '10,000 - 50,000', value: '10001,50000' },
	{ label: '50,000+', value: '50001,100000' }
];

export const paymentStatusOptions = [
	{ label: 'None', value: 'none' },
	{ label: 'Awaiting Validation', value: 'awaitingValidation' },
	{ label: 'Validated', value: 'validated' }
];

export const bookingStatusOptions = [
	{ label: 'None', value: 'none' },
	{ label: 'Ready For Pick Up', value: 'readyForPickUp' },
	{ label: 'Picked Up', value: 'pickedUp' }
];

export const orderStatusOptionsBuyer = [
	{ label: 'In Progress', value: ['inProgress', 'sold', 'booked'] },
	{ label: 'Closed', value: 'closed' },
	{ label: 'Cancelled', value: 'cancelled' }
];
