import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import { debounce } from 'lodash';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { Button, createTheme, Popover, ThemeProvider, Alert, AlertTitle, Box, IconButton, Modal } from '@mui/material';
import axios from 'axios';
import MUIDataTable, { MUIDataTableCustomHeadRenderer, SelectableRows } from 'mui-datatables';
import { useEffect, useState } from 'react';
import {
	getMuiTheme,
	noSelectHeaderTemplate
} from '../../../shared/components/DataTable/TableUtils';
import colors from '../../../shared/styles/_variables.module.scss';
import AdminLayout from '../Util/AdminLayout';
import UpdateCurrency from './UpdateDocumentTypes';
import AlertCmpt from '../../buyer/Util/AlertCmpt';
import { useDispatch } from 'react-redux';
import { setMenuActive } from '../../../redux/features/menuActive';

export type DocumentTypeType = {
	createdDate: string;
	deletedDate: string;
	id: number;
	deleted: boolean;
	lastModifiedDate: string;
	maxSize: number;
	name: string;
	userRole: [];
};

const DocumentTypes = () => {
	const dispatch = useDispatch();
	dispatch(setMenuActive('nine'));
	const getMuiTheme = () =>
	createTheme({
		components: {
			MuiPaper: {
				styleOverrides: {
					root: {
						boxShadow: 'none !important',
						backgroundColor: 'transparent',
						cursor: 'default !important',
					},
					elevation: {
						background: '#fafbfc'
					}
				}
			},
			MUIDataTable: {
				styleOverrides: {
					root: {
						borderRadius: '10px',
						margin: '0px 10px',
						cursor: 'default !important',
					}
				}
			},
			MuiInputBase: {
				styleOverrides: {
					root: { width: '109px', height: '35px' }
				}
			},
			MuiButton: {
				styleOverrides: {
					root: { marginRight: '10px' }
				}
			},
			MuiTableRow: {
				styleOverrides: {
					root: {cursor: 'default !important', '&:hover': { cursor: 'default !important', backgroundColor: 'transparent !important' } },
					head: {cursor: 'default !important',  '&:hover': { cursor: 'default !important', backgroundColor: 'transparent !important' } },
					footer: {cursor: 'default !important',  '&:hover': { cursor: 'default !important', backgroundColor: 'transparent !important' } }
				}
			},
			MUIDataTableBodyCell: {
				styleOverrides: {
					stackedCommon: {
						paddingTop: '10px',
						borderBottom: '0',
						height: '45px',
						justifyContent: 'center !important',
						display: 'flex',
						borderLeft: `1px solid ${colors.colorFillPrimaryRedHover}`,
						whiteSpace: 'normal',
						textOverflow: 'ellipsis',
						overflow: 'hidden'
					},
					stackedHeader: {
						display: 'none !important'
					},
					root: {
						textAlign: 'center',
						color: colors.colorFillPrimaryBlue
					}
				}
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						position: 'relative',
						borderBottom: '0',
						padding: '10px 0px !important',
						minWidth: '120px',
						maxWidth: '300px',
						width: '300px',
						verticalAlign: 'bottom'
					}
				}
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						height: '35px',
						width: '80%',
						justifyContent: 'center',
						alignSelf: 'center'
					}
				}
			},
			MUIDataTableToolbar: {
				styleOverrides: {
					root: {
						color: colors.colorFillPrimaryBlue
					}
				}
			},
			MUIDataTableBodyRow: {
				styleOverrides: {
					root: {
						cursor: 'default !important', '&:hover': { cursor: 'default !important', backgroundColor: 'transparent' },
						boxShadow: 'rgb(221, 222, 223) 5px 5px 5px'
					}
				}
			},
			MuiTablePagination: {
				styleOverrides: {
					selectLabel: {
						marginTop: 'auto'
					},
					displayedRows: {
						marginTop: 'auto'
					}
				}
			},
			MuiList: {
				styleOverrides: {
					root: {
						backgroundColor: 'white'
					}
				}
			},
			MUIDataTableSelectCell: {
				styleOverrides: {
					headerCell: {
						backgroundColor: 'transparent'
					},
					root: {
						width: '50px !important',
						maxWidth: '50px !important'
					}
				}
			},
			MuiSvgIcon: {
				styleOverrides: {
					root: {
						':hover': {
							cursor: 'pointer'
						},
						color: '#04246a'
					}
				}
			}
		}
	});
	const [documentTypeData, setDocumentTypeData] = useState<DocumentTypeType[]>([]);
	/** error pop up */
	const [openError, setOpenError] = useState(false);

	const [documentTypeObject, setDocumentTypeObject] = useState<DocumentTypeType>({
		createdDate: '',
		deletedDate: '',
		id: 0,
		deleted: false,
		lastModifiedDate: '',
		maxSize: 0,
		name: '',
		userRole: []
	});
	const [openEditPopover, setOpenEditPopover] = useState(false);
	const [openAddPopover, setOpenAddPopover] = useState(false);
	const [messageType, setMessageType] = useState('');
	const [messageText, setMessageText] = useState('');

	const onEditType = (tableMeta: any) => {
		setDocumentTypeObject((prev) => documentTypeData[tableMeta.rowIndex] ?? prev);
		setOpenEditPopover(true);
	};

	const deleteType = (tableMeta: any) => {
		const deletedDocType: DocumentTypeType = documentTypeData[tableMeta.rowIndex];
		axios
			.put('/api/documents/documentTypes/' + deletedDocType.id, {
				deleted: deletedDocType.deleted,
				userRole: deletedDocType.userRole
			})
			.then(() => getData())
	};

	const openAddDocumentType = () => {
		setDocumentTypeObject({
			createdDate: '',
			deletedDate: '',
			id: 0,
			deleted: false,
			lastModifiedDate: '',
			maxSize: 0,
			name: '',
			userRole: []
		});
		setOpenAddPopover(true);
	};


	const updateDocumentTypeData = (updatedDocType: DocumentTypeType) => {
		axios
			.put('/api/documents/documentTypes/' + updatedDocType.id, {
				deleted: updatedDocType.deleted,
				userRole: updatedDocType.userRole
			})
			.then(() => {getData(); 
				setOpenEditPopover(false);})
			.catch((err: any) => {
				setOpenEditPopover(true);
				setMessageType('error');
				setMessageText(err.response.data.description ?? 'Error Uploading Document');
				setOpenError(true);
			})	
};

	/** get data for table with pagination */
	const getData = () => {
		axios
			.get('/api/documents/documentTypes')
			.then((response) => {
				setDocumentTypeData(response.data);
			})
			.catch(() => {
				setDocumentTypeData([]);
			});
	};

	// Debounce the API call with a delay of 100 milliseconds
	const debouncedGetData = debounce(getData, 100);
	useEffect(() => {
		debouncedGetData();
	}, []);

	const addDocumentType = (newDocType: DocumentTypeType) => {
		if(newDocType.name === ''){
			setOpenAddPopover(true)
			setMessageType('error');
			setMessageText('Please add a document name');
			setOpenError(true);
		}
		else{
			axios
				.post('/api/documents/documentTypes', {
					deleted: newDocType.deleted,
					name: newDocType.name,
					userRole: newDocType.userRole
				})
				.then(() => {
					getData();
					setOpenAddPopover(false)
				})
				.catch((err: any) => {
					console.log(err);
					setOpenAddPopover(true)
					setMessageType('error');
					setMessageText(err.response.data.description ?? 'Error Uploading Document');
					setOpenError(true);
				})
		}
	};

	/** column definition */
	const columnDefs = [
		{
			label: 'Name',
			name: 'name',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '')
			}
		},
		{
			label: 'Created Date',
			name: 'createdDate',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '')
			}
		},
		{
			label: 'User',
			name: 'userRole',
			options: {
				customBodyRender: (value: any) => {
					return value.join('  -  ').toUpperCase()
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '')
			}
		},
		/* {
			label: 'Max Size',
			name: 'maxSize',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '')
			}
		}, */
		{
			label: 'Status',
			name: 'deleted',
			options: {
				customBodyRender: (value: any) => {
					return value ? 'Inactive' : 'Active'; // Display 'true' if value is true, otherwise, display nothing
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '')
			}
		},
		/* {
			label: 'Delete',
			name: '',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<div>
							<DeleteOutlineSharpIcon
								sx={{
									color: '#314b85'
								}}
								onClick={(e) => {
									e.stopPropagation();
									deleteType(tableMeta);
								}}
							/>
						</div>
					);
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '')
			}
		}, */
		{
			label: 'Manage',
			name: '',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					let disabled = false;
					if(tableMeta.rowData[0] === 'PAYMENT PROOF' || tableMeta.rowData[0] === 'CUSTOM CLEARANCE' || tableMeta.rowData[0] === 'INVOICE'){
						disabled = true
					}
					return (
						<div>
							<IconButton disabled={disabled}
									onClick={(e) => {
										e.stopPropagation();
										onEditType(tableMeta);
									}}>
								<EditIcon/>
							</IconButton>
						</div>
					);
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '')
			}
		}
	];

	const options = {
		selectableRows: 'none' as SelectableRows,
		viewColumns: false,
		print: false,
		download: false,
		search: false,
		filter: false,
		customToolbar: () => {
			return (
				<div>
					<Button
						onClick={openAddDocumentType}
						sx={{ color: colors.colorFillPrimaryBlue }}
					>
						Add Document Type
					</Button>
				</div>
				
			);
		}
	};

	return (
		<>
			<AdminLayout>
				<ThemeProvider
					theme={createTheme({
						components: {
							MuiPopover: {
								styleOverrides: {
									paper: {
										borderRadius: '20px',
										border: '1px solid #04246a',
										padding: '10px'
									}
								}
							}
						}
					})}
				>
					<AlertCmpt
						open={openError}
						setOpen={setOpenError}
						messageType={messageType}
						messageText={messageText}
					/>
					<Popover
						open={openAddPopover}
						onClose={() => setOpenAddPopover(false)}
						anchorReference={'none'}
						disableAutoFocus={true}
						disableEnforceFocus={true}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<UpdateCurrency
							open={openAddPopover}
							setOpen={setOpenAddPopover}
							documentTypeObject={documentTypeObject}
							updateDocumentTypeData={addDocumentType}
							action={'Add'}
							documentTypeDataList ={documentTypeData}
						/>
					</Popover>
					<Popover
						open={openEditPopover}
						onClose={() => setOpenEditPopover(false)}
						anchorReference={'none'}
						disableAutoFocus={true}
						disableEnforceFocus={true}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<UpdateCurrency
							open={openEditPopover}
							setOpen={setOpenEditPopover}
							documentTypeObject={documentTypeObject}
							updateDocumentTypeData={updateDocumentTypeData}
							action={'Edit'}
							documentTypeDataList ={documentTypeData}
						/>
					</Popover>
				</ThemeProvider>
				<div>
					{/* Title */}
					<div>
						<p style={{ fontSize: '22px', fontWeight: '600', color: '#04246a' }}>
							Document Types
						</p>
					</div>
					<div>
						<ThemeProvider theme={getMuiTheme()}>
							<MUIDataTable
								title=""
								data={documentTypeData ?? []}
								columns={columnDefs}
								options={options}
							/>
						</ThemeProvider>
					</div>
				</div>
			</AdminLayout>
		</>
	);
};

export default DocumentTypes;
