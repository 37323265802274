import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useDispatch, useSelector } from 'react-redux';
import { setFilter } from '../../../redux/features/filter';
import { shouldBeChecked } from '../DataTable/TableUtils';
import './Select.css';

export default function MultipleSelectCheckmarks(props: any) {
	const dispatch = useDispatch();
	const filter: any = useSelector((state: any) => state.filter.value);
	const { name, options } = props;

	function handleChange(event: SelectChangeEvent) {
		const name = event.target.name;
		const filterTemp = { ...filter, [name]: [...event.target.value] };
		dispatch(setFilter(filterTemp));
	}

	return (
		<FormControl className="select-form">
			<InputLabel id="demo-multiple-checkbox-label" className="select-label">
				All...
			</InputLabel>
			<Select
				onChange={handleChange}
				sx={{ width: '80%', height: '35px' }}
				name={name}
				input={<OutlinedInput label="Tag" />}
				value={filter && filter[name] && filter[name] !== undefined ? filter[name] : []}
				renderValue={(selected) => {
					const selectedValues = Array.isArray(selected) ? selected : [selected];
					const newMap = selectedValues.map((e: string) => {
						const found = options.find((obj: { value: string }) => {
							return obj.value === e;
						});
						return found?.label;
					});
					return newMap.join(',');
				}}
				multiple
				notched={false}
			>
				{options.map((o: any) => (
					<MenuItem key={o.value} value={o.value}>
						<Checkbox checked={shouldBeChecked(name, o.value, filter)} />
						<ListItemText primary={o.label} />
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}
