import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setCart } from '../../../redux/features/cart';
import { setOrder } from '../../../redux/features/order';
import { CartItem } from '../../../shared/models/cart';
import { OrderDetail } from '../../../shared/models/orderDetail';
import { User } from '../../../shared/models/user';
import './ShoppingCart.css';
import ItemsDetailsSummary from './ItemsDetailsSummary';

type ShoppingCartPropTypes = {
	cartItems: CartItem[];
	user: User;
	setCartItems: (cart: CartItem[]) => void;
};

export default function ShoppingCart(props: ShoppingCartPropTypes) {
	const [orderDetail, setOrderDetail] = useState<OrderDetail[]>([]);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { user, cartItems, setCartItems } = props;
	const trigger = useSelector((state: any) => state.trigger.value);
	let currency = useSelector((state: any) => state.currency.value);
	const [containerImage, setContainerImage] = useState<any>([]);
	const [itemDetails, setItemDetails] = useState<CartItem>(new CartItem());
	const [openPopOver, setOpenPopOver] = useState(false);
	const [orderCountry, setOrderCountry] = useState<string>('');
	const [countryPercentage, setCountryPercentage] = useState<number>();

	if(currency === null){
		currency = user.defaultCurrency;
	}

	const orderParam = {
		customerUid: user.ccgId,
		status: 'cart'
	};

	const handleClose = () => {
		setOpenPopOver(false);
	};

	const handleItemClick = (cartItem: CartItem) => {
		setItemDetails(cartItems.find((x) => x.orderDetailsId === cartItem.orderDetailsId) ?? new CartItem());
		setOpenPopOver(true);
	};

	useEffect(() => {
		const fetchData = async () => {
		  const transformedArray = await Promise.all(
			cartItems.map(async (item) => {
				if (item.defaultImage !== null) {
					try {
					// Make an API call using Axios when item is not empty
					const response = await axios.get(`/api/download/${item?.defaultImage?.s3ImageName}`, {
						method: 'GET',
						responseType: 'blob'
					});
					// Return the API response data or any specific property you need
					const blob = new Blob([response.data]);
					const imageUrl = URL.createObjectURL(blob);
					return imageUrl;
					} catch (error) {
					console.error('Error fetching data:', error);
					return '/media/container-logo.png'; // Return null or handle error case appropriately
					}
				} else {
					return '/media/container-logo.png'; // Return null for empty items
				}
				})
		  );
	
		  setContainerImage(transformedArray);
		};
	
		fetchData();
	}, [cartItems]);

	useEffect(() => {
		axios
			.get('/api/container/orders', { params: orderParam })
			.then((response) => {
				setOrderDetail(response.data);
				dispatch(setOrder(response.data));
				setOrderCountry(response.data[0].originCountry);
			})
			.catch(() => {
				setOrderDetail([]);
				dispatch(setOrder([]));
				setCartItems([]);
				dispatch(setCart([]));
			});
	}, [trigger]);

	useEffect(() => {
		const orderIds: (number | undefined)[] = [];
		orderDetail.map((order: OrderDetail) => orderIds.push(order.orderId));
		if (orderIds.length > 0) {
			const param = {
				orderId: orderIds
			};
			axios
				.get('/api/container/orderDetails', {
					params: param,
					paramsSerializer: {
						serialize: (params) => {
							return qs.stringify(params, { arrayFormat: 'comma', encode: false });
						}
					}
				})
				.then((response) => {
					setCartItems(response.data);
					dispatch(setCart(response.data));
				})
				.catch(() => {
					setCartItems([]);
					dispatch(setCart([]));
				});
		}
		else{
			setCartItems([]);
			dispatch(setCart([]));
		}
	}, [orderDetail]);

	const checkout = () => {
		navigate('/listProducts/checkout');
	};
	/* const getContainerImage = (displayedContainer : CartItem) => {
		let imageSource = '/media/container-logo.png';
		if(displayedContainer?.defaultImage !== null){
			axios
			.get(`/api/download/${displayedContainer?.defaultImage?.s3ImageName}`, {
				method: 'GET',
				responseType: 'blob'
			})
			.then((response) =>{
				if(response !== null){
					const blob = new Blob([response.data]);
				}
			})
			.catch((error) => {
				console.error('Error fetching Blob:', error);
			})

			console.log('default image1', containerImage)
			return <img src={containerImage} />
		}
	
			return <img src={imageSource} />;
	} */
	return (
		<>
		<Popover
			open={openPopOver}
			onClose={handleClose}
			anchorReference={'none'}
			disableAutoFocus={true}
			disableEnforceFocus={true}
			sx={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}}
		>
			<ItemsDetailsSummary
				itemDetails={itemDetails}
				handleClose={handleClose}
			/>
		</Popover>
		<PopupState variant="popover" popupId="popup-cart">
			{(popupState) => (
				<div className="shoppingCart">
					<Button
						size="large"
						variant="text"
						className="shoppingCartBtn"
						style={{ maxWidth: '10px', padding: 0, color: '#04246a' }}
						{...bindTrigger(popupState)}
					>
						<FontAwesomeIcon icon={faShoppingCart} size="lg" className="nav-icon" />{' '}
					</Button>
					<Popover
						{...bindPopover(popupState)}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right'
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right'
						}}
					>
						<Typography className="shoppingCart-content">
							{/* Title */}
							<div className="shoppingCart-title">
								<p>Shopping Cart</p>

								<Button variant="text" onClick={popupState.close}>
									x
								</Button>
							</div>
							{/* Items */}
							{cartItems?.length === 0 && (
								<div className="shoppingCart-items">Your cart is empty</div>
							)}
							<div className="shoppingCart-items">
								{cartItems?.length > 0 &&
									cartItems.map((p: CartItem, index: number) => (
										<div className="shoppingCart-item" key={p.productId} onClick={() => handleItemClick(p)} style={{cursor: 'pointer'}}>
											{/* Left */}
											<div className="shoppingCart-itemLeft">
												<img src={containerImage[index]} />
											</div>

											{/* Middle */}
											<div className="shoppingCart-itemMiddle">
												{/* Middle top */}
												<div className="shoppingCart-itemMiddle-top">
													{p.quantity}x {p.productType ?? ''},{' '}
													{p.productSize}" {p.productCategory},{' '}
													{p.productCondition ?? ''}
												</div>

												{/* Middle bottom */}
												<div className="shoppingCart-itemMiddle-bottom">
													{p.productCity}, {p.productCountry}
												</div>

												{/* Middle bottom */}
												<div className="shoppingCart-itemMiddle-bottom">
													{p.productDepotName}
												</div>
											</div>

											{/* Right */}
											<div className="shoppingCart-itemRight">
												{(p?.prices?.length ?? 0 > 0 ) && currency !== null
													? currency +
													  ' ' +
													  Math.round(
															p?.prices?.find(
																(amount: { currency: any }) =>
																	amount.currency === currency
															)?.price ?? 0
													  )
													: ''}
											</div>
										</div>
									))}
							</div>									
							{/* Summary */}
							{cartItems?.length > 0 && (
								<div className="shoppingCart-summary">
									<div className="shoppingCart-summaryContent">
										<div className="shoppingCart-subtotal">
											<p>Subtotal</p>
											<p>
												{currency + ' '}
												{orderDetail.length > 0
													? Math.round(
															orderDetail[0]?.amounts?.find(
																(amount: { currency: any }) =>
																	amount.currency === currency
															)?.price ?? 0
													  )
													: ''}
											</p>
										</div>
										<div className="shoppingCart-subtotal">
											<p>VAT</p>
											<p>
												{orderDetail !== undefined
													? '% ' +
													cartItems[0].vatPercentage
													: ''}
											</p>
										</div>
										<div className="shoppingCart-total">
											<p>Total</p>
											<p>
												{currency + ' '}
												{orderDetail.length > 0 &&
												orderDetail[0]?.taxAmounts !== undefined &&
												orderDetail[0]?.amounts !== undefined
													? Math.round(
															orderDetail[0]?.taxAmounts.find(
																(amount) =>
																	amount.currency === currency
															)?.price ?? 0
													  ) +
													  Math.round(
															orderDetail[0]?.amounts.find(
																(amount) =>
																	amount.currency === currency
															)?.price ?? 0
													  )
													: ''}
											</p>
										</div>
									</div>
								</div>
							)}

							{/* Checkout */}
							<div className="shoppingCart-checkout">
								{cartItems?.length > 0 && (
									<Button
										variant="contained"
										color="primary"
										className="shoppingCart-button"
										onClick={checkout}
									>
										Go to Checkout
									</Button>
								)}
							</div>
						</Typography>
					</Popover>
				</div>
			)}
		</PopupState>
		</>
	);
}
