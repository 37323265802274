import HomeIcon from '@mui/icons-material/Home';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './PageNotFound.css';

const PageNotFound = () => {
	const navigate = useNavigate();

	const routeChange = () => {
		navigate('/');
	};

	return (
		<div className="pageNotFound">
			<div className="pageNotFound-content">
				{/* Row */}
				<div className="pageNotFound-content-row">
					<p>404</p>
				</div>

				{/* Row */}
				<div className="pageNotFound-content-row">
					<p>Page Not Found.</p>
				</div>

				{/* Row */}
				<div className="pageNotFound-content-row">
					<Button
						onClick={routeChange}
						variant="text"
						startIcon={<HomeIcon />}
						className="pageNotFound-content-button"
					>
						Return to your home page
					</Button>
				</div>
			</div>
		</div>
	);
};

export default PageNotFound;
