import { Box, Button, CircularProgress, Modal, ThemeProvider,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	TextField } from '@mui/material';
import axios from 'axios';
import { debounce } from 'lodash';
import MUIDataTable, {
	DisplayData,
	FilterType,
	MUIDataTableCustomHeadRenderer,
	Popover,
	SelectableRows
} from 'mui-datatables';
import qs from 'qs';
import { useEffect, useState } from 'react';
import {
	getMuiTheme,
	inputTextHeaderTemplate,
	multipleInputHeaderTemplate,
	noSelectHeaderTemplate,
	rangeHeaderTemplate,
	selectHeaderTemplate
} from '../../../shared/components/DataTable/TableUtils';
import { Container } from '../../../shared/models/container';
import colors from '../../../shared/styles/_variables.module.scss';
import AgentLayout from '../Util/AgentLayout';
import './AgentACContainers.css';
import './containers.scss';
import { useSelectedItems } from '../Util/SelectedItemsContext';
import AgentISModal from './AgentISModal';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategories } from '../../../redux/features/category';
import { fetchTypes } from '../../../redux/features/type';
import { fetchConditions } from '../../../redux/features/condition';
import { fetchSizes } from '../../../redux/features/size';
import { setMenuActive } from '../../../redux/features/menuActive';
import AlertCmpt from '../../buyer/Util/AlertCmpt';

const style = {
	position: 'absolute',
	top: '50%',
	left: '55%',
	transform: 'translate(-50%, -50%)',
	width: '50%',
	height: 600,
	maxHeight: 600,
	borderRadius: '10px',
	bgcolor: 'background.paper',
	border: '2px solid gray',
	boxShadow: 24,
	overflowY: 'auto',
	pt: 2,
	px: 4,
	pb: 3
};

const AgentISContainers = () => {
	//get userinfo from session storage
	// we use this instead of redux in order to get userinfo faster
	let userInfo: any = window.sessionStorage.getItem('user');
	userInfo = JSON.parse(userInfo);

	/** price range for filtering */
	const [priceRange, setPriceRange] = useState<number[]>([0, 10000]);
	const [openError, setOpenError] = useState(false);
	const [messageType, setMessageType] = useState('');
	const [messageText, setMessageText] = useState('');

	const paramInitialState = {
		country: userInfo?.location.split(',')[0],
		price: priceRange,
		status: 'available',
		sortBy: 'DEPOT',
		sortOrder: 'asc'
	};
	type OrderSortingMap = {
		[key: string]: string;
	};

	const orderSortingMap: OrderSortingMap = {
		containerNumber: 'CONTAINER_NUMBER',
		depot: 'DEPOT',
		containerType: 'TYPE',
		condition: 'CONDITION',
		size: 'SIZE',
		price: 'PRICE',
		category: 'CATEGORY'
	};

	/** column definition */
	const columnDefs = [
		{
			label: 'Depot Code',
			name: 'Depot',
			options: {
				display: false
			}
		},
		{
			label: 'Container Type Size Display',
			name: 'typeSizeDisplay',
			options: {
				display: false
			}
		},
		{
			label: 'type',
			name: 'containerType',
			options: {
				display: false
			}
		},
		{
			label: 'Comment',
			name: 'comments',
			options: {
				display: false
			}
		},
		{
			label: 'Images',
			name: '',
			options: {
				customBodyRenderLite: (index: any) => {
					let imageSource = '/media/container-logo.png';
					const containerS3Image = containerImage?.at(index);
					if(containerS3Image !== null){
						return <img src={containerS3Image} />
					}
				
						return <img src={imageSource} />;
				},
				/* customBodyRender: () => {
					return <img src="/media/container-logo.png" />;
				}, */
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '')
			}
		},
		{
			label: 'ID',
			name: 'containerNumber',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					inputTextHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						param,
						handleChange as any,
						sorting,
						setSorting,
						orderSortingMap[columnMeta.name]
					)
			}
		},
		{
			label: 'Depot',
			name: 'depot',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return tableMeta.rowData[6]['code'] ?? '';
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					inputTextHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						param,
						handleChange as any,
						sorting,
						setSorting,
						orderSortingMap[columnMeta.name]
					)
			}
		},
		{
			label: 'Category',
			name: 'category',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return tableMeta.rowData[7];
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					selectHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						categoryOptions,
						param,
						handleChange,
						sorting,
						setSorting,
						orderSortingMap[columnMeta.name]
					)
			}
		},
		{
			label: 'Condition',
			name: 'condition',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					selectHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						conditionOptions,
						param,
						handleChange,
						sorting,
						setSorting,
						orderSortingMap[columnMeta.name]
					)
			}
		},

		{
			label: 'Size',
			name: 'size',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return tableMeta.rowData[9] + 'ft' ?? '';
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					selectHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						sizeOptions,
						param,
						handleChange,
						sorting,
						setSorting,
						orderSortingMap[columnMeta.name]
					)
			}
		},
		{
			label: 'Price',
			name: 'price',
			options: {
				customBodyRenderLite: (dataIndex: any) => {
					const container = inStockData?.at(dataIndex);
					return (container?.currency + ' ' ?? '') + container?.price;
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
				multipleInputHeaderTemplate(columnMeta.label || '', 
						columnMeta.name,
						param,
						handleChange as any,
						sorting,
						setSorting,
						orderSortingMap[columnMeta.name])
			}
		},
		{
			label: 'Type',
			name: 'containerType',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return tableMeta.rowData[2];
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					selectHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						typeOptions,
						param,
						handleChange,
						sorting,
						setSorting,
						orderSortingMap[columnMeta.name]
					)
			}
		}
	];

	/** for filtering */
	const [param, setParam] = useState<any>(paramInitialState);
	/** for sorting */
	const [sorting, setSorting] = useState({ sortBy: 'DEPOT', sortOrder: 'asc' });
	/** table data */
	const [inStockData, setInStockData] = useState<any[]>([]);
	/** to show/ not show progress bar */
	const [isLoading, setIsLoading] = useState(true);
	/** containers no to be sent to backend when selecting records and confirming them */
	const [containersUpdated, setContainersUpdated] = useState<any[]>([]);
	/** how many record per page */
	const [pageSize, setPageSize] = useState<number>(10);
	/** total records returned by api */
	const [totalRecords, setTotalRecords] = useState<number>(0);
	/** current page number */
	const [currentPage, setCurrentPage] = useState(1);
	/** disable/enable confirm button */
	const [buttonDisabled, setButtonDisabled] = useState<boolean>(true);
	/** progress circular bar when editing a record and waiting for success */
	const [progressBar, setProgressBar] = useState(false);
	/** get row data by id */
	const [rowDataById, setRowDataById] = useState<Container>();
	const { selectedItems, setSelectedItems } = useSelectedItems();
	const [containersDeselected, setContainersDeselected] = useState<any>([]);
	const [containerImage, setContainerImage] = useState<any>([]);
	const [containerDetails, setContainerDetails] = useState<Container>(new Container());
	const [openPopOver, setOpenPopOver] = useState(false);
	const dispatch = useDispatch();
	dispatch(setMenuActive('three'));
	const conditionsState = useSelector((state: any) => state.conditions);
	const categoriesState = useSelector((state: any) => state.categories);
	const typesState = useSelector((state: any) => state.types);
	const sizesState = useSelector((state: any) => state.sizes);

	const conditionOptions = conditionsState.conditions.map((item:any) => ({
		label: item.condition,
		value: item.condition
	}));

	const categoryOptions = categoriesState.categories.map((item:any) => ({
		label: item.category,
		value: item.category
	}));
	
	const typeOptions = typesState.types.map((item:any) => ({
		label: item.containerType,
		value: item.containerType
	}));
	const sizeOptions = sizesState.sizes.map((item:any) => ({
		label: item.size + 'ft',
		value: item.size
	}));
	useEffect(() => {
		dispatch(fetchCategories());
		dispatch(fetchTypes());
		dispatch(fetchConditions());
		dispatch(fetchSizes());
	},[])

	/** get data for table with pagination */
	const getData = (currentPageInner?: number) => {
		let headers = undefined;
		const minValue = ((currentPageInner ?? currentPage) - 1) * pageSize;
		const maxValue = minValue + pageSize - 1;
		headers = { range: `${minValue}-${maxValue}` };
		setIsLoading(true);
		axios
			.get('/api/container/containers', {
				params: param,
				headers,
				paramsSerializer: {
					serialize: (params) => {
						return qs.stringify(params, { arrayFormat: 'comma', encode: false });
					}
				}
			})
			.then((response) => {
				const rangeVal = response?.headers['content-range'];
				const rangeSplit = rangeVal.toString().split('/');
				const totalElements = parseInt(rangeSplit[1]);
				// setButtonDisabled(true);
				setTotalRecords(totalElements);
				setPageSize(10);
				setInStockData(response.data);
				const newData = response.data;
				// Check if filtered data is empty
				if (newData.length === 0) {
					setInStockData([]);
					setIsLoading(false);
					setTotalRecords(0);
				}
				const currentSelectedRows = selectedRows.get(
					currentPageInner ? currentPageInner : 1
				);
				const currentRowsState = currentSelectedRows?.map((row) => newData[row]);
				setContainersDeselected(currentRowsState);
			})
			.catch(() => {
				setInStockData([]);
				setIsLoading(false);
				setTotalRecords(0);
				// setButtonDisabled(true);
			});
	};

	const handleClose = () => {
		setOpenPopOver(false);
	};

	// Debounce the API call with a delay of 100 milliseconds
	const debouncedGetData = debounce(getData, 100);
	useEffect(() => {
		debouncedGetData();
	}, [param]);

	useEffect(() => {
		const fetchData = async () => {
		  const transformedArray = await Promise.all(
			inStockData.map(async (item) => {
				if (item.images.length !== 0) {
					try {
					// Make an API call using Axios when item is not empty
					const response = await axios.get(`/api/download/${item.images[0].s3ImageName}`, {
						method: 'GET',
						responseType: 'blob'
					});
					// Return the API response data or any specific property you need
					const blob = new Blob([response.data]);
					const imageUrl = URL.createObjectURL(blob);
					return imageUrl;
					} catch (error) {
					console.error('Error fetching data:', error);
					return null; // Return null or handle error case appropriately
					}
				} else {
					return null; // Return null for empty items
				}
				})
		  );
	
		  setContainerImage(transformedArray);
		};
	
		fetchData();
	  }, [inStockData]);

	useEffect(() => {
		setCurrentPage(1);
		setSelectedItems([]);
		setParam({
			...param,
			sortBy: sorting.sortBy,
			sortOrder: sorting.sortOrder,
			price: priceRange
		});
	}, [sorting, priceRange]);

	const array: any[] = [];
	/** method to get an array of object of containers number to be sent to api */
	const getContainerNoToUpdate = (selectedDataIndex: any) => {
		selectedDataIndex.map((dataIndex: any) => {
			array.push(inStockData[dataIndex.index]);
		});
		const containerNo = array.map((data: any) => {
			return {
				containerNumber: data.containerNumber
			};
		});

		setContainersUpdated(containerNo);
	};

	const handleRowClick = (rowData: any) => {
		setContainerDetails(inStockData.find((x) => x.containerNumber === rowData[5]) ?? new Container());
		setOpenPopOver(true);
	};
	
	function removeDuplicates(array: any[], property: string | number) {
		return array.filter((obj, index, self) =>
			index === self.findIndex((t) => (
				t[property] === obj[property]
			))
		);
	}

	/** mark unavailable selected records */
	const handleMoveToAwaitingConfirmContainer = () => {
		let headers: { range: string } | undefined = undefined;
		const minValue = (currentPage - 1) * pageSize;
		const maxValue = minValue + pageSize - 1;
		headers = { range: `${minValue}-${maxValue}` };
		const uniqueArray = removeDuplicates(selectedItems, 'id');
		setProgressBar(true);
		axios
			.put('/api/container/containers', uniqueArray)
			.then((response) => {
				if (response.status === 200) {
					axios
						.get('/api/container/containers', {
							params: param,
							headers,
							paramsSerializer: {
								serialize: (params) => {
									return qs.stringify(params, {
										arrayFormat: 'comma',
										encode: false
									});
								}
							}
						})
						.then((response) => {
							setInStockData(response?.data);
							setProgressBar(false);
							setButtonDisabled(true);
							setSelectedRows(new Map<number, number[]>([]));
							setSelectedItems([]);
							setCurrentPage(1);
							getData();
						})
						.catch(() => {
							setInStockData([]);
							setProgressBar(false);
							setCurrentPage(1);
							setButtonDisabled(false);
						});
					setSelectedRows(new Map<number, number[]>([]));
					setSelectedItems([]);
				}
			})
			.catch((error) => {
				setProgressBar(false);
				setOpenError(true);
				setMessageType('error');
				setMessageText(error.response.data.description);
			});
	};

	type SelectedRowsState = Map<number, number[]>;
	const [selectedRows, setSelectedRows] = useState<SelectedRowsState>(new Map());

	/** mui datatable options */
	const options = {
		selectableRows: 'multiple' as SelectableRows,
		onRowSelectionChange(
			currentRowSelected: any,
			allRowsSelected: any[],
			rowsSelected?: any[]
		) {
			// Create a copy of the current selected rows for the current page
			const selectedRowsCopy = new Map(selectedRows);

			selectedRowsCopy.set(currentPage, rowsSelected || []);
			const allSelectedRows = Array.from(selectedRowsCopy.values()).flat();
			// Check if there are selected rows across all pages
			const hasSelectedValues = allSelectedRows.length > 0;
			setButtonDisabled(!hasSelectedValues);
			setSelectedRows(selectedRowsCopy);
			getContainerNoToUpdate(allRowsSelected);
			if (array.length > 0) {
				setContainersDeselected(array);
			}

			if (allRowsSelected.length === 0 || allRowsSelected.length === inStockData.length) {
				if (
					selectedItems.length >= allRowsSelected.length &&
					containersDeselected !== undefined &&
					containersDeselected.length > 0
				) {
					const newSelectedItems = selectedItems.filter(
						(mainItem) =>
							!containersDeselected.some(
								(filterItem: any) => mainItem.id === filterItem.id
							)
					);
					setSelectedItems(newSelectedItems);
				} else {
					const newSelectedItems = selectedItems.concat(inStockData.map((item) => item));
					setSelectedItems(newSelectedItems);
				}
			} else {
				if (
					selectedItems.some(
						(selectedItem) =>
							selectedItem.id === inStockData[currentRowSelected[0].index].id
					)
				) {
					setSelectedItems(
						selectedItems.filter(
							(selected: any) =>
								selected.id !== inStockData[currentRowSelected[0].index].id
						)
					);
				} else {
					setSelectedItems([...selectedItems, inStockData[currentRowSelected[0].index]]);
				}
			}
		},
		rowsSelected:
			(selectedRows && selectedRows.size > 0 && selectedRows?.get(currentPage)) || [],
		textLabels: {
			body: {
				noMatch: isLoading ? <CircularProgress /> : 'No Containers match your search'
			}
		},
		page: currentPage - 1,
		rowsPerPageOptions: [],
		count: totalRecords,
		serverSide: true,
		onChangePage(currentPage: number) {
			setCurrentPage(currentPage + 1);
			setIsLoading(true);
			getData(currentPage + 1);
		},
		customToolbar: () => {
			return (
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
					<Button
						disabled={buttonDisabled}
						variant="outlined"
						onClick={() => {
							handleMoveToAwaitingConfirmContainer();
						}}
						sx={{
							color: colors.colorFillPrimaryBlue,
							border: `1px solid ${colors.colorFillPrimaryBlue}`
						}}
					>
						Move to awaiting confirmation
					</Button>
					<Button onClick={getExport} sx={{ color: colors.colorFillPrimaryBlue }}>Export</Button>
					<Button onClick={clearFilters} sx={{ color: colors.colorFillPrimaryBlue }}>
						Clear Filters
					</Button>
				</div>
			);
		},
		customToolbarSelect: () => {
			return (
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
					<Button
						disabled={buttonDisabled}
						variant="outlined"
						onClick={() => {
							handleMoveToAwaitingConfirmContainer();
						}}
						sx={{
							color: colors.colorFillPrimaryBlue,
							border: `1px solid ${colors.colorFillPrimaryBlue}`
						}}
						style={{ marginRight: '10px !important' }}
					>
						Move to awaiting confirmation
					</Button>
					<Button onClick={clearFilters} sx={{ color: colors.colorFillPrimaryBlue }}>
						Clear Filters
					</Button>
				</div>
			);
		},
		filterType: 'dropdown' as FilterType,
		viewColumns: false,
		print: false,
		download: false,
		search: false,
		filter: false,
		onRowClick: (rowData: any) => handleRowClick(rowData)
	};

	/** this is called on filtering */
	const handleChange = (event: any) => {
		setCurrentPage(1);
		let filterTemp = { ...param };
		const name = event.target.name;

		if (Array.isArray(event.target.value)) {
			filterTemp = { ...param, [name]: [...event.target.value] };
		} else {
			filterTemp = { ...param, [name]: event.target.value };
		}
		setParam(filterTemp);
	};

	const getExport = () => {
		const minValue = (currentPage - 1) * pageSize;
		const maxValue = minValue + pageSize - 1;
		setIsLoading(true);
		let tempParam = param;
		if (userInfo?.role !== 'admin') {
			tempParam = {
				...param,
				range: `${minValue}-${maxValue}`
			};
		}
		axios .get('/api/container/containers/export', {
			method: 'GET',
			responseType: 'blob',
			params: tempParam,
			paramsSerializer: {
				serialize: (params) => {
					return qs.stringify(params, { arrayFormat: 'comma', encode: false });
				}
			}
		})
		.then((response) => {
			setIsLoading(false);
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', 'in-stock-table-export.xlsx');
			document.body.appendChild(link);
			link.click();
		})
		.catch((err) => {
			setIsLoading(false);
			console.log(err)
		});
	}

	/** clear all filters */
	const clearFilters = () => {
		setCurrentPage(1);
		setSelectedItems([]);
		setPriceRange([0, 10000]);
		setParam(paramInitialState);
	};

	return (
		<>
			<AlertCmpt
				open={openError}
				setOpen={setOpenError}
				messageType={messageType}
				messageText={messageText}
			/>
			<AgentISModal
				open={openPopOver}
				containerDetails={containerDetails}
				handleModalClose={handleClose}
			/>
			{progressBar && (
				<div>
					<Modal
						className="modal-popup-progress-bar"
						open={progressBar}
						onClose={() => setProgressBar(false)}
					>
						<Box>
							<CircularProgress />
						</Box>
					</Modal>
				</div>
			)}
			<AgentLayout>
				<div className="agentConfirmation">
					{/* Title */}
					<div className="agentConfirmation-top">
						<p>Manage Containers In Stock</p>
					</div>
					{/* Table */}
					<div className="agentConfirmation-bottom">
						{/* Bottom */}
						<ThemeProvider theme={getMuiTheme()}>
							<MUIDataTable
								title=""
								data={inStockData}
								columns={columnDefs}
								options={options}
							/>
						</ThemeProvider>
					</div>
				</div>
			</AgentLayout>
		</>
	);
};

export default AgentISContainers;
