import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from '@mui/material';
import axios from 'axios';
import * as React from 'react';
import { Navigate } from 'react-router-dom';
import { NotificationTypeEnum } from '../../../shared/models/notificationType';
import { useCallOrdersNotificationApi } from '../../../shared/util/SharedMethods';
import './Confirmation.css';

const Confirmation = (props: any) => {
	const { confirmed, orderDetail, setConfirmed, setSelected } = props;
	const orderId = orderDetail[0]?.orderId;
	const [orderConfirmed, setOrderConfirmed] = React.useState(false);
	const param = {
		status: 'inProgress'
	};

	const handleClose = () => {
		setConfirmed(false);
		setSelected(0);
	};

	function confirmOrder() {
		axios
			.put('/api/container/orders/' + orderId, param)
			.then(() => {
				setOrderConfirmed(true);
			})
			.catch(() => {
				handleClose();
				alert('Error');
			});
	}
	if (orderConfirmed) {
		useCallOrdersNotificationApi(NotificationTypeEnum.ORDERCONFIRMED, orderId);

		useCallOrdersNotificationApi(NotificationTypeEnum.NEWBOOKINGID, orderId);

		return <Navigate to={'/thankyou'} />;
	}

	return (
		<div>
			<Dialog
				open={confirmed}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{'Are you sure you want to confirm your order?'}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						You will be receiving an email with your order's details.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={confirmOrder} autoFocus>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default Confirmation;
