import {
	Autocomplete,
	TextField
} from '@mui/material';
import React from 'react';
import { Location } from '../../../shared/models/location';

interface Props {
	depots: Location[];
	selectedLocations: Location[];
	onLocationChange: (selectedLocations: Location[]) => void;
}

const MultipleSelectDepots: React.FC<Props> = ({ depots, selectedLocations, onLocationChange }) => {
	const handleDepotChange = (event: React.ChangeEvent<unknown>, value: Location[]) => {
		onLocationChange(value);
	};

	return (
		<Autocomplete
			multiple
			options={depots}
			value={selectedLocations}
			onChange={handleDepotChange}
			getOptionLabel={(loc) => loc?.depot ?? ''}
			renderInput={(params) => (
				<TextField
					{...params}
					variant="outlined"
					label="Depots"
					placeholder="Assign Depots to Agent"
				/>
			)}
		/>
	);
};

export default MultipleSelectDepots;
