import { Button } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import * as XLSX from 'xlsx';
import AlertCmpt from '../../buyer/Util/AlertCmpt';

const PriceListUploadButton = (props: any) => {
	const [selectedFile, setSelectedFile] = useState<any>(null);
	const [messageType, setMessageType] = useState('');
	const [messageText, setMessageText] = useState('');
	const [openError, setOpenError] = useState(false);

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		setSelectedFile(file || null);
	};

	const handleUpload = () => {
		if (selectedFile && selectedFile.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
			props.setIsLoading(true);
			const reader = new FileReader();
			reader.onload = async (e) => {
				const target = e.target;
				if (target && target.result) {
					const data = new Uint8Array(target.result as ArrayBuffer);
					const workbook = XLSX.read(data, { type: 'array' });
					const sheetName = workbook.SheetNames[0];
					const worksheet = workbook.Sheets[sheetName];

					const json = XLSX.utils.sheet_to_json(worksheet);
					await axios.post('/api/container/priceLists', json).catch((err) =>{
						setMessageType('error');
						setMessageText(err.response.data.description ?? 'Error Uploading Pricelist');
						setOpenError(true);});
					props.setIsLoading(false);
					props.getData();
				}
			};

			reader.readAsArrayBuffer(selectedFile);
		}
		else {
			setOpenError(true);
			setMessageType('error');
			setMessageText('Invalid File Type');
		}
	};

	return (
		<div>
			
			<AlertCmpt
				open={openError}
				setOpen={setOpenError}
				messageType={messageType}
				messageText={messageText}
			/>
			<input type="file" onChange={handleFileChange} accept=".xlsx, .xls" />
			<Button onClick={handleUpload} disabled={!selectedFile}>
				Upload
			</Button>
		</div>
	);
};

export default PriceListUploadButton;
