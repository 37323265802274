/**
 * CSP - Containers API
 * CMA CGM API - Endpoints needed to retrieve update and delete containers
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export class OrderDetail {
	/**
	 * Order ID
	 */
	orderId?: number;
	/**
	 * Amount of the order
	 */
	amounts?: [
		{
			currency: string;
			price: number;
			currencyType: string;
		}
	];
	/**
	 * Tax amount
	 */
	taxAmounts?: [
		{
			currency: string;
			price: number;
			currencyType: string;
		}
	];
	/**
	 * Total container quantity
	 */
	totalContainerQuantity?: number;
	/**
	 * Customs clearance method of the order
	 */
	customsClearance?: string;
	/**
	 * Origin country of the order
	 */
	originCountry?: string;
	/**
	 * status of the order
	 */
	status?: string;
	/**
	 * Order date
	 */
	orderDate?: string;
	/**
	 * Create date
	 */
	createDate?: string;
	/**
	 * Modified date
	 */
	modifiedDate?: string;
	/**
	 * document status of the order
	 */
	documentStatus?: string;
	/**
	 * payment status of the order
	 */
	paymentStatus?: string;
	/**
	 * Payment date
	 */
	paymentDate?: string;
	/**
	 * booking status of the order
	 */
	bookingStatus?: string;
	/**
	 * Booking date
	 */
	bookingDate?: string;
	/**
	 * Customer UID
	 */
	customerUid?: string;
	/**
	 * Customer first name
	 */
	customerFirstName?: string;
	/**
	 * Customer last name
	 */
	customerLastName?: string;
}
