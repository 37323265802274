
import React, { createContext, useContext, useState } from 'react';

type SelectedItemsContextType = {
  selectedItems: any[];
  setSelectedItems: React.Dispatch<React.SetStateAction<any[]>>;
};

const SelectedItemsContext = createContext<SelectedItemsContextType | undefined>(undefined);

export const useSelectedItems = () => {
  const context = useContext(SelectedItemsContext);
  if (context === undefined) {
    throw new Error('useSelectedItems must be used within a SelectedItemsProvider');
  }
  return context;
};

export const SelectedItemsProvider: any = ({ children } : any) => {
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  return (
    <SelectedItemsContext.Provider value={{ selectedItems, setSelectedItems }}>
      {children}
    </SelectedItemsContext.Provider>
  );
};
