import { createSlice } from '@reduxjs/toolkit';

export const currencySlice = createSlice({
	name: 'currency',
	initialState: {
		value: null
	},
	reducers: {
		setCurrency: (state, action) => {
			state.value = action.payload;
		}
	}
});
export const { setCurrency } = currencySlice.actions;
export default currencySlice.reducer;
