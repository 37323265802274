import { configureStore } from '@reduxjs/toolkit';
import filterReducer from './features/filter';
import cartReducer from './features/cart';
import userReducer from './features/user';
import triggerReducer from './features/trigger';
import menuActiveReducer from './features/menuActive';
import orderReducer from './features/order';
import currencyReducer from './features/currency';
import categoriesReducer from './features/category';
import conditionsReducer from './features/condition';
import typesReducer from './features/type';
import sizesReducer from './features/size';

export const store = configureStore({
	reducer: {
		filter: filterReducer,
		cart: cartReducer,
		loggedInUser: userReducer,
		trigger: triggerReducer,
		menuActive: menuActiveReducer,
		order: orderReducer,
		currency: currencyReducer,
		categories: categoriesReducer,
		conditions: conditionsReducer,
		types: typesReducer,
		sizes: sizesReducer
	}
});
