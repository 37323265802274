import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import {
	Box,
	Button,
	FormControl,
	InputLabel,
	ListItemText,
	MenuItem,
	Modal,
	OutlinedInput,
	Select,
	SelectChangeEvent,
	TextField
} from '@mui/material';
import { useState } from 'react';
import { countryList } from '../../../shared/constants/countryList';

const style = {
	position: 'absolute',
	top: '50%',
	left: '55%',
	transform: 'translate(-50%, -50%)',
	width: '50%',
	height: 600,
	maxHeight: 600,
	borderRadius: '10px',
	bgcolor: 'background.paper',
	border: '2px solid gray',
	boxShadow: 24,
	overflowY: 'auto',
	pt: 2,
	px: 4,
	pb: 3
};

const UpdateVatRule = (props: any) => {
	const { open, setOpen, vatObject: currencyObject, action, updateVatCountryData } = props;
	const [editedValue, setEditedValue] = useState({
		mainCurrency: currencyObject.mainCurrency,
		toCurrency: currencyObject.toCurrency,
		rate: currencyObject.rate,
		id: currencyObject.id
	});

	const handleClose = () => {
		setOpen(false);
	};

	const onInputChange = (e: any, name: string) => {
		const temp = { ...editedValue, [name]: e.target.value };
		setEditedValue(temp);
	};

	const handleSaveModal = () => {
		updateVatCountryData(editedValue);
		setOpen(false);
	};

	const handleSelectChange = (event: SelectChangeEvent) => {
		const name = event.target.name;
		const temp = { ...editedValue, [name]: event.target.value };
		setEditedValue(temp);
	};

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<div style={{ marginBottom: '50px' }}>
						<h2>{action} Currency Pair Conversion</h2>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column'
						}}
					>
						{action === 'Add' ? (
							<FormControl variant="outlined">
								<div>
									{editedValue.mainCurrency === '' && (
										<InputLabel id="test-select-label">Currency 1</InputLabel>
									)}
									<Select
										onChange={handleSelectChange}
										input={<OutlinedInput label="Tag" />}
										value={editedValue.mainCurrency}
										notched={false}
										name="mainCurrency"
										style={{
											marginBottom: '15px',
											maxHeight: '55px',
											width: '200px'
										}}
										labelId="test-select-label"
										label="Currency 2"
										variant="filled"
									>
										{countryList.map((o: any) => (
											<MenuItem key={o.value} value={o.label}>
												<ListItemText primary={o.label} />
											</MenuItem>
										))}
									</Select>
									<CurrencyExchangeIcon style={{ margin: '0px 5px' }} />
									{editedValue.toCurrency === '' && (
										<InputLabel id="test-select-label">Currency 2</InputLabel>
									)}
									<Select
										onChange={handleSelectChange}
										input={<OutlinedInput label="Tag" />}
										value={editedValue.toCurrency}
										notched={false}
										name="toCurrency"
										style={{
											marginBottom: '15px',
											maxHeight: '55px',
											width: '200px'
										}}
										labelId="test-select-label"
										label="Currency 2"
										variant="filled"
									>
										{countryList.map((o: any) => (
											<MenuItem key={o.value} value={o.label}>
												<ListItemText primary={o.label} />
											</MenuItem>
										))}
									</Select>
								</div>
							</FormControl>
						) : (
							<div
								style={{
									display: 'flex',
									flexDirection: 'row',
									alignItems: 'center'
								}}
							>
								<TextField
									disabled={action === 'Edit'}
									required={action === 'Add'}
									id="mainCurrency"
									value={editedValue.mainCurrency}
									label="Currency 1"
									onChange={(e) => onInputChange(e, 'mainCurrency')}
									style={{ marginBottom: '15px' }}
								/>
								<CurrencyExchangeIcon style={{ margin: '0px 5px 20px 5px' }} />
								<TextField
									disabled={action === 'Edit'}
									required={action === 'Add'}
									id="toCurrency"
									value={editedValue.toCurrency}
									label="Currency 2"
									onChange={(e) => onInputChange(e, 'toCurrency')}
									style={{ marginBottom: '15px' }}
								/>
							</div>
						)}
						<TextField
							required
							id="rate"
							label="Rate"
							value={editedValue.rate}
							onChange={(e) => onInputChange(e, 'rate')}
							style={{ marginBottom: '15px' }}
							type="number"
						/>

						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-end',
								padding: '5px'
							}}
						>
							<Button
								variant="contained"
								className="manage-btn"
								onClick={handleSaveModal}
							>
								Save
							</Button>
							<Button
								variant="outlined"
								className="manage-btn-cancel"
								onClick={() => setOpen(false)}
							>
								Cancel
							</Button>
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	);
};

export default UpdateVatRule;
