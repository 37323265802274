import React, { useEffect, useState } from 'react';
import { TextField, MenuItem, Select, Input, Chip, FormControl, InputLabel, FormHelperText } from '@mui/material';
import { User } from '../../../shared/models/user';

interface Props {
	buyers: User[];
	selectedBuyers: User[];
    onBuyerListChange: (selectedBuyers: User[], deselectedBuyers: User[]) => void;
}

const MultipleSelectBuyers: React.FC<Props> = ({ buyers, selectedBuyers, onBuyerListChange }) => {
    let preSelectedBuyers: string[] = [];
    selectedBuyers?.map((selectedBuyer: User) =>{
        preSelectedBuyers.push(selectedBuyer.username)
    })
    const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
    useEffect(() => {
      // Set the initially selected options once the component mounts
      setSelectedOptions(preSelectedBuyers);
    }, [selectedBuyers]);
    
    const handleSelectChange = (event: any) => {
        const value = event.target.value
        setSelectedOptions(value);
        const filteredOptions = buyers.filter(buyer => value.includes(buyer.username));
        const repeatedOptions = buyers.filter(buyer => !filteredOptions.includes(buyer));
        onBuyerListChange(filteredOptions, repeatedOptions)
    };

    
    return (
        <div>
            <FormControl fullWidth>
                <InputLabel id="multiple-select-label">Select Users</InputLabel>
                <Select
                    multiple
                    labelId="multiple-select-label"
                    id="multiple-select"
                    value={selectedOptions}
                    label="Select Users"
                    onChange={handleSelectChange}
                    renderValue={(selected) => (
                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                            {selected.map((value) => (
                            <Chip key={value} label={value} />
                            ))}
                        </div>
                    )}
                    style={{ marginBottom: '15px', minHeight: '65px' }}
                >
                    {buyers.map((buyer) => (
                        <MenuItem key={buyer.ccgId} value={buyer.username}>
                            {buyer.username}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};

export default MultipleSelectBuyers;
