 
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Backdrop, Button, CircularProgress } from '@mui/material';
import Popover from '@mui/material/Popover';
import { SelectChangeEvent } from '@mui/material/Select';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import axios from 'axios';
import MUIDataTable, {
	FilterType,
	MUIDataTableCustomHeadRenderer,
	SelectableRows
} from 'mui-datatables';
import qs from 'qs';
import { useEffect, useState } from 'react';
import {
	dateHeaderTemplate,
	getMuiTheme,
	inputTextHeaderTemplate,
	noSelectHeaderTemplate,
	selectHeaderTemplate
} from '../../../shared/components/DataTable/TableUtils';
import {
	bookingStatusOptions,
	documentStatusOptions,
	orderStatusOptionsBuyer,
	paymentStatusOptions
} from '../../../shared/constants/optionsConstants';
import { CartItem } from '../../../shared/models/cart';
import { Document } from '../../../shared/models/document';
import { OrderDetail } from '../../../shared/models/orderDetail';
import { User } from '../../../shared/models/user';
import colors from '../../../shared/styles/_variables.module.scss';
import BuyerLayout from '../BuyerDashboard/BuyerLayout';
import OrderDetailSummary from '../OrderManagement/OrderDetailSummary';
import UploadFilesPopover from '../OrderManagement/UploadFilesPopover';
import AlertCmpt from '../Util/AlertCmpt';
import { DebouncedFunc, debounce } from 'lodash';

const MyOrders = () => {
	//get userinfo from session storage
	// we use this instead of redux in order to get userinfo faster
	const userinfo: any = window.sessionStorage.getItem('user');
	const [profileUser, setProfileUser] = useState<User>(JSON.parse(userinfo));
	const paramInitial = {
		customerUid: profileUser.ccgId,
		orderDate: '',
		documentStatus: [],
		orderId: '',
		bookingStatus: [],
		paymentStatus: [],
		status: [],
		sortBy: 'orderId',
		sortOrder: 'desc',
		excludeStatus: ['cart']
	};
	const [orders, setOrders] = useState<OrderDetail[]>([]);
	const [openedOrder, setOpenedOrder] = useState<OrderDetail>(new OrderDetail());

	const [param, setParam] = useState<any>(paramInitial);
	const [sorting, setSorting] = useState({ sortBy: 'orderId', sortOrder: 'desc' });
	const [isLoading, setIsLoading] = useState(true);
	const [openDetailPopover, setOpenDetailPopover] = useState(false);
	const [openUploadPopover, setOpenUploadPopover] = useState(false);
	const [orderDetails, setOrderDetails] = useState<CartItem[]>([]);
	const [pageSize, setPageSize] = useState<number>(10);
	const [totalRecords, setTotalRecords] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState(1);
	const [open, setOpen] = useState(false);
	const [messageType, setMessageType] = useState('');
	const [messageText, setMessageText] = useState('');

	useEffect(() => {
		setCurrentPage(1);
		setParam({
			...param,
			sortBy: sorting.sortBy,
			sortOrder: sorting.sortOrder, 
			customerUid: profileUser?.ccgId ?? -1,
			excludeStatus: ['cart']
		});
	}, [sorting, profileUser]);
	
	const handleSelectChange = (event: SelectChangeEvent) => {
		setCurrentPage(1);
		const name = event.target.name;
		const filterTemp = { ...param, [name]: [...event.target.value] };
		setParam(filterTemp);
	};

	const handleInputTextChange = (event: any) => {
		setCurrentPage(1);
		const name = event.target.name;
		const filterTemp = { ...param, [name]: event.target.value };
		setParam(filterTemp);
	};

	const handleDateChange = (newValue: any) => {
		setCurrentPage(1);
		const filterTemp = {
			...param,
			orderDate: newValue
		};
		setParam(filterTemp);
	};


	const getOrders = (filterTemp: any, currentPageIndex?: number) => {
		let headers = undefined;
		const minValue = ((currentPageIndex ?? currentPage) - 1) * pageSize;
		const maxValue = minValue + pageSize - 1;
		headers = { range: `${minValue}-${maxValue}` };
		setIsLoading(true);
		if (profileUser.id > -1) {
			axios
				.get('/api/container/orders', {
					params: filterTemp,
					headers,
					paramsSerializer: {
						serialize: (params) => {
							return qs.stringify(params, { arrayFormat: 'comma', encode: false });
						}
					}
				})
				.then((response) => {
					const rangeVal = response?.headers['content-range'];
					const rangeSplit = rangeVal.toString().split('/');
					const totalElements = parseInt(rangeSplit[1]);
					setTotalRecords(totalElements);
					setPageSize(10);
					setOrders(response.data);
					setIsLoading(false);
				})
				.catch(() => {
					setOrders([]);
					setIsLoading(false);
					setTotalRecords(0);
				});
		} else {
			setOrders([]);
			setIsLoading(false);
		}
	};

	// Create a debounced version of getData
	const debouncedGetOrders = debounce(getOrders, 300);
	 useEffect(() => {
		debouncedGetOrders(param, currentPage);
	}, [currentPage, param]);

	const options = {
		selectableRows: 'none' as SelectableRows,
		customToolbar: () => {
			return (
				<div>
					{/* <Button sx={{ color: colors.colorFillPrimaryBlue }}>Export</Button> */}
					<Button onClick={clearFilters} sx={{ color: colors.colorFillPrimaryBlue }}>
						Clear Filters
					</Button>
				</div>
			);
		},
		filterType: 'dropdown' as FilterType,
		viewColumns: false,
		print: false,
		download: false,
		search: false,
		filter: false,
		onRowClick: (rowData: any) => handleRowClick(rowData),
		textLabels: {
			body: {
				noMatch: isLoading ? <CircularProgress /> : 'No Products match your search'
			}
		},
		page: currentPage - 1,
		rowsPerPageOptions: [],
		count: totalRecords,
		serverSide: true,
		onChangePage(currentPage: number) {
			setCurrentPage(currentPage + 1);
			setIsLoading(true);
			getOrders(param, currentPage + 1);
		}
	};

	const clearFilters = () => {
		setParam(paramInitial);
		setCurrentPage(1);
	};

	/** when click on edit button get the row data by id to be displayed in edit popup */
	const onUploadFile = (rowMetaData: any) => {
		callOrderDetails(rowMetaData.rowData[0], setOpenUploadPopover);

		const dataObj: Record<string, any> = {};

		/* Convert map to json object  */
		rowMetaData?.rowData.forEach((data: any, i: number) => {
			dataObj[i] = data;
		});

		const columnsObj = columnDefs.map((column) => column.name);

		const filterObj: Record<string, any> = {};
		columnsObj.forEach((obj, i) => {
			filterObj[obj] = dataObj[i];
		});

		//setRowDataById(filterObj);
	};

	type OrderSortingMap = {
		[key: string]: string;
	};

	const orderSortingMap: OrderSortingMap = {
		bookingDate: 'ORDER_DATE',
		bookingStatus: 'BOOKING_STATUS',
		documentStatus: 'DOCUMENT_STATUS',
		orderDate: 'ORDER_DATE',
		orderId: 'ID',
		status: 'STATUS',
		originCountry: 'COUNTRY',
		paymentStatus: 'PAYMENT_STATUS',
		customerName: 'CUSTOMER_NAME',
		totalAmount: 'TOTAL_AMOUNT'
	};

	const columnDefs = [
		{
			label: 'Order #',
			name: 'orderId',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					inputTextHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						param,
						handleInputTextChange,
						sorting,
						setSorting,
						orderSortingMap[columnMeta.name]
					)
			}
		},
		{
			label: 'Status',
			name: 'status',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return value === 'sold' || value === 'booked' || value === 'inProgress'
						? 'In Progress'
						: value;
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					selectHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						orderStatusOptionsBuyer,
						param,
						handleSelectChange,
						sorting,
						setSorting,
						orderSortingMap[columnMeta.name]
					)
			}
		},
		{
			label: 'Booking Status',
			name: 'bookingStatus',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					selectHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						bookingStatusOptions,
						param,
						handleSelectChange,
						sorting,
						setSorting,
						orderSortingMap[columnMeta.name]
					)
			}
		},
		{
			label: 'Payment Status',
			name: 'paymentStatus',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					selectHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						paymentStatusOptions,
						param,
						handleSelectChange,
						sorting,
						setSorting,
						orderSortingMap[columnMeta.name]
					)
			}
		},
		{
			label: 'Documents',
			name: 'documentStatus',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					selectHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						documentStatusOptions,
						param,
						handleSelectChange,
						sorting,
						setSorting,
						orderSortingMap[columnMeta.name]
					)
			}
		},
		{
			label: 'Order Date',
			name: 'orderDate',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					dateHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						handleDateChange,
						param,
						sorting,
						setSorting,
						orderSortingMap[columnMeta.name]
					),
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return value ?? tableMeta.rowData[4] ?? '';
				}
			}
		},
		{
			label: 'Manage',
			name: 'upload',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<Button
							disabled={tableMeta?.rowData[1] === 'closed'}
							onClick={(e) => {
								e.stopPropagation();
								onUploadFile(tableMeta);
							}}
						>
							<UploadFileIcon
								sx={{
									color:
										tableMeta?.rowData[1] !== 'closed'
											? colors.colorFillPrimaryBlue
											: colors.colorFillSecondaryGreyMedium
								}}
							/>
						</Button>
					);
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '')
			}
		}
	];

	const handleDocumentDownload = async (doc: Document) => {
		await axios
				.get(`/api/download/${doc.s3DocumentName}`, {
					method: 'GET',
					responseType: 'blob'
				})
				.then((response) =>{
					const url = window.URL.createObjectURL(new Blob([response.data]));
					const link = window.document.createElement('a');
					link.href = url;
					link.setAttribute(
						'download',
						doc.name ?? ''
					);

					// Append to html link element page
					document.body.appendChild(link);

					// Start download
					link.click();
					// Clean up and remove the link
					link?.parentNode?.removeChild(link);
				});
	};

	const callOrderDetails = (
		orderId: any,
		openPopover: React.Dispatch<React.SetStateAction<boolean>>
	) => {
		axios
			.get('/api/container/orderDetails', {
				params: {
					orderId: orderId
				}
			})
			.then((response) => {
				setIsLoading(false);
				setOrderDetails(response.data);
				setOpenedOrder(orders.find((order) => order.orderId === orderId) ?? {});
				openPopover(true);
			})
			.catch(() => {
				setMessageType('error');
				setMessageText('Could not get order details');
				setOpen(true);
				openPopover(false);
				setIsLoading(false);
			});
	};

	const handleRowClick = (rowData: any) => {
		setIsLoading(true);
		callOrderDetails(rowData[0], setOpenDetailPopover);
	};

	return (
		<BuyerLayout>
			<ThemeProvider
				theme={createTheme({
					components: {
						MuiPopover: {
							styleOverrides: {
								paper: {
									borderRadius: '20px',
									border: '1px solid #04246a',
									padding: '10px'
								}
							}
						}
					}
				})}
			>
				<Popover
					open={openDetailPopover}
					onClose={() => {
						setOpenDetailPopover(false);
						getOrders(param);
					}}
					anchorReference={'none'}
					disableAutoFocus={true}
					disableEnforceFocus={true}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<OrderDetailSummary
						orderDetails={orderDetails}
						handleClose={() => {
							setOpenDetailPopover(false);
							getOrders(param);
						}}
						openedOrder={openedOrder}
					/>
				</Popover>
				<Popover
					open={openUploadPopover}
					onClose={() => {
						setOpenUploadPopover(false);
						getOrders(param);
					}}
					anchorReference={'none'}
					disableAutoFocus={true}
					disableEnforceFocus={true}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center'
					}}
				>
					<UploadFilesPopover
						orderId={orderDetails[0]?.orderId ?? -1}
						handleClose={() => {
							setOpenUploadPopover(false);
							getOrders(param);
						}}
						profileUser={profileUser}
						downloadDocument={handleDocumentDownload}
					/>
				</Popover>
			</ThemeProvider>
			<main style={{ marginTop: '60px', filter: openDetailPopover ? 'blur(3px)' : 'none' }}>
				{/* {isLoading && (
					<Backdrop
						sx={{
							color: colors.colorFillPrimaryBlue,
							zIndex: (theme) => theme.zIndex.drawer + 1
						}}
						open={isLoading}
					>
						<CircularProgress color="inherit" />
					</Backdrop>
				)} */}
				<div>
					<AlertCmpt
						open={open}
						setOpen={setOpen}
						messageType={messageType}
						messageText={messageText}
					/>
					<ThemeProvider theme={getMuiTheme()}>
						<MUIDataTable
							title={'My Orders'}
							data={orders}
							columns={columnDefs}
							options={options}
						/>
					</ThemeProvider>
				</div>
			</main>
		</BuyerLayout>
	);
};

export default MyOrders;
