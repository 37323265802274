import {
	Button,
	Checkbox,
	ListItemText,
	OutlinedInput,
	TableCell,
	TableSortLabel,
	TextField
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { createTheme } from '@mui/material/styles';
import colors from '../../../shared/styles/_variables.module.scss';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { debounce } from 'lodash';
import RangeSlider from '../RangeSlider/RangeSlider';

export const getMuiTheme = () =>
	createTheme({
		components: {
			MuiPaper: {
				styleOverrides: {
					root: {
						boxShadow: 'none !important',
						backgroundColor: 'transparent'
					},
					elevation: {
						background: '#fafbfc'
					}
				}
			},
			MUIDataTable: {
				styleOverrides: {
					root: {
						borderRadius: '10px',
						margin: '0px 10px'
					}
				}
			},
			MuiInputBase: {
				styleOverrides: {
					root: { width: '109px', height: '35px' }
				}
			},
			MuiButton: {
				styleOverrides: {
					root: { marginRight: '10px' }
				}
			},
			MuiTableRow: {
				styleOverrides: {
					head: { '&:hover': { backgroundColor: 'transparent' } },
					footer: { '&:hover': { backgroundColor: 'transparent' } }
				}
			},
			MUIDataTableBodyCell: {
				styleOverrides: {
					stackedCommon: {
						paddingTop: '10px',
						borderBottom: '0',
						height: '45px',
						justifyContent: 'center !important',
						display: 'flex',
						borderLeft: `1px solid ${colors.colorFillPrimaryRedHover}`,
						whiteSpace: 'normal',
						textOverflow: 'ellipsis',
						overflow: 'hidden'
					},
					stackedHeader: {
						display: 'none !important'
					},
					root: {
						textAlign: 'center',
						color: colors.colorFillPrimaryBlue
					}
				}
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						position: 'relative',
						borderBottom: '0',
						padding: '10px 0px !important',
						minWidth: '120px',
						maxWidth: '300px',
						width: '300px',
						verticalAlign: 'bottom'
					}
				}
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						height: '35px',
						width: '80%',
						justifyContent: 'center',
						alignSelf: 'center'
					}
				}
			},
			MUIDataTableToolbar: {
				styleOverrides: {
					root: {
						color: colors.colorFillPrimaryBlue
					}
				}
			},
			MUIDataTableBodyRow: {
				styleOverrides: {
					root: {
						boxShadow: 'rgb(221, 222, 223) 5px 5px 5px'
					}
				}
			},
			MuiTablePagination: {
				styleOverrides: {
					selectLabel: {
						marginTop: 'auto'
					},
					displayedRows: {
						marginTop: 'auto'
					}
				}
			},
			MuiList: {
				styleOverrides: {
					root: {
						backgroundColor: 'white'
					}
				}
			},
			MUIDataTableSelectCell: {
				styleOverrides: {
					headerCell: {
						backgroundColor: 'transparent'
					},
					root: {
						width: '50px !important',
						maxWidth: '50px !important'
					}
				}
			},
			MuiSvgIcon: {
				styleOverrides: {
					root: {
						':hover': {
							cursor: 'pointer'
						},
						color: '#04246a'
					}
				}
			}
		}
	});

export const noSelectHeaderTemplate = (label: string, marginBottom = '40px') => {
	return (
		<TableCell variant="head" style={{ textAlign: 'center' }} key={uuidv4()}>
			<h6 style={{ marginBottom: marginBottom }}>{label}</h6>
		</TableCell>
	);
};

export const multipleInputHeaderTemplate = (
    label: string,
    name: string,
    filter: any,
    handleInputTextChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
    sorting: any,
    setSorting: React.Dispatch<
        React.SetStateAction<{
            sortBy: string;
            sortOrder: string;
        }>
    >,
    sortingName?: string,
    changeName?: string
) => {
    const [minValue, setMinValue] = useState(filter[name][0]);
    const [maxValue, setMaxValue] = useState(filter[name][1]);
    const cellKey = useRef(uuidv4());
	const debouncedHandleInputTextChange = useCallback(
		debounce((minValue: string, maxValue: string) => {
			if (minValue === '') {
				minValue = '0';
			}
			const value = `${minValue},${maxValue}`;
			handleInputTextChange({
				target: {
					name: changeName && changeName !== '' ? changeName : name,
					value: value
				}
			} as React.ChangeEvent<HTMLInputElement>);
		}, 300),
		[handleInputTextChange, changeName, name]
	);	

    const handleMinChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
		const { value: minValue } = event.target;
		setMinValue(minValue);
		debouncedHandleInputTextChange(minValue, maxValue);
	};
	
	const handleMaxChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
		const { value: maxValue } = event.target;
		setMaxValue(maxValue);
		debouncedHandleInputTextChange(minValue, maxValue);
	};

    useEffect(() => {
        const filterValue = filter[name];
        if (filterValue && typeof filterValue === 'object') {
            setMinValue(filterValue[0]);
            setMaxValue(filterValue[1]);
        }
    }, [filter[name]]);

    useEffect(() => {
        const filterAtName = filter[changeName && changeName !== '' ? changeName : name];

        if (filterAtName === undefined || filterAtName === '') {
            setMinValue('0');
            setMaxValue('10000');
        }
    }, [filter[changeName && changeName !== '' ? changeName : name]]);

    return (
        <TableCell variant="head" style={{ textAlign: 'center' }} key={cellKey.current}>
            <TextForHeaderComponent
                label={label}
                name={sortingName && sortingName !== '' ? sortingName : name}
                setSorting={setSorting}
                sorting={sorting}
            />
            <div style={{ display: 'flex', minWidth: '150px' }}>
                <TextField
                    sx={{
                        height: '35px',
                        marginRight: '5px',
                        '& .MuiOutlinedInput-root': {
                            height: '35px',
							width: '100%'
                        }
                    }}
                    size="small"
                    id={`min-${changeName && changeName !== '' ? changeName : name}`}
                    name={`min-${changeName && changeName !== '' ? changeName : name}`}
                    variant="outlined"
                    onChange={handleMinChange}
                    value={minValue}
                />
                <TextField
                    sx={{
                        height: '35px',
                        '& .MuiOutlinedInput-root': {
                            height: '35px',
							width: '100%'
                        }
                    }}
                    size="small"
                    id={`max-${changeName && changeName !== '' ? changeName : name}`}
                    name={`max-${changeName && changeName !== '' ? changeName : name}`}
                    variant="outlined"
                    onChange={handleMaxChange}
                    value={maxValue}
                />
            </div>
        </TableCell>
    );
};

export const rangeHeaderTemplate = (
	label: string,
	name: string,
	setPriceRange: React.Dispatch<React.SetStateAction<number[]>> | undefined,
	priceRange: number[],
	sorting: any,
	setSorting: React.Dispatch<
		React.SetStateAction<{
			sortBy: string;
			sortOrder: string;
		}>
	>,
	sortingName?: string
) => {
	return (
		<TableCell
			variant="head"
			style={{
				display: 'flex',
				justifyContent: 'space-evenly',
				flexDirection: 'column',
				textAlign: 'center',
				maxWidth: '150px',
				width: '150px'
			}}
			key={uuidv4()}
		>
			<TextForHeaderComponent
				label={label}
				name={sortingName && sortingName !== '' ? sortingName : name}
				setSorting={setSorting}
				sorting={sorting}
			/>
			<RangeSlider setPriceRange={setPriceRange} priceRange={priceRange} />
		</TableCell>
	);
};

export const selectHeaderTemplate = (
	label: string,
	name: string,
	selectList: any[],
	filter: any,
	handleSelectChange: ((event: SelectChangeEvent<any>, child: ReactNode) => void) | undefined,
	sorting: any,
	setSorting: React.Dispatch<
		React.SetStateAction<{
			sortBy: string;
			sortOrder: string;
		}>
	>,
	sortingName?: string
) => {
	return (
		<TableCell variant="head" style={{ textAlign: 'center' }} key={uuidv4()}>
			<TextForHeaderComponent
				label={label}
				name={sortingName && sortingName !== '' ? sortingName : name}
				setSorting={setSorting}
				sorting={sorting}
			/>
			<Select
				onChange={handleSelectChange}
				sx={{ width: '80%', height: '35px' }}
				name={name}
				input={<OutlinedInput label="Tag" />}
				value={filter && filter[name] && filter[name] !== undefined ? filter[name] : []}
				renderValue={(selected) => {
					const newMap = selected.map((e: string) => {
						const found = selectList.find((obj) => {
							return obj.value === e;
						});
						return found?.label;
					});
					return newMap.join(',');
				}}
				multiple
				notched={false}
			>
				{selectList.map((o: any) => (
					<MenuItem key={o.value} value={o.value}>
						<Checkbox checked={shouldBeChecked(name, o.value, filter)} />
						<ListItemText primary={o.label} />
					</MenuItem>
				))}
			</Select>
		</TableCell>
	);
};

export const emptyHeaderTemplate = () => {
	return <TableCell variant="head" key={uuidv4()}></TableCell>;
};

/* const debounce = (
	func: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
	delay: number
) => {
	let timerId: ReturnType<typeof setTimeout>;
	return (
		...args: Parameters<React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>>
	) => {
		clearTimeout(timerId);
		timerId = setTimeout(() => {
			func(...args);
		}, delay);
	};
}; */

export const inputTextHeaderTemplate = (
	label: string,
	name: string,
	filter: any,
	handleInputTextChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>,
	sorting: any,
	setSorting: React.Dispatch<
		React.SetStateAction<{
			sortBy: string;
			sortOrder: string;
		}>
	>,
	sortingName?: string,
	changeName?: string
) => {
	const [debouncedValue, setDebouncedValue] = useState('');
	const cellKey = useRef(uuidv4());

	const debouncedHandleInputTextChange = useCallback(debounce(handleInputTextChange, 300), [
		handleInputTextChange
	]);

	const handleChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
		const { value } = event.target;
		setDebouncedValue(value);
		debouncedHandleInputTextChange(event);
	};

	useEffect(() => {
		const filterAtName = filter[changeName && changeName !== '' ? changeName : name];

		if (filterAtName === undefined || filterAtName === '') {
			setDebouncedValue('');
		}
	}, [filter[changeName && changeName !== '' ? changeName : name]]);

	return (
		<TableCell variant="head" style={{ textAlign: 'center' }} key={cellKey.current}>
			<TextForHeaderComponent
				label={label}
				name={sortingName && sortingName !== '' ? sortingName : name}
				setSorting={setSorting}
				sorting={sorting}
			/>
			<TextField
				sx={{
					height: '35px',
					'& .MuiOutlinedInput-root': {
						height: '35px'
					}
				}}
				size="small"
				id={changeName && changeName !== '' ? changeName : name}
				name={changeName && changeName !== '' ? changeName : name}
				variant="outlined"
				onChange={handleChange}
				value={debouncedValue}
			/>
		</TableCell>
	);
};

export const dateHeaderTemplate = (
	label: string,
	name: string,
	handleDateChange: (arg0: any) => void,
	filter: any,
	sorting: any,
	setSorting: React.Dispatch<
		React.SetStateAction<{
			sortBy: string;
			sortOrder: string;
		}>
	>,
	sortingName?: string
) => {
	return (
		<TableCell variant="head" style={{ textAlign: 'center' }} key={uuidv4()}>
			<TextForHeaderComponent
				label={label}
				name={sortingName && sortingName !== '' ? sortingName : name}
				setSorting={setSorting}
				sorting={sorting}
			/>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DatePicker
					className="picker"
					format="yyyy-MM-dd"
					value={
						filter && filter[name] && filter[name] !== undefined
							? new Date(filter[name])
							: null
					}
					onChange={(value: any) => {
						handleDateChange(format(value, 'yyyy-MM-dd'));
					}}
				/>
			</LocalizationProvider>
		</TableCell>
	);
};

export const TextForHeaderComponent = (props: any) => {
	return (
		<h6
			style={{
				marginLeft: '10px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}}
			key={uuidv4()}
		>
			<div
				onClick={() => {
					props.setSorting({
						sortBy: props.name,
						sortOrder: props?.sorting?.sortOrder === 'asc' ? 'desc' : 'asc'
					});
				}}
			>
				<TableSortLabel
					active={props?.sorting?.sortBy === props.name}
					direction={props?.sorting?.sortOrder === 'asc' ? 'asc' : 'desc'}
				>
					{props.label}
				</TableSortLabel>
			</div>
		</h6>
	);
};

export const shouldBeChecked = (name: string, item: any, param: any): boolean => {
	if (param === undefined || param[name] === undefined || param[name].length === 0) return false;

	return param[name].find((element: any) => {
		return element === item;
	});
};
