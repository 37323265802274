import { Box, Button, Modal, TextField } from '@mui/material';
import axios from 'axios';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { Location } from '../../../shared/models/location';
import { User } from '../../../shared/models/user';
import MultipleSelectDepots from './MultipleSelectDepots';

const style = {
	position: 'absolute',
	top: '50%',
	left: '55%',
	transform: 'translate(-50%, -50%)',
	width: '50%',
	height: 600,
	maxHeight: 600,
	borderRadius: '10px',
	bgcolor: 'background.paper',
	border: '2px solid gray',
	boxShadow: 24,
	overflowY: 'auto',
	pt: 2,
	px: 4,
	pb: 3
};

type AdminEditAgentProps = {
	open: any;
	setOpen: any;
	user: User;
	updateAgentDepots: any;
};

const AdminEditAgent = (props: AdminEditAgentProps) => {
	const { open, setOpen, user, updateAgentDepots } = props;

	const [depots, setDepots] = useState<Location[]>([]);
	const [selectedDepots, setSelectedDepots] = useState<Location[]>([]);

	const handleClose = () => {
		setOpen(false);
	};

	const handleSaveModal = () => {
		updateAgentDepots(selectedDepots);
		setOpen(false);
	};

	useEffect(() => {
		if (depots.length === 0) {
			axios
				.get('/api/container/locations', {
					params: { country: user.location.split(',')[0] },
					paramsSerializer: {
						serialize: (params) => {
							return qs.stringify(params, { arrayFormat: 'comma', encode: false });
						}
					}
				})
				.then((response) => {
					setDepots(response.data);
				})
				.catch((e) => {
					console.log(e);
					setDepots([]);
				});
		}
	}, []);

	useEffect(() => {
		if (selectedDepots.length === 0) {
			axios
				.get('/api/container/locations', {
					params: { agentUid: user.ccgId },
					paramsSerializer: {
						serialize: (params) => {
							return qs.stringify(params, {
								arrayFormat: 'comma',
								encode: false
							});
						}
					}
				})
				.then((response) => {
					setSelectedDepots(response.data);
				})
				.catch((e) => {
					console.log(e);
					setSelectedDepots([]);
				});
		}
	}, []);

	const handleDepotsChange = (selectedDepots: Location[]) => {
		setSelectedDepots(selectedDepots);
	};

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<div style={{ marginBottom: '50px' }}>
						<h2>Edit Agent {user.id ? '#' + user.id : ''}</h2>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column'
						}}
					>
						<TextField
							disabled
							id="firstName"
							label="First Name"
							value={user.firstName}
							style={{ marginBottom: '15px' }}
						/>
						<TextField
							disabled
							id="lastName"
							label="Last Name"
							value={user.lastName}
							style={{ marginBottom: '15px' }}
						/>
						<TextField
							disabled
							id="emailAddress"
							label="Email Address"
							value={user.email}
							style={{ marginBottom: '15px' }}
						/>
						<TextField
							disabled
							id="country"
							label="Country"
							value={user.location}
							style={{ marginBottom: '15px' }}
						/>

						<div>
							<MultipleSelectDepots
								depots={depots}
								selectedLocations={selectedDepots}
								onLocationChange={handleDepotsChange}
							/>
						</div>

						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-end',
								padding: '5px'
							}}
						>
							<Button
								variant="contained"
								className="manage-btn"
								onClick={handleSaveModal}
							>
								Save
							</Button>
							<Button
								variant="outlined"
								className="manage-btn-cancel"
								onClick={() => setOpen(false)}
							>
								Cancel
							</Button>
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	);
};
export default AdminEditAgent;
