 
/* eslint-disable   */
import CloseIcon from '@mui/icons-material/Close';
import {
	Box,
	Button,
	CircularProgress,
	Modal,
	Tab,
	Table,
	TableBody,
	TableCell,
	TableRow,
	Tabs,
	TextField
} from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createTypography';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FreeMode, Navigation, Thumbs } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { CartItem } from '../../../shared/models/cart';
import { Container } from '../../../shared/models/container';
import { OrderDetail } from '../../../shared/models/orderDetail';
import colors from '../../../shared/styles/_variables.module.scss';
import CardView from './CardView';
import './OrderDetailSummary.css';

type OrderDetailSummaryProps = {
	orderDetails: CartItem[];
	openedOrder: OrderDetail;
	handleClose: any;
};

interface FileData {
	file: string;
	fileType: string;
}

type FileDataMap = Map<number, FileData[]>;

type ContainerData = {
	productId: number;
	containers: Container[];
};

const OrderDetailSummary = ({
	orderDetails,
	handleClose,
	openedOrder
}: OrderDetailSummaryProps) => {
	const [progressBar, setProgressBar] = useState(false);
	const [containers, setContainers] = useState<ContainerData[]>([]);
	const [swiperIndex, setSwiperIndex] = useState<number>(0);
	const [mainSwiperItems, setMainSwiperItems] = useState<JSX.Element[]>([]);
	const [fileDataList, setFileDataList] = useState<any>(new Map());
	const [subtotalAmountUserCurrency, setSubtotalAmountUserCurrency] = useState('');
	const [subtotalAmountMainCurrency, setSubtotalAmountMainCurrency] = useState('');
	const [totalAmountUserCurrency, setTotalAmountUserCurrency] = useState('');
	const [totalAmountMainCurrency, setTotalAmountMainCurrency] = useState('');
	const [vatUserCurrency, setVatUserCurrency] = useState('');
	const [vatMainCurrency, setVatMainCurrency] = useState('');
	const [activeTab, setActiveTab] = useState(0);
	const [activeContainers, setActiveContainers] = useState<any>([]);
	const [containerImage, setContainerImage] = useState<any>([]);
	const [swiperItems, setSwiperItems] = useState<any>([]);

	setTimeout(() => {
		setActiveContainers(containers[activeTab]?.containers)
	}, 1500);

	useEffect(() => {
		const fetchData = async () => {
		const images: any[] = []
		if(activeContainers !== undefined){
			activeContainers?.forEach(async (container: Container) => {
				if (container?.images?.length !== undefined && container?.images?.length > 0) {
					container.images.map((image:any) => {
						const imageDetails = {image: image, comment: container.comments}
						images.push(imageDetails)
					})
				}
				else{
					const imageDetails = {image: null, comment: container.comments}
					images.push(imageDetails)
				}
			});
		}

		const transformedArray = await Promise.all(
			images.map(async (item:any) => {				
				let imageDetails = {image: '/media/container-logo.png' , comment: item.comment}
				if(item.image !== null){
					try {
						// Make an API call using Axios when item is not empty
						const response = await axios.get(`/api/download/${item.image.s3ImageName}`, {
							method: 'GET',
							responseType: 'blob'
						});
						// Return the API response data or any specific property you need
						const blob = new Blob([response.data]);
						const imageUrl = URL.createObjectURL(blob);									
						imageDetails = {image: imageUrl, comment: item.comment}
						} 
					catch (error) {
						console.error('Error fetching data:', error);
						return null; // Return null or handle error case appropriately
					}
				}
				return imageDetails
			})
		);
		const swipeItems = transformedArray?.map((image: any, index: number) => {
			return (
				<SwiperSlide key={index}>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<div>
							<img
								style={{
									width: 500,
									height: 300,
									maxWidth: 500,
									maxHeight: 300,
									borderRadius: '10px'
								}}
								src={image.image}
								alt={image.comment}
							/>
						</div>
						<TextField
							label="Agent Comment"
							value={image.comment ?? ''}
							multiline
							rows={2}
							disabled
							style={{ marginTop: '20px', width: '100%' }}
							InputLabelProps={{
								style: { color: '#000000b3', fontWeight: 'bold' },
							}}
							InputProps={{
								style: { color: '#000000b3', fontWeight: 'bold' },
								sx: { textarea: { WebkitTextFillColor: '#000000b3 !important', fontWeight: 'bold' } },
							}}
						/>
					</div>
				</SwiperSlide>
			);
		});
		
		if(swipeItems.length !== 0){
			setProgressBar(false)
		}
		setSwiperItems(swipeItems);
		};
	
		fetchData();
	}, [activeContainers, activeTab, swiperIndex]);

	const currency = useSelector((state: any) => state.currency.value);

	const handleChangeTab = (event: React.SyntheticEvent, newTab: number) => {
		setActiveTab(newTab);
	};

	const generateMainComponent = (orderDetailsDisplayMain: any, productId: number) => {
		return (
			<div style={{ display: 'flex', flexDirection: 'row' }}>
				<Table title={'Order Details'} style={{
								maxWidth: '500px',
								width: '500px',}}>
					<TableBody>
						{Object.entries(orderDetailsDisplayMain).map(([key, value]) => (
							<TableRow key={key}>
								<TableCell
									variant="head"
									sx={{ color: colors.colorFillPrimaryBlue }}
								>
									{key
										.replace(/([a-z])([A-Z])/g, '$1 $2')
										.charAt(0)
										.toUpperCase() +
										key.replace(/([a-z])([A-Z])/g, '$1 $2').slice(1)}
								</TableCell>
								<TableCell sx={{ color: colors.colorFillPrimaryBlue }}>
									{value?.toString()}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<span
					style={{
						maxWidth: '800px',
						height: '420px',
						width: '600px',
						marginLeft: '50px',
						marginRight: '20px',
						display: 'flex',
						justifyContent: 'space-evenly',
						flexDirection: 'column',
						alignItems: 'center'
					}}
				>
					<Swiper
						spaceBetween={200}
						navigation={true}
						modules={[FreeMode, Navigation, Thumbs]}
						style={
							{
								padding: '10px',
								maxWidth: '500px',
								width: '500px',
								maxHeight: '600px',
								'--swiper-navigation-color': '#314b85',
								'--swiper-navigation-size': '35px'
							} as CSSProperties
						}
						onSlideChange={(swiper) => {
							setSwiperIndex(swiper.activeIndex);
						}}
					>
						{swiperItems}
					</Swiper>
				</span>
			</div>
		);
	};

	const getMainSwiperItems = () => {
		const mainSwipeItems = orderDetails.map((orderDetail, index) => {
			const orderDetailDisplay = getOrderDetailsDisplay(orderDetail);
			return generateMainComponent(orderDetailDisplay, orderDetail.productId ?? -1);
		});
		setMainSwiperItems(mainSwipeItems);
	};

	const getContainers = () => {
		/* orderDetails.map((orderDetail) => {
		}); */
		
		axios
		.get('/api/container/containers', {
			params: { productId: orderDetails[activeTab].productId}
		})
		.then((response) => {
			setContainers((prev) => {
				const existingIndex = prev?.findIndex(
					(item) => item.productId === orderDetails[activeTab]?.productId
				);
				let array: ContainerData[] = [];
				if (existingIndex !== -1) {
					array = [
						...prev.slice(0, existingIndex),
						{
							productId: orderDetails[activeTab]?.productId ?? -1,
							containers: response.data
						},
						...prev.slice(existingIndex + 1)
					];
				} else {
					array = [
						...(prev ?? []),
						{
							productId: orderDetails[activeTab]?.productId ?? -1,
							containers: response.data
						}
					];
				}
				getContainerImages(array);
				return array;
			});
		})
		.catch((e) => console.log(e));
	};

	const getOrderDetailsDisplay = (orderDetails: CartItem) => {
		const taxAmountUser = openedOrder?.taxAmounts?.find(
			(amount: { currency: any }) => amount.currency === currency
		) as any;
		const amountUser = openedOrder?.amounts?.find(
			(amount: { currency: any }) => amount.currency === currency
		) as any;
		const taxAmountMain = openedOrder?.taxAmounts?.find(
			(amount: { currencyType: string }) => amount.currencyType?.toLowerCase() === 'main'
		) as any;
		const amountMain = openedOrder?.amounts?.find(
			(amount: { currencyType: string }) => amount.currencyType.toLowerCase() === 'main'
		) as any;

		const userCurrencyTotal = amountUser?.price + taxAmountUser?.price ?? 0;
		const mainCurrencyTotal = amountMain?.price + taxAmountMain?.price ?? 0;

		setSubtotalAmountUserCurrency(amountUser?.currency + ' ' + amountUser?.price);
		setSubtotalAmountMainCurrency(amountMain?.currency + ' ' + amountMain?.price);
		setTotalAmountUserCurrency(amountUser?.currency + ' ' + userCurrencyTotal);
		setTotalAmountMainCurrency(amountMain?.currency + ' ' + mainCurrencyTotal);
		setVatUserCurrency('% ' + (taxAmountUser?.price / amountUser?.price) * 100);
		setVatMainCurrency('% ' + orderDetails?.vatPercentage);

		const productMainPrice = orderDetails?.productPrices?.find(
			(price) => price?.currencyType?.toLowerCase() === 'main'
		);

		if (orderDetails !== undefined) {
			return {
				Country: orderDetails?.productCountry ?? '',
				City: orderDetails?.productCity ?? '',
				'Pool Full Name': orderDetails?.productFullPoolName ?? '',
				Category: orderDetails?.productCategory ?? '',
				'Size/Type':
					orderDetails?.productSize || orderDetails?.productType
						? orderDetails.productSize + ', ' + orderDetails.productType
						: '',
				Condition: orderDetails?.productCondition ?? '',
				Price: productMainPrice?.currency + ' ' + productMainPrice?.price,
				'Depot Address': orderDetails?.productDepotAddress ?? '',
				Quantity: orderDetails?.quantity ?? 0
			};
		}
	};

	const getContainerImages = (array: { productId: number; containers: Container[] }[]) => {
		const updatedFileDataList: FileDataMap = new Map(fileDataList);

		array.forEach((containersByProduct) => {
			const { productId, containers } = containersByProduct;
			const images: any[] = [];

			containers.forEach((container) => {
				if (container.images && container.images.length > 0) {
					container.images.forEach((imageName) => {
						axios
							.get(`/api/download/${imageName.s3ImageName}`, {
								method: 'GET',
								responseType: 'blob'
							})
							.then((response) => {
								const blob = new Blob([response.data]);
								const imageUrl = URL.createObjectURL(blob);
								images.push(imageUrl)

								// Check if all images have been processed
								if (images.length === container.images?.length) {
									updatedFileDataList.set(productId, images);
									setFileDataList(new Map(updatedFileDataList));
								}
							});
					});
				} else {
					const defaultImageFile = '/media/container-logo.png'; // Default image file path

					images.push(defaultImageFile);
				}
			});

			updatedFileDataList.set(productId, images);
			setFileDataList(new Map(updatedFileDataList));
		});
	};

	useEffect(() => {
		setProgressBar(true);
		getContainers();
	}, [activeTab]);

	useEffect(() => {
		getMainSwiperItems();
	}, [swiperItems]);

	return (
		<div>
			{progressBar && (
				<div>
					<Modal
						className="modal-popup-progress-bar"
						open={progressBar}
						onClose={() => setProgressBar(false)}
					>
						<Box>
							<CircularProgress />
						</Box>
					</Modal>
				</div>
			)}
			<Button
				onClick={handleClose}
				sx={{
					position: 'absolute',
					top: 10,
					right: 0,
					'&:hover': {
						backgroundColor: 'transparent'
					}
				}}
				disableRipple
			>
				<CloseIcon
					sx={{
						color: 'black'
					}}
				/>
			</Button>
			<h4 style={{ paddingTop: '20px', marginLeft: '10px', textDecoration: 'underline' }}>
				Order Details #{orderDetails[0]?.orderId}
			</h4>
			{/* <CardView
				items={mainSwiperItems}
				containers={containers}
				activeTab={activeTab}
				setActiveTab={setActiveTab}
			/> */}
			<Tabs value={activeTab} onChange={handleChangeTab}>
				{orderDetails.map((item: any, index: any) => (
					<Tab key={index} label={`Product ${index + 1}`} />
				))}
			</Tabs>
			{mainSwiperItems.map((item:any, index:number) => (
				<TabPanel key={index} value={activeTab} index={index}>
					{item}
				</TabPanel>
			))}
			<div className="orderDetail-footer">
				<div className="orderDetail-footer-left">
					<div className="orderDetail-footer-row">
						<p>Subtotal</p>
						<p>{subtotalAmountMainCurrency}</p>
					</div>
					<div className="orderDetail-footer-row">
						<p>VAT</p>
						<p>{vatMainCurrency}</p>
					</div>
					<div className="orderDetail-footer-row">
						<p>Total</p>
						<p>{totalAmountMainCurrency}</p>
					</div>
				</div>
			</div>
		</div>
	);
};

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, index, value }) => {
	return (
		<div role="tabpanel" hidden={value !== index}>
			{value === index && <Box p={0}>{children}</Box>}
		</div>
	);
};

export default OrderDetailSummary;
