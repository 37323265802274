import { faBox, faGlobeAsia, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import BuyerLayout from '../../../modules/buyer/BuyerDashboard/BuyerLayout';
import { resetState, setFilter } from '../../../redux/features/filter';
import Select from '../../components/Select/Select';
import { countryOptions } from '../../constants/optionsConstants';
import './Home.css';
import ContactUs from './contact-us-section/ContactUs';
import { fetchCategories } from '../../../redux/features/category';
import { fetchSizes } from '../../../redux/features/size';
import axios from 'axios';


const Home = () => {
	const navigate = useNavigate();
	const filter = useSelector((state: any) => state.filter.value);
	const dispatch = useDispatch();
	const categoriesState = useSelector((state: any) => state.categories);
	const sizesState = useSelector((state: any) => state.sizes);
	const categoryOptions = categoriesState.categories.map((item:any) => ({
		label: item.category,
		value: item.category
	}));
	const sizeOptions = sizesState.sizes.map((item:any) => ({
		label: item.size + 'ft',
		value: item.size
	}));


	const searchContainers = () => {
		dispatch(setFilter(filter));
		navigate('/listProducts');
	};

	const [container, setContainer] = useState(0);
	const [countries, setCountries] = useState(0);
	const [customers, setCustomers] = useState(0);

	// Function to count unique countries attributed to agents
	function countUniqueCountries(data:any) {
		const uniqueCountries = new Set();
		data.forEach((agent:any) => {
		uniqueCountries.add(agent.country);
		});
		return Array.from(uniqueCountries);
	}


	useEffect(() => {
		dispatch(resetState(filter));
		dispatch(fetchCategories());
		dispatch(fetchSizes());
		axios
			.get('/api/container/containers', { params: {status: 'sold'} })
			.then((response) => {
				const length = response.headers['content-range'].split('/')[1];
				if (length !== undefined && length !== null) {
					setContainer(length);
				} else {
					setContainer(0);
				}
			})
			.catch((err) => {
				console.log('error', err);
			});

		/* axios
			.get('/api/container/locations')
			.then((response) => {
				const length = response.headers['content-range'].split('/')[1];
				const uniqueCountries = countUniqueCountries(response.data);
				if (length !== undefined && length !== null) {
					setCountries(uniqueCountries.length);
				} else {
					setCountries(0);
				}
			})
			.catch((err) => {
				console.log('error', err);
			}); */
			setCountries(countryOptions.length)
		
		axios
			.get('/api/container/users', { params: {status: 'active', role: 'buyer'} })
			.then((response) => {
				const length = response.headers['content-range'].split('/')[1];
				if (length !== undefined && length !== null) {
					setCustomers(length);
				} else {
					setCustomers(0);
				}
			})
			.catch((err) => {
				console.log('error', err);
			});

		/* for (let count = 0; count <= 6; count++) {
			setTimeout(() => {
				setContainer(count);
			}, 100);
		}

		for (let count = 0; count <= 50; count++) {
			setTimeout(() => {
				setCountries(count);
			}, 100);
		}

		for (let count = 0; count <= 4; count++) {
			setTimeout(() => {
				setCustomers(count);
			}, 100);
		} */
	}, []);

	return (
		<BuyerLayout>
			{/* Page 1 */}
			<div className="home-page" style={{paddingBottom: '20px'}}>
				{/* Page 1 Top */}
				<div className="home-pageTop">
					{/* Image */}
					<img
						src="../../../media/slider-background.jpg"
						className="home-pageTop-image"
					/>
					{/* Title */}
					<div className="home-pageTop-content">
						{/* Top */}
						<div className="home-pageTop-contentTop">
							<p>Buy CMA CGM Containers</p>
						</div>

						{/* Bottom */}
						<div className="home-pageTop-contentBottom">
							<p>
								Building a container house. Selling goods in a container kiosk.
								Shipping items aboard.
							</p>
							<p>Whatever your container need are, we've got you covered</p>
						</div>
					</div>
				</div>

				{/* Page 1 Bottom */}
				<div className="home-pageBottom">
					{/* Top */}
					<div className="Home-pageBottom-top">
						{/* Item 1 */}
						<div className="Home-pageBottom-item">
							{/* Title */}
							<div className="Home-pageBottom-itemTop">
								<p>Category</p>
							</div>

							{/* Content */}
							<div className="Home-pageBottom-itemBottom">
								<Select
									name="category"
									selectName="type"
									filters={filter}
									options={categoryOptions}
								/>
							</div>
						</div>

						{/* Item 2 */}
						<div className="Home-pageBottom-item">
							{/* Title */}
							<div className="Home-pageBottom-itemTop">
								<p>Size</p>
							</div>

							{/* Content */}
							<div className="Home-pageBottom-itemBottom">
								<Select
									name="size"
									selectName="size"
									filters={filter}
									options={sizeOptions}
								/>
							</div>
						</div>

						{/* Item 3 */}
						<div className="Home-pageBottom-item">
							{/* Title */}
							<div className="Home-pageBottom-itemTop">
								<p>Country</p>
							</div>

							{/* Content */}
							<div className="Home-pageBottom-itemBottom">
								<Select
									name="country"
									selectName="country"
									filters={filter}
									options={countryOptions}
								/>
							</div>
						</div>

						{/* Item 4 */}
						<div className="Home-pageBottom-item">
							<button onClick={searchContainers}>Search Containers</button>
						</div>
					</div>

					{/* Bottom */}
					<div className="Home-pageBottom-bottom">
						{/* Item 1 */}
						<div className="Home-pageBottom-bottom-item">
							{/* Top */}
							<div className="Home-pageBottom-bottom-itemTop">
								<img src="/media/fs.jpg" alt="" />
							</div>

							{/* Bottom */}
							<div className="Home-pageBottom-bottom-itemBottom">
								<p>
									Container Sales Platform or CSP is an online e-commerce platform
									that allows CMA CGM customers to purchase containers that are
									older than 13 years online.
								</p>
							</div>
						</div>

						{/* Item 2 */}
						<div className="Home-pageBottom-bottom-item">
							{/* Top */}
							<div className="Home-pageBottom-bottom-itemTop">
								<img src="/media/ss.png" alt="" />
							</div>

							{/* Bottom */}
							<div className="Home-pageBottom-bottom-itemBottom">
								<p>
									Since 2015 sales of containers were exploded. Agility plan
									proposed to sell more containers to avoid additional logistic
									cost for Empty repositioning.
								</p>
							</div>
						</div>

						{/* Item 3 */}
						<div className="Home-pageBottom-bottom-item">
							{/* Top */}
							<div className="Home-pageBottom-bottom-itemTop">
								<img src="/media/ts.jpg" alt="" />
							</div>

							{/* Bottom */}
							<div className="Home-pageBottom-bottom-itemBottom">
								<p>
									Container sales should be provided effectively and with direct
									collaboration between CMA CGM and final client.
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>

			{/* Page 2 */}
			{/* <ContactUs /> */}

			<div className="parallax2">
				{/* Counter */}
				<div className="counter">
					{/* Item */}
					<div className="counter-item">
						<div className="counter-content">
							{/* Top */}
							<div className="counter-content-top">
								{/* Left */}
								<div className="counter-content-topLeft">
									<FontAwesomeIcon icon={faBox} className="counter-icon" />
								</div>

								{/* Right */}
								<div className="counter-content-topRight">
									<p>{container}</p>
								</div>
							</div>

							{/* Bottom */}
							<div className="counter-content-bottom">
								<p>Sold Containers</p>
							</div>
						</div>
					</div>

					{/* Item */}
					<div className="counter-item">
						<div className="counter-content">
							{/* Top */}
							<div className="counter-content-top">
								{/* Left */}
								<div className="counter-content-topLeft">
									<FontAwesomeIcon icon={faGlobeAsia} className="counter-icon" />
								</div>

								{/* Right */}
								<div className="counter-content-topRight">
									<p>{countries}</p>
								</div>
							</div>

							{/* Bottom */}
							<div className="counter-content-bottom">
								<p>Covered Countries</p>
							</div>
						</div>
					</div>

					{/* Item */}
					<div className="counter-item">
						<div className="counter-content">
							{/* Top */}
							<div className="counter-content-top">
								{/* Left */}
								<div className="counter-content-topLeft">
									<FontAwesomeIcon icon={faUsers} className="counter-icon" />
								</div>

								{/* Right */}
								<div className="counter-content-topRight">
									<p>{customers}</p>
								</div>
							</div>

							{/* Bottom */}
							<div className="counter-content-bottom">
								<p>Active Customers</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</BuyerLayout>
	);
};

export default Home;
