import { Button, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { Container } from '../../models/container';
import './ViewChosenContainers.css';

const ViewChosenContainers = (props: any) => {
	const bookedContainersByOrderDetailsId: Container[] = props.bookedContainersByOrderDetailsId;
	let disabled = false;
	if(props.status === 'Closed'){
		disabled = true
	}
	return (
		<div className="viewContainers">
			{/* Button */}
			<div className="viewContainers-top">
				<Button
					disabled = {disabled}
					variant="contained"
					sx={{ backgroundColor: '#04246a' }}
					onClick={() => {
						props.setIsSaved(false);
					}}
				>
					Edit List
				</Button>
			</div>

			{/* Table */}
			<div className="viewContainers-bottom">
				<Table className="viewContainers-table">
					<TableHead className="viewContainers-head">
						<TableRow className="viewContainers-row">
							<TableCell className="viewContainers-cell">Number</TableCell>
							<TableCell className="viewContainers-cell">Depot</TableCell>
							<TableCell className="viewContainers-cell">Category</TableCell>
							<TableCell className="viewContainers-cell">
								Container Condition
							</TableCell>
							<TableCell className="viewContainers-cell">Container Price</TableCell>
							<TableCell className="viewContainers-cell">Container Size</TableCell>
							<TableCell className="viewContainers-cell">Type</TableCell>
						</TableRow>
					</TableHead>
					<TableBody className="viewContainers-body">
						{bookedContainersByOrderDetailsId &&
							bookedContainersByOrderDetailsId.map(
								(containerInfo: Container, index: number) => {
									return (
										<TableRow className="viewContainers-body-row" key={index}>
											<TableCell className="viewContainers-body-cell">
												{containerInfo?.containerNumber}
											</TableCell>
											<TableCell className="viewContainers-body-cell">
												{containerInfo?.depot?.code}
											</TableCell>
											<TableCell className="viewContainers-body-cell">
												{containerInfo?.category}
											</TableCell>
											<TableCell className="viewContainers-body-cell">
												{containerInfo?.condition?.toUpperCase()}
											</TableCell>
											<TableCell className="viewContainers-body-cell">
												{containerInfo?.price}
											</TableCell>
											<TableCell className="viewContainers-body-cell">
												{containerInfo?.size}
											</TableCell>
											<TableCell className="viewContainers-body-cell">
												{containerInfo?.containerType?.toUpperCase()}
											</TableCell>
										</TableRow>
									);
								}
							)}
					</TableBody>
				</Table>
			</div>
		</div>
	);
};

export default ViewChosenContainers;
