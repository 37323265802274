import { createSlice } from '@reduxjs/toolkit';

export const triggerSlice = createSlice({
	name: 'trigger',
	initialState: {
		value: false
	},
	reducers: {
		setTrigger: (state, action) => {
			state.value = action.payload;
		}
	}
});
export const { setTrigger } = triggerSlice.actions;
export default triggerSlice.reducer;
