import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import { Button, TextField } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTrigger } from '../../../redux/features/trigger';
import { CartItem } from '../../../shared/models/cart';

const CartButton = (props: any) => {
	const loggedInUser = useSelector((state: any) => state.loggedInUser.value);
	const { product, setOpen, setMessageType, setMessageText, hideLabel } = props;
	const dispatch = useDispatch();
	const [isClicked, setIsClicked] = useState(false);
	const [addCalled, setAddCalled] = useState(false);
	const [quantity, setQuantity] = useState(1);
	const cart = useSelector((state: any) => state.cart.value);
	const trigger = useSelector((state: any) => state.trigger.value);
	useEffect(() => {
		(async () => {
			if (cart.length > 0) {
				const inCart = cart.some((o: CartItem) => o.productId === product.id);
				setAddCalled(inCart);
				setIsClicked(inCart);
				const productInCart = cart.find((p: CartItem) => p.productId === product.id);
				if (productInCart !== undefined) setQuantity(productInCart.quantity);
			}
			else {
				setAddCalled(false);
				setIsClicked(false);
			}
		})();
	}, [product, cart]);

	const param = {
		customerUid: loggedInUser?.ccgId,
		productId: product?.id,
		quantity: quantity
	};
	const inputQuantity = document.getElementById('inputQuantity');

	const validChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
	if (inputQuantity !== null) {
		inputQuantity.addEventListener('keydown', function (e) {
			if (!validChars.includes(e.key)) {
				e.preventDefault();
			}
		});
	}
	function addToCart() {
		if (cart.length > 0) {
			if (cart.some((o: CartItem) => o.productCountry !== product.country)) {
				setMessageType('warning');
				setMessageText('You cannot add products from different countries!');
				setOpen(true);
				return;
			}
		}
		if (param.quantity === 0) {
			setIsClicked(false);
			removeFromCart();
			return;
		}
		if (param.productId === undefined) {
			param.productId = product.id;
		}
		axios
			.post('/api/container/orders', param)
			.then(() => {
				setIsClicked(true);
				setMessageType('success');
				setMessageText('Product added to cart!');
				setOpen(true);
				setQuantity(1);
				dispatch(setTrigger(!trigger));
				setAddCalled(true);
			})
			.catch((response) => {
				setMessageType('error');
				setMessageText(
					response?.response?.data?.description ?? 'Could not add product to Cart!'
				);
				setOpen(true);
				setIsClicked(false);
			});
	}

	function updateQuantity(event: any) {
		if (event.target.value > product.inStock) {
			setMessageType('warning');
			setMessageText('Quantity exceeds quantity in Stock!');
			setOpen(true);
		} else {
			setQuantity(parseInt(event.target.value));
			param.quantity = parseInt(event.target.value);
		}
	}

	function removeFromCart() {
		param.quantity = 0;
		setQuantity(1);
		axios
			.put('/api/container/orderDetails', param)
			.then(() => {
				setIsClicked(false);
				setAddCalled(false);
				setMessageType('info');
				setMessageText('Removed from cart');
				setOpen(true);
				dispatch(setTrigger(!trigger));
			})
			.catch(() => {
				setMessageType('error');
				setMessageText('Could not delete product from Cart!');
				setOpen(true);
				setIsClicked(true);
			});
	}

	return (
		<>
			{!isClicked ? (
				<div
					onClick={(e) => e.stopPropagation()}
					style={{
						flex: 0.14,
						fontWeight: 500,
						borderBottom: 0,
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'flex-start'
					}}
				>
					{(loggedInUser?.role === 'buyer' || !loggedInUser) &&
						<Button
							onClick={() => {
								setIsClicked(true);
							}}
							disabled={!loggedInUser}
						>
							<ShoppingCartOutlinedIcon
								sx={{
									color: '#314b85'
								}}
							/>
							<label hidden={hideLabel} style={{ color: '#314b85', marginLeft: '10px' }}>
								Add To Cart
							</label>
						</Button>}
				</div>
			) : !addCalled ? (
				<>
					<div
						onClick={(e) => e.stopPropagation()}
						style={{
							flex: 0.14,
							fontWeight: 500,
							borderBottom: 0,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-start',
							maxWidth: 'min-content'
						}}
					>
						<TextField
							style={{
								minWidth: '55px',
								color: '#314b85'
							}}
							size="small"
							type="number"
							id="inputQuantity"
							onChange={updateQuantity}
							value={quantity}
							inputProps={{ min: '1' }}
						/>
						<Button onClick={addToCart} sx={{ color: 'black', minWidth: '30px' }}>
							<CheckIcon fontSize="small" />
						</Button>
						<Button
							onClick={() => {
								setIsClicked(false);
							}}
							sx={{ color: 'black', minWidth: '30px' }}
						>
							<ClearIcon fontSize="small" />
						</Button>
					</div>
				</>
			) : (
				<>
					<div
						onClick={(e) => e.stopPropagation()}
						style={{
							flex: 0.14,
							fontWeight: 500,
							borderBottom: 0,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-start'
						}}
					>
						<Button onClick={removeFromCart}>
							<DeleteOutlineSharpIcon
								sx={{
									color: '#314b85'
								}}
							/>
						</Button>
					</div>
				</>
			)}
		</>
	);
};

export default CartButton;
