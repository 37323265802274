/**
 * CSP - Containers API
 * CMA CGM API - Endpoints needed to retrieve update and delete containers
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export class Container {
	/**
	 * Container ID
	 */
	id?: number;
	/**
	 * Container Number
	 */
	containerNumber?: string;
	/**
	 * Size of the container
	 */
	size?: number;
	/**
	 * Category to display
	 */
	category?: string;
	/**
	 * Type to filter
	 */
	containerType?: string;
	/**
	 * size type to display
	 */
	typeSizeDisplay?: string;
	/**
	 * Condition of the container
	 */
	condition?: string;
	/**
	 * Depot of the container
	 */
	depot?: { 
		code: string,
		name: string,
		point: string,
		pointName: string,
		countryCode: string,
		countryName: string,
		fullAddress: string,
		postCode: string,
		address1: string,
		address2: string,
		address3: string,
		districtName: string,
		townName: string
	 };
	/**
	 * City of the container
	 */
	city?: string;
	/**
	 * Country of the container
	 */
	country?: string;
	/**
	 * Status of the container
	 */
	status?: string;
	/**
	 * Age of the container
	 */
	age?: number;
	/**
	 * Color of the container
	 */
	color?: string;
	/**
	 * Price of the container
	 */
	price?: number;
	/**
	 * currency of the container
	 */
	currency?: string;
	/**
	 * Date of the record
	 */
	createdAt?: string;
	/**
	 * Product ID
	 */
	productId?: number;
	/**
	 * Assigned agent UID
	 */
	assignedAgentUid?: string;
	/**
	 * Order details ID
	 */
	orderDetailsId?: number;
	/**
	 * Agent Comments
	 */
	comments?: string;
	
	images?:
	[{
		id: number;
		name: string;
		s3ImageName: string;
	}];
}
