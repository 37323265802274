import { ThemeProvider } from '@emotion/react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import './App.scss';
import AdminDashboard from './modules/admin/AdminDashboard/AdminDashboard';
import CurrencyRules from './modules/admin/CurrencyConversion/CurrencyRules';
import DocumentTypes from './modules/admin/DocumentTypes/DocumentTypes';
import AdminManageUsers from './modules/admin/ManageUsers/AdminManageUsers';
import AssignDepotsUser from './modules/admin/ManageUsers/AssignDepotsUser';
import PriceLists from './modules/admin/PriceList/PriceList';
import VatRules from './modules/admin/VatRules/VatRules';
import AgentACContainers from './modules/agent/AgentContainers/AgentACContainers';
import AgentISContainers from './modules/agent/AgentContainers/AgentISContainers';
import AgentDashboard from './modules/agent/AgentDashboard/AgentDashboard';
import ListCompanies from './modules/agent/ListCompanies/ListCompanies';
import SalesHistory from './modules/agent/SalesHistory/SalesHistory';
import Checkout from './modules/buyer/Checkout/Checkout';
import Thankyou from './modules/buyer/Checkout/Thankyou';
import Products from './modules/buyer/Products/Products';
import MyOrders from './modules/buyer/Profile/MyOrders';
import Inactive from './shared/components/ErrorPages/Inactive';
import Invalid from './shared/components/ErrorPages/Invalid';
import PageNotFound from './shared/components/ErrorPages/PageNotFound';
import Home from './shared/layout/Home/Home';
import ManageBooking from './shared/layout/Manage/ManageBooking';
import ManageOrder from './shared/layout/Manage/ManageOrder';
import Login from './shared/layout/UserLogin';
import theme from './shared/styles/theme';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { User } from './shared/models/user';
import { setLoggedInUser } from './redux/features/user';

function App() {
	const [inactive, setInactive] = useState(false);
	const [user, setUser] = useState<User | null>();
	const dispatch = useDispatch();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.get('/api/getUserInfo');
				window.sessionStorage.setItem('user', JSON.stringify(response.data));

				setUser(response.data);
				if (response.data?.status === 'inactive') {
					setInactive(true);
					}
				} 
			catch (error) {
				setUser(null);		
				}
			};

		fetchData();
	}, []);

	dispatch(setLoggedInUser(user));
	return (
		<div className="App">
			<ThemeProvider theme={theme}>
				<BrowserRouter>
					<Routes>
						<Route path={'/'} element={user && user.status === 'active' ? (user.role === 'buyer' ? <Home /> : <Navigate to={`/${user.role}`} />) : user===null && <Home />} />
						<Route path={'/admin'} element={user ? (user.status === 'active' && user.role === 'admin' ? <AdminDashboard /> : <Navigate to='/invalid' />) : user===null && <Navigate to='/invalid'/>} />
						<Route path={'/agent'} element={user ? (user.status === 'active' && user.role === 'agent' ? <AgentDashboard /> : <Navigate to='/invalid' />) : user===null && <Navigate to='/invalid'/>} />
						<Route path={'/inactive'} element={<Inactive />} />
						<Route path={'/invalid'} element={<Invalid />} />
						<Route path={'/howitworks'} element={<Navigate to='/invalid' />} />
						<Route path={'/containertypes'} element={<Navigate to='/invalid' />} />
						<Route path={'/containeruses'} element={<Navigate to='/invalid' />} />
						<Route path={'/contactus'} element={<Navigate to='/invalid' />} />
						<Route path={'/admin/manageusers'} element={user ? (user.status === 'active' && user.role === 'admin' ? <AdminManageUsers /> : <Navigate to='/invalid' />) : user===null && <Navigate to='/invalid'/>}/>
						<Route path={'/admin/user/:userId'} element={user ? (user.status === 'active' && user.role === 'admin' ? <AssignDepotsUser /> : <Navigate to='/invalid' />) : user===null && <Navigate to='/invalid'/>} />
						<Route path={'/booking/manageorders'} element={user ? (user.status === 'active' && (user.role === 'admin' || user.role === 'agent') ? <ManageBooking /> : <Navigate to='/invalid' />) : user===null && <Navigate to='/invalid'/>} />
						<Route path={'/booking/salesHistory'} element={user ? (user.status === 'active' && (user.role === 'admin' || user.role === 'agent') ? <SalesHistory /> : <Navigate to='/invalid' />) : user===null && <Navigate to='/invalid'/>} />
						<Route path={'/booking/ManageOrder/:orderId'} element={user ? (user.status === 'active' && (user.role === 'admin' || user.role === 'agent') ? <ManageOrder /> : <Navigate to='/invalid' />) : user===null && <Navigate to='/invalid'/>} />
						<Route path={'/booking/vatRules'} element={user ? (user.status === 'active' && user.role === 'admin' ? <VatRules /> : <Navigate to='/invalid' />) : user===null && <Navigate to='/invalid'/>} />
						<Route path={'/booking/listCompanies'} element={user ? (user.status === 'active' && (user.role === 'admin') ? <ListCompanies /> : <Navigate to='/invalid' />) : user===null && <Navigate to='/invalid'/>} />
						<Route path={'/booking/currencyRules'} element={user ? (user.status === 'active' && user.role === 'admin' ? <CurrencyRules /> : <Navigate to='/invalid' />) : user===null && <Navigate to='/invalid'/>} />
						<Route path={'/booking/priceLists'} element={user ? (user.status === 'active' && user.role === 'admin' ? <PriceLists /> : <Navigate to='/invalid' />) : user===null && <Navigate to='/invalid'/>} />
						<Route path={'/booking/documentTypes'} element={user ? (user.status === 'active' && user.role === 'admin' ? <DocumentTypes /> : <Navigate to='/invalid' />) : user===null && <Navigate to='/invalid'/>}/>
						<Route path={'/listProducts/checkout'} element={user ? (user.status === 'active' && user.role === 'buyer' ? <Checkout /> : <Navigate to={`/invalid`} />) : user===null && <Navigate to='/invalid'/>}/>
						<Route path={'/listProducts'} element={<Products />} />
						<Route
							path={'/agent/containers/awaiting-confirmation'}
							element={user ? (user.status === 'active' && user.role === 'agent' ? <AgentACContainers /> : <Navigate to='/invalid' />) : user===null && <Navigate to='/invalid'/>}
						/>
						<Route
							path={'/agent/containers/in-stock'}
							element={user ? (user.status === 'active' && user.role === 'agent' ? <AgentISContainers /> : <Navigate to='/invalid' />) : user===null && <Navigate to='/invalid'/>}
						/>
						<Route path={'/myorders'} element={user ? (user.status === 'active' && user.role === 'buyer' ? <MyOrders /> :  <Navigate to={`/invalid`} />) : user===null && <Navigate to='/invalid'/>} />
						<Route path={'/thankyou'} element={<Thankyou />} />
						<Route path="*" element={<PageNotFound />} />
					</Routes>
				</BrowserRouter>
			</ThemeProvider>
		</div>
	);
}

export default App;
