export class Product {
	/**
	 * Product ID
	 */
	id?: number;
	/**
	 * Size of the product
	 */
	size?: number;
	/**
	 * Type of the product
	 */
	productType?: string;
	/**
	 * Product Category
	 */
	category?: string;
	/**
	 * Condition of the product
	 */
	condition?: string;
	/**
	 * Depot of the product
	 */
	depotName?: string;
	/**
	 * Depot of the product
	 */
	depotAddress?: string;
	/**
	 * City of the product
	 */
	city?: string;
	/**
	 * Country of the product
	 */
	country?: string;
	/**
	 * Number of the product in stock
	 */
	inStock?: number;
	/**
	 * price of the product
	 */
	prices?: [
		{
			currency: string;
			price: number;
			type: string;
		}
	];
	/**
	 * starting at price
	 */
	startingAtPrice?: number;
	/**
	 * currency of the product
	 */
	currency?: string;
	/**
	 * Assigned agent UID
	 */
	assignedAgentUid?: string;

	defaultImage?:
	{
		id: number;
		name: string;
		s3ImageName: string;
	};
}
