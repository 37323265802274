import HomeIcon from '@mui/icons-material/Home';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './Invalid.css';

const Invalid = () => {
	const navigate = useNavigate();
	let user: any = window.sessionStorage.getItem('user');
	user = JSON.parse(user);

	const routeChange = () => {
		navigate('/');
	};
	
	function logout() {
		(async () => {
			try {
				window.sessionStorage.removeItem('user');
				window.location.replace(
					`${process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : ''}/auth/sso/logout`
				);
			} catch (e) {
				console.log('Could not log out');
			}
		})();
	}

	return (
		<div className="invalid">
			<div className="invalid-content">
				{/* Row */}
				<div className="invalid-content-row">
					<p>Your account is inactive</p>
				</div>

				{/* Row */}
				<div className="invalid-content-row">
					<p>Contact our support to activate your account</p>
				</div>

				{/* Row */}
				<div className="invalid-content-row">
					<Button
						onClick={() => {
							logout();
						}}
						variant="text"
						startIcon={<HomeIcon />}
						className="invalid-content-button"
					>
						Return to your home page
					</Button>
				</div>
			</div>
		</div>
	);
};

export default Invalid;
