import { Box, Button, Checkbox, FormControlLabel, FormLabel, ListItemButton, Modal, TextField } from '@mui/material';
import { debounce, filter } from 'lodash';
import { DocumentTypeType } from './DocumentTypes';
import axios from 'axios';
import { useEffect, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import {
	Alert,
	AlertTitle,
	IconButton
} from '@mui/material';
import './UpdateDocumentTypes.scss';
import AlertCmpt from '../../buyer/Util/AlertCmpt';
const style = {
	position: 'absolute',
	top: '50%',
	left: '55%',
	transform: 'translate(-50%, -50%)',
	width: '50%',
	height: 600,
	maxHeight: 600,
	borderRadius: '10px',
	bgcolor: 'background.paper',
	border: '2px solid gray',
	boxShadow: 24,
	overflowY: 'auto',
	pt: 2,
	px: 4,
	pb: 3
};

const UpdateDocumentTypes = (props: any) => {
	const { open, setOpen, action, updateDocumentTypeData, documentTypeDataList } = props;
	const documentTypeObject: DocumentTypeType = props.documentTypeObject;
	/** when confirming a selected record if price is null and condition is none set this to true */
	const [confirmError, setConfirmError] = useState<boolean>(false);
	const [messageType, setMessageType] = useState('');
	const [messageText, setMessageText] = useState('');
	/** error pop up */
	const [openError, setOpenError] = useState(false);
	const [checkboxes, setCheckboxes] = useState([
		{ id: 1, label: "admin", checked: true },
		{ id: 2, label: "agent", checked: true },
		{ id: 3, label: "buyer", checked: true },
		// Add more checkboxes as needed
	]);
	// Initialize state to store checked label values
	const [checkedLabels, setCheckedLabels] = useState<string[]>([]);
	const [checkedStatus, setCheckedStatus] = useState(false);
	const [editedValue, setEditedValue] = useState({
		createdDate: documentTypeObject.createdDate,
		deletedDate: documentTypeObject.deletedDate,
		id: documentTypeObject.id,
		deleted: documentTypeObject.deleted,
		lastModifiedDate: documentTypeObject.lastModifiedDate,
		maxSize: documentTypeObject.maxSize,
		name: documentTypeObject.name,
		userRole: ["admin", "agent", "buyer"]
	});
	const checkedLabel: string[] = documentTypeObject.userRole;
	const updateCheckedValues = () => {
		const updatedCheckboxes = checkboxes.map(checkbox => {
		  return {
			...checkbox,
			checked: checkedLabel.includes(checkbox.label)
		  };
		});
	
		setCheckboxes(updatedCheckboxes);
		// Update checkedLabels array with labels of checked checkboxes
			const labels = updatedCheckboxes
			.filter((checkbox) => checkbox.checked)
			.map((checkbox) => checkbox.label);
		setCheckedLabels(labels);
		setEditedValue({ ...editedValue, userRole: labels });
	};

	useEffect(() => {
		if(action === 'Edit'){
			updateCheckedValues();
			setCheckedStatus(documentTypeObject.deleted)
		}
	}, [])
	const handleClose = () => {
		setOpen(false);
	};
	const handleStatusChange = (event: any) =>{
		setCheckedStatus(event.target.checked);
		setEditedValue({ ...editedValue, deleted: event.target.checked });
	}
	// Function to handle checkbox changes
	const handleCheckboxChange = (checkboxId: number) => {
		const updatedCheckboxes = checkboxes.map((checkbox) =>
		  checkbox.id === checkboxId ? { ...checkbox, checked: !checkbox.checked } : checkbox
		);
		setCheckboxes(updatedCheckboxes);
		// Update checkedLabels array with labels of checked checkboxes
			const labels = updatedCheckboxes
			.filter((checkbox) => checkbox.checked)
			.map((checkbox) => checkbox.label);
		setCheckedLabels(labels);
		setEditedValue({ ...editedValue, userRole: labels });
	  };
	function getKeyWithTrueValue(obj : any) {
		let keysWithTrueValue = [];
		for (let key in obj) {
		  if (obj[key] === true) {
			keysWithTrueValue.push(key);
		  }
		}
	  
		return keysWithTrueValue;
	  }
	/* const handleChangeRole = (event: React.ChangeEvent<HTMLInputElement>) => {
		const { value, checked } = event.target;
		if (checked) {
			setRole(prevItems => [...prevItems, value]);
		} else {
			setRole(prevItems => prevItems.filter(item => item !== value));
		}
		console.log('role', role);
	}; */

	const onInputChange = (e: any, name: string) => {
		const temp = { ...editedValue, [name]: e.target.value };
		setEditedValue(temp);
	};

	const handleSaveModal = () => {
		updateDocumentTypeData(editedValue);
	};
	const [documentTypeData, setDocumentTypeData] = useState<DocumentTypeType[]>([]);
	const getData = () => {
		axios
			.get('/api/documents/documentTypes')
			.then((response) => {
				setDocumentTypeData(response.data);
			})
			.catch(() => {
				setDocumentTypeData([]);
			});
	};

	// Debounce the API call with a delay of 100 milliseconds
	const debouncedGetData = debounce(getData, 100);
	useEffect(() => {
		debouncedGetData();
	}, []);

	return (
		<div>
		<AlertCmpt
			open={openError}
			setOpen={setOpenError}
			messageType={messageType}
			messageText={messageText}
		/>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<div style={{ marginBottom: '50px' }}>
						<h2>{action} Document Type</h2>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column'
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column'
							}}
						>
							<TextField
								disabled={action === 'Edit'}
								required={action === 'Add'}
								id="name"
								value={editedValue.name}
								label="Name"
								onChange={(e) => onInputChange(e, 'name')}
								style={{ marginBottom: '15px' }}
							/>
							{/* <TextField
								required={action === 'Add'}
								id="maxSize"
								value={editedValue.maxSize}
								label="Max Size in Kb"
								onChange={(e) => onInputChange(e, 'maxSize')}
								style={{ marginBottom: '15px' }}
								inputProps={{ maxLength: 4 }}
							/> */}
							<p>Please Note that the max file size is 3MB</p>
							<FormControlLabel
								control={<Checkbox checked={checkedStatus} onChange={handleStatusChange} />}
								label="Inactive"
							/>
							<ListItemButton className="list-btns" style={{display: 'block', padding: '20px 0'}}>
								<FormLabel component="legend">Please pick who will be uploading this document*</FormLabel>
								{checkboxes.map((checkbox) => (
									<FormControlLabel
										className="list-item" key={checkbox.id}
										control={
											<Checkbox
												name="admin"
												value="admin"
												className="list-checkbox"
												checked={checkbox.checked}
												onChange={() => handleCheckboxChange(checkbox.id)}
											/>
										}
										label={checkbox.label.toUpperCase()}
									/>
								))}
							</ListItemButton>
						</div>

						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-end',
								padding: '5px'
							}}
						>
							<Button
								variant="contained"
								className="manage-btn"
								onClick={handleSaveModal}
							>
								Save
							</Button>
							<Button
								variant="outlined"
								className="manage-btn-cancel"
								onClick={() => setOpen(false)}
							>
								Cancel
							</Button>
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	);
};

export default UpdateDocumentTypes;
