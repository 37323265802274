import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from '@mui/material';

const DeleteAgentDialog = (props: any) => {
	const { openDialog, setOpenDialog, handleAction, userName } = props;

	const handleClose = () => {
		setOpenDialog(false);
	};

	return (
		<div>
			<Dialog
				open={openDialog}
				onClose={handleClose}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{'Are you sure you want to delete this user?'}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						You will delete user {userName}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleClose}>Cancel</Button>
					<Button onClick={handleAction} autoFocus>
						Confirm
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default DeleteAgentDialog;
