import React from 'react';
import { useSelector } from 'react-redux';
import { CartItem } from '../../../shared/models/cart';
import { OrderDetail } from '../../../shared/models/orderDetail';
import BuyerLayout from '../BuyerDashboard/BuyerLayout';
import AlertCmpt from '../Util/AlertCmpt';
import './Checkout.css';
import CheckoutTabs from './CheckoutTabs';
import EmptyCart from './EmptyCart';

const Checkout = () => {
	const cartItemsRedux: CartItem[] = useSelector((state: any) => state.cart.value);
	const orderDetailRedux: OrderDetail[] = useSelector((state: any) => state.order.value);
	const [open, setOpen] = React.useState(false);
	const [messageType, setMessageType] = React.useState('');
	const [messageText, setMessageText] = React.useState('');

	return (
		<BuyerLayout>
			<AlertCmpt
				open={open}
				setOpen={setOpen}
				messageType={messageType}
				messageText={messageText}
			/>

			<div className="checkout">
				{cartItemsRedux.length === 0 ? (
					<EmptyCart />
				) : (
					<>
						<CheckoutTabs
							cartItems={cartItemsRedux}
							orderDetail={orderDetailRedux}
							setOpen={setOpen}
							setMessageType={setMessageType}
							setMessageText={setMessageText}
						/>
					</>
				)}
			</div>
		</BuyerLayout>
	);
};

export default Checkout;
