import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import constants from './_custom-typography.module.scss';

/* Create a theme instance. */
let theme = createTheme({
	typography: {
		fontFamily: 'Roboto-Bold',
		h1: {
			fontSize: 42,
			fontWeight: 300,
			lineHeight: 1.167,
			fontFamily: 'Roboto-Bold',
			fontStyle: 'normal',
			textTransform: 'none'
		},
		h2: {
			fontSize: 36,
			fontWeight: 400,
			lineHeight: 1.167,
			fontFamily: 'Roboto-Bold',
			fontStyle: 'normal',
			textTransform: 'none'
		},
		h3: {
			fontSize: 28,
			fontWeight: 600,
			lineHeight: 1.167,
			fontFamily: 'Roboto-Regular',
			fontStyle: 'normal',
			textTransform: 'none'
		},
		h4: {
			fontSize: 20,
			fontWeight: 700,
			lineHeight: 1.2,
			fontStyle: 'normal',
			fontFamily: 'Roboto-Regular',
			textTransform: 'none'
		},
		h5: {
			fontSize: 18,
			fontWeight: 600,
			lineHeight: 1.2,
			fontStyle: 'normal',
			fontFamily: 'Roboto-Regular',
			textTransform: 'none'
		},
		h6: {
			fontSize: 18,
			fontWeight: 400,
			lineHeight: 1.2,
			fontStyle: 'normal',
			fontFamily: 'Roboto-Regular',
			textTransform: 'none'
		},
		subtitle1: {
			fontSize: 16,
			fontWeight: 600,
			lineHeight: 1.2,
			fontStyle: 'normal',
			textTransform: 'none'
		},
		subtitle2: {
			fontSize: 14,
			fontWeight: 600,
			lineHeight: 1.2,
			fontStyle: 'normal',
			textTransform: 'none'
		},
		body1: {
			fontSize: 16,
			fontWeight: 400,
			lineHeight: 1.62,
			fontStyle: 'normal',
			textTransform: 'none'
		},
		body2: {
			fontSize: 14,
			fontWeight: 400,
			lineHeight: 1.62,
			fontStyle: 'normal',
			textTransform: 'none'
		},
		caption: {
			fontSize: 12,
			fontWeight: 400,
			lineHeight: 1.62,
			fontStyle: 'normal',
			textTransform: 'none'
		},
		overline: {
			fontSize: 12,
			fontWeight: 400,
			lineHeight: 2.66,
			fontStyle: 'normal',
			textTransform: 'uppercase'
		},
		button: {
			fontSize: 14,
			fontWeight: 600,
			lineHeight: 1.2,
			fontFamily: 'Roboto-Bold !important',
			fontStyle: 'normal',
			borderRadius: 4,
			textTransform: 'none'
		}
	}
});
theme = responsiveFontSizes(theme);
export default theme;
