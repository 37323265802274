/**
 * CSP Notification API
 * CMA CGM Containers Sales Platform (CSP) Notification API
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export enum NotificationTypeEnum {
	PENDINGITEMS = <any>'PENDING_ITEMS',
	ORDERCONFIRMED = <any>'ORDER_CONFIRMED',
	AWAITINGCONFIRMATION = <any>'AWAITING_CONFIRMATION',
	PAYMENTVALIDATED = <any>'PAYMENT_VALIDATED',
	PAYMENTNOTVALIDATED = <any>'PAYMENT_NOT_VALIDATED',
	CUSTOMSCLEARANCENOTVALIDATED = <any>'CUSTOMS_CLEARANCE_NOT_VALIDATED',
	ORDERREADYFORPICKUP = <any>'ORDER_READY_FOR_PICKUP',
	CUSTOMSCLEARANCEVALIDATED = <any>'CUSTOMS_CLEARANCE_VALIDATED',
	LEFTDAYSFORPICKUP = <any>'LEFT_DAYS_FOR_PICKUP',
	LASTDAYFORPICKUP = <any>'LAST_DAY_FOR_PICKUP',
	STORAGEOVERDUE = <any>'STORAGE_OVERDUE',
	NBRCONTAINERSCHANGED = <any>'NBR_CONTAINERS_CHANGED',
	NEWBOOKINGID = <any>'NEW_BOOKING_ID',
	INVOICEUPLOADED = <any>'INVOICE_UPLOADED',
	NEWPAYMENTPROOF = <any>'NEW_PAYMENT_PROOF',
	NEWCUSTOMSCLEARANCEPROOF = <any>'NEW_CUSTOMS_CLEARANCE_PROOF',
	ORDERCANCELLED = <any>'ORDER_CANCELLED',
	NBROFCONTAINERSSOLD = <any>'NBR_OF_CONTAINERS_SOLD'
}
