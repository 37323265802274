import { Autocomplete, Box, Button, Modal, TextField } from '@mui/material';
import { useState } from 'react';
import { countryList } from '../../../shared/constants/countryList';

const style = {
	position: 'absolute',
	top: '50%',
	left: '55%',
	transform: 'translate(-50%, -50%)',
	width: '50%',
	height: 600,
	maxHeight: 600,
	borderRadius: '10px',
	bgcolor: 'background.paper',
	border: '2px solid gray',
	boxShadow: 24,
	overflowY: 'auto',
	pt: 2,
	px: 4,
	pb: 3
};

const UpdateVatRule = (props: any) => {
	const { open, setOpen, vatObject, action, updateVatCountryData } = props;
	const [editedValue, setEditedValue] = useState({
		countryName: vatObject.countryName,
		percentage: vatObject.percentage,
		id: vatObject.id
	});

	const handleClose = () => {
		setOpen(false);
	};

	const onInputChange = (e: any, name: string) => {
		const temp = { ...editedValue, [name]: e.target.value };
		setEditedValue(temp);
	};

	const handleSaveModal = () => {
		updateVatCountryData(editedValue);
		setOpen(false);
	};

	const handleSelectChange = (event: any) => {
		const name = event.target.name;
		const temp = { ...editedValue, [name]: event.target.value };
		setEditedValue(temp);
	};

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<div style={{ marginBottom: '50px' }}>
						<h2>{action} Vat Rule</h2>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column'
						}}
					>
						{action === 'Add' ? (
							<Autocomplete
								options={countryList}
								value={editedValue.countryName}
								onChange={handleSelectChange}
								getOptionLabel={(loc) => loc.label ?? ''}
								style={{ marginBottom: '15px', maxHeight: '55px' }}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										label="Country"
										placeholder="Country"
									/>
								)}
							/>
						) : (
							<TextField
								disabled
								id="countryName"
								value={editedValue.countryName}
								label="Country"
								onChange={(e) => onInputChange(e, 'countryName')}
								style={{ marginBottom: '15px' }}
							/>
						)}
						<TextField
							required
							id="percentage"
							label="VAT"
							value={editedValue.percentage}
							onChange={(e) => onInputChange(e, 'percentage')}
							style={{ marginBottom: '15px' }}
						/>

						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-end',
								padding: '5px'
							}}
						>
							<Button
								variant="contained"
								className="manage-btn"
								onClick={handleSaveModal}
							>
								Save
							</Button>
							<Button
								variant="outlined"
								className="manage-btn-cancel"
								onClick={() => setOpen(false)}
							>
								Cancel
							</Button>
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	);
};

export default UpdateVatRule;
