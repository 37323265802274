import '../Footer.scss';

export const FooterList = [
	{
		url: 'https://www.facebook.com/cmacgm/',
		icon: <img src="/media/icon-facebook.svg" className="footer-icon" />
	},
	{
		url: 'https://www.linkedin.com/company/8050',
		icon: <img src="/media/icon-linkedin.svg" className="footer-icon" />
	},
	{
		url: 'https://twitter.com/cmacgm',
		icon: <img src="/media/icon-twitter.svg" className="footer-icon" />
	},
	{
		url: 'https://www.instagram.com/cmacgm/?hl=en',
		icon: <img src="/media/icon-instagram.svg" className="footer-icon" />
	}
];
