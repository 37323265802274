 
import { Box, Button, Modal, TextField } from '@mui/material';
import axios from 'axios';
import { debounce } from 'lodash';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { User } from '../../../shared/models/user';
import MultipleSelectBuyers from './MultipleSelectBuyers';

const style = {
	position: 'absolute',
	top: '50%',
	left: '55%',
	transform: 'translate(-50%, -50%)',
	width: '50%',
	height: 600,
	maxHeight: 600,
	borderRadius: '10px',
	bgcolor: 'background.paper',
	border: '2px solid gray',
	boxShadow: 24,
	overflowY: 'auto',
	pt: 2,
	px: 4,
	pb: 3
};

const paramInitialState = {
	role: 'buyer',
	firstName: '',
	lastName: '',
	email: '',
	phoneNumber: '',
	status: 'active',
	sortBy: '',
	sortOrder: 'asc'
};

const UpdateCompany = (props: any) => {
	const { open, setOpen, companyObject, action, updateCompanyData } = props;
	const [buyers, setBuyers] = useState<User[]>([]);
	const [selectedBuyers, setSelectedBuyers] = useState<User[]>([]);
    const [deselectedBuyers, setDeselectedBuyers] = useState<User[]>([]);

	/** for filtering */
	const [param, setParam] = useState<any>(paramInitialState);
	const [editedValue, setEditedValue] = useState(
		action.toLowerCase() === 'edit'
			? {
					id: companyObject.id,
					name: companyObject.name,
					deletedFlag: false,
					address: companyObject.address
			  }
			: {
					id: '',
					name: '',
					deletedFlag: false,
					address: ''
			  }
	);

	const handleClose = () => {
		setOpen(false);
	};

	const onInputChange = (e: any, name: string) => {
		const temp = { ...editedValue, [name]: e.target.value };
		setEditedValue(temp);
	};

	const handleSaveModal = () => {
		updateCompanyData(editedValue, selectedBuyers, deselectedBuyers);
		setOpen(false);
	};


	const getData = () => {
		if(action.toLowerCase() === 'edit'){
			const editableUsers = `${companyObject.id}, null`;
			axios
				.get('/api/container/users', {
					params: { role: 'buyer', companyId: companyObject.id }
				})
				.then((response) => {
					setSelectedBuyers(response.data);
				})
				.catch(() => {
					setSelectedBuyers([]);
				});
			
			
			axios
				.get('/api/container/users', {
					params: { role: 'buyer', companyId: editableUsers }
				})
				.then((response) => {
					setBuyers(response.data);
					//setSelectedBuyers(tempArray);
				})
				.catch(() => {
					setBuyers([]);
					//setSelectedBuyers([]);
				});
		}
		else{
			axios
				.get('/api/container/users', {
					params: { role: 'buyer', companyId: 'null' }
				})
				.then((response) => {
					setBuyers(response.data);
					//setSelectedBuyers(tempArray);
				})
				.catch(() => {
					setBuyers([]);
					//setSelectedBuyers([]);
				});
		}
	};


	/** get data for table with pagination */
	/*OLD GET USERS FUNCTION
	const getData = () => {
		axios
			.get('/api/container/users', {
				params: param,
				paramsSerializer: {
					serialize: (params) => {
						return qs.stringify(params, { arrayFormat: 'comma', encode: false });
					}
				}
			})
			.then((response) => {
				setBuyers(response.data);
				const tempArray: User[] = [];
				response.data.forEach((buyer: User) => {
					if (buyer.companyId === companyObject.id) {
						tempArray.push(buyer);
					}
				});
				//setSelectedBuyers(tempArray);
			})
			.catch(() => {
				setBuyers([]);
				//setSelectedBuyers([]);
			});
	}; */
	
	// Debounce the API call with a delay of 100 milliseconds
	const debouncedGetData = debounce(getData, 100);
	useEffect(() => {
		debouncedGetData();
	}, [param]);

	const onBuyerListChange = (selectedBuyers: User[], deselectedBuyers: User[]) => {
		setSelectedBuyers(selectedBuyers);
		setDeselectedBuyers(deselectedBuyers);
	};

	return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<div style={{ marginBottom: '50px' }}>
						<h2>
							{action} Company {editedValue.id ? '#' + editedValue.id : ''}
						</h2>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column'
						}}
					>
						<TextField
							required
							id="name"
							label="Name"
							value={editedValue.name}
							onChange={(e) => onInputChange(e, 'name')}
							style={{ marginBottom: '15px' }}
						/>
						<TextField
							required
							id="address"
							label="Address"
							value={editedValue.address}
							onChange={(e) => onInputChange(e, 'address')}
							style={{ marginBottom: '15px' }}
						/>
						<MultipleSelectBuyers
							buyers={buyers}
							selectedBuyers={selectedBuyers}
							onBuyerListChange={onBuyerListChange}
						/>

						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-end',
								padding: '5px'
							}}
						>
							<Button
								variant="contained"
								className="manage-btn"
								onClick={handleSaveModal}
							>
								Save
							</Button>
							<Button
								variant="outlined"
								className="manage-btn-cancel"
								onClick={() => setOpen(false)}
							>
								Cancel
							</Button>
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	);
};

export default UpdateCompany;
