import { Backdrop, CircularProgress, ThemeProvider, createTheme } from '@mui/material';
import axios from 'axios';
import { debounce } from 'lodash';
import MUIDataTable, { MUIDataTableCustomHeadRenderer, SelectableRows } from 'mui-datatables';
import { useEffect, useState } from 'react';
import {
	getMuiTheme,
	noSelectHeaderTemplate
} from '../../../shared/components/DataTable/TableUtils';
import { PriceListType } from '../../../shared/models/priceList';
import colors from '../../../shared/styles/_variables.module.scss';
import AdminLayout from '../Util/AdminLayout';
import PriceListUploadButton from './PriceListUploadButton';
import { useDispatch } from 'react-redux';
import { setMenuActive } from '../../../redux/features/menuActive';

const PriceLists = () => {
	const dispatch = useDispatch();
	dispatch(setMenuActive('eight'));
	const getMuiTheme = () =>
	createTheme({
		components: {
			MuiPaper: {
				styleOverrides: {
					root: {
						boxShadow: 'none !important',
						backgroundColor: 'transparent',
						cursor: 'default !important',
					},
					elevation: {
						background: '#fafbfc'
					}
				}
			},
			MUIDataTable: {
				styleOverrides: {
					root: {
						borderRadius: '10px',
						margin: '0px 10px',
						cursor: 'default !important',
					}
				}
			},
			MuiInputBase: {
				styleOverrides: {
					root: { width: '109px', height: '35px' }
				}
			},
			MuiButton: {
				styleOverrides: {
					root: { marginRight: '10px' }
				}
			},
			MuiTableRow: {
				styleOverrides: {
					root: {cursor: 'default !important', '&:hover': { cursor: 'default !important', backgroundColor: 'transparent !important' } },
					head: {cursor: 'default !important',  '&:hover': { cursor: 'default !important', backgroundColor: 'transparent !important' } },
					footer: {cursor: 'default !important',  '&:hover': { cursor: 'default !important', backgroundColor: 'transparent !important' } }
				}
			},
			MUIDataTableBodyCell: {
				styleOverrides: {
					stackedCommon: {
						paddingTop: '10px',
						borderBottom: '0',
						height: '45px',
						justifyContent: 'center !important',
						display: 'flex',
						borderLeft: `1px solid ${colors.colorFillPrimaryRedHover}`,
						whiteSpace: 'normal',
						textOverflow: 'ellipsis',
						overflow: 'hidden'
					},
					stackedHeader: {
						display: 'none !important'
					},
					root: {
						textAlign: 'center',
						color: colors.colorFillPrimaryBlue
					}
				}
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						position: 'relative',
						borderBottom: '0',
						padding: '10px 0px !important',
						minWidth: '120px',
						maxWidth: '300px',
						width: '300px',
						verticalAlign: 'bottom'
					}
				}
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						height: '35px',
						width: '80%',
						justifyContent: 'center',
						alignSelf: 'center'
					}
				}
			},
			MUIDataTableToolbar: {
				styleOverrides: {
					root: {
						color: colors.colorFillPrimaryBlue
					}
				}
			},
			MUIDataTableBodyRow: {
				styleOverrides: {
					root: {
						cursor: 'default !important', '&:hover': { cursor: 'default !important', backgroundColor: 'transparent' },
						boxShadow: 'rgb(221, 222, 223) 5px 5px 5px'
					}
				}
			},
			MuiTablePagination: {
				styleOverrides: {
					selectLabel: {
						marginTop: 'auto'
					},
					displayedRows: {
						marginTop: 'auto'
					}
				}
			},
			MuiList: {
				styleOverrides: {
					root: {
						backgroundColor: 'white'
					}
				}
			},
			MUIDataTableSelectCell: {
				styleOverrides: {
					headerCell: {
						backgroundColor: 'transparent'
					},
					root: {
						width: '50px !important',
						maxWidth: '50px !important'
					}
				}
			},
			MuiSvgIcon: {
				styleOverrides: {
					root: {
						':hover': {
							cursor: 'pointer'
						},
						color: '#04246a'
					}
				}
			}
		}
	});
	const [priceListData, setPriceListData] = useState<PriceListType[]>([]);
	const [isLoading, setIsLoading] = useState(true);
	/** total records returned by api */
	const [totalRecords, setTotalRecords] = useState<number>(0);
	/** how many record per page */
	const [pageSize, setPageSize] = useState<number>(10);
	/** current page number */
	const [currentPage, setCurrentPage] = useState(1);

	/** get data for table with pagination */
	const getData = (currentPageIndex?: number) => {
		let headers = undefined;
		const minValue = ((currentPageIndex ?? currentPage) - 1) * pageSize;
		const maxValue = minValue + pageSize - 1;
		headers = { range: `${minValue}-${maxValue}` };

		axios
			.get('/api/container/priceLists', {
				headers
			})
			.then((response) => {
				setPriceListData(response.data);
				const rangeVal = response?.headers['content-range'];
				const rangeSplit = rangeVal.toString().split('/');
				const totalElements = parseInt(rangeSplit[1]);
				setTotalRecords(totalElements);
				setPageSize(10);
				setIsLoading(false);
			})
			.catch(() => {
				setPriceListData([]);
				setIsLoading(false);
			});
	};

	// Debounce the API call with a delay of 100 milliseconds
	const debouncedGetData = debounce(getData, 100);
	useEffect(() => {
		debouncedGetData();
	}, []);

	const columnDefs = [
		{
			label: 'Size Type Category',
			name: 'sizeTypeCategory',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '')
			}
		},
		{
			label: 'Condition',
			name: 'condition',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '')
			}
		},
		{
			label: 'Region',
			name: 'region',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '')
			}
		},
		{
			label: 'Price',
			name: 'price',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || ''),
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					//return tableMeta.rowData[1]?.substring(0, 2) + value ?? '';
					return value ?? '';
				}
			}
		}
	];

	const options = {
		selectableRows: 'none' as SelectableRows,
		customToolbar: () => {
			return (
				<div>
					<PriceListUploadButton setIsLoading={setIsLoading} getData={getData} />
				</div>
			);
		},
		count: totalRecords,
		page: currentPage - 1,
		rowsPerPageOptions: [],
		serverSide: true,
		onChangePage(currentPage: number) {
			setCurrentPage(currentPage + 1);
			getData(currentPage + 1);
		},
		viewColumns: false,
		print: false,
		download: false,
		search: false,
		filter: false
	};

	return (
		<>
			<AdminLayout>
				{isLoading && (
					<Backdrop
						sx={{
							color: colors.colorFillPrimaryBlue,
							zIndex: (theme) => theme.zIndex.drawer + 1
						}}
						open={isLoading}
					>
						<CircularProgress color="inherit" />
					</Backdrop>
				)}
				<div>
					{/* Title */}
					<div>
						<p style={{ fontSize: '22px', fontWeight: '600', color: '#04246a' }}>
							Price List
						</p>
					</div>
					<div>
						<ThemeProvider theme={getMuiTheme()}>
							<MUIDataTable
								title=""
								data={priceListData}
								columns={columnDefs}
								options={options}
							/>
						</ThemeProvider>
					</div>
				</div>
			</AdminLayout>
		</>
	);
};

export default PriceLists;
