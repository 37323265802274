import { createSlice } from '@reduxjs/toolkit';

const initialFilterState = {
	sortBy: 'country',
	sortOrder: 'asc',
	status: '',
	minQuantity: 1,
	productType: [],
	productSize: [],
	productCondition: [],
	productTypeSizeDisplay: [],
	productCountry: [],
	price: []
};
export const filterSlice = createSlice({
	name: 'filter',
	initialState: {
		value: initialFilterState
	},
	reducers: {
		setFilter: (state, action) => {
			state.value = action.payload;
		},
		resetState: (state) => {
			state.value = initialFilterState;
		}
	}
});
export const { setFilter, resetState } = filterSlice.actions;
export default filterSlice.reducer;
