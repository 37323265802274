import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { countryList } from '../../../../shared/constants/countryList';
import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Modal,
	Select,
	Alert,
	AlertTitle,
	TextField,
	Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import qs from 'qs';
import { useEffect, useRef, useState } from 'react';
import AlertCmpt from '../../../buyer/Util/AlertCmpt';
import './AgentEditAcModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import { fetchConditions } from '../../../../redux/features/condition';

const style = {
	position: 'absolute',
	top: '50%',
	left: '55%',
	transform: 'translate(-50%, -50%)',
	width: '50%',
	height: 600,
	maxHeight: 600,
	borderRadius: '10px',
	bgcolor: 'background.paper',
	border: '2px solid gray',
	boxShadow: 24,
	overflowY: 'auto',
	pt: 2,
	px: 4,
	pb: 3
};

interface FileData {
	name: string;
	file: string;
	fileType: string;
	id: number;
	containerNumber: string;
}

const AgentEditAcModal = (props: any) => {
	//get userinfo from session storage
	// we use this instead of redux in order to get userinfo faster
	let userInfo: any = window.sessionStorage.getItem('user');
	let mediaFile: File;
	userInfo = JSON.parse(userInfo);
	const {
		open,
		setOpen,
		rowDataById,
		setRowDataById,
		setAwaitingConfirmationData,
		setConfirmError,
		setCurrentPage,
		param,
		getData,
		setSelectedRows
	} = props;

	const MAX_COUNT = 5;
	const [progressBar, setProgressBar] = useState(false);
	const uploadedSizes = useRef<number>(0);
	const uploadInputRef = useRef<HTMLInputElement | null>(null);
	const [fileDataList, setFileDataList] = useState<FileData[]>([]);
	const [priceListData, setPriceListData] = useState<{ price: number }>({ price: 0 });
	const [openError, setOpenError] = useState(false);
	const [imageSrc, setImageSrc] = useState<any>([]);
	/** when confirming a selected record if price is null and condition is none set this to true */
	const [confirmLocationError, setConfirmLocationError] = useState<boolean>(false);
	const [messageType, setMessageType] = useState('');
	const [messageText, setMessageText] = useState('');
	const [uploadedDocuments, setUploadedDocuments] = useState<any>([]);
	const [agentCountry, setAgentCountry] = useState<{ label: string; value: string }>({
		label: '',
		value: ''
	});
	
	const dispatch = useDispatch();
	const [imageList, setImageList] = useState<any[]>([]);
	const [containerImage, setContainerImage] = useState<any>([]);
	const conditionsState = useSelector((state: any) => state.conditions);

	const conditionsOptions = conditionsState.conditions.map((item:any) => ({
		label: item.condition,
		value: item.condition
	}));
	const maxCharacters = 255;
	const [comments, setComments] = useState(rowDataById?.comments ? rowDataById.comments : '');

	

	useEffect(() => {
		dispatch(fetchConditions());
	},[])

	// Function to update the combined address when any of the address fields change
	const handleAddressChange = () => {
		let combinedAddress = '';
		if (rowDataById.depot.address1 !== null) {
			combinedAddress += `${rowDataById.depot.address1}\n`;
		}
		
		if (rowDataById.depot.address2 !== null) {
			combinedAddress += `${rowDataById.depot.address2}\n`;
		} 
		
		if (rowDataById.depot.address3 !== null) {
			combinedAddress += `${rowDataById.depot.address3}\n`;
		} 
		
		if (rowDataById.depot.postCode !== null) {
			combinedAddress += `${rowDataById.depot.postCode}\n`;
		} 
		
		if (rowDataById.depot.townName !== null) {
			combinedAddress += `${rowDataById.depot.townName}\n`;
		} 
		
		if (rowDataById.depot.countryName !== null) {
			combinedAddress += `${rowDataById.depot.countryName}\n`;
		} 
		
		if (rowDataById.depot.districtName !== null) {
			combinedAddress += `${rowDataById.depot.districtName}\n`;
		} 
		
		if (rowDataById.depot.name !== null) {
			combinedAddress += `${rowDataById.depot.name}\n`;
		} 
		return combinedAddress;
	};
	

	const callUploadFile = (file: File) => {
		const formData = new FormData();
		formData.append('file', file);
		axios
			.put(
				`/api/container/laraContainers/${rowDataById.containerNumber ?? ''}/images`,
				formData
			)
			.then(() => {
				setMessageType('success');
				setMessageText('Successfully Attached');
				setOpenError(true);
				setProgressBar(false);
				getImages();
			})
			.catch((e) => {
				setProgressBar(false);
				setMessageType('error');
				setMessageText(e.response.data.description ?? 'Error Uploading Document');
				setOpenError(true);
			})
	};

	const refreshContainer = () => {
		getData(1);
	};
	

	let filteredImages: any[] = [];

	/* const getDocuments = () => {
		const images: FileData[] = [];
		const imagePromises = rowDataById?.images?.map((image: { id: any; name: any }) => {
			return axios
				.get(
					`/api/container/laraContainers/${rowDataById.containerNumber}/images/${image.id}`,
					{
						responseType: 'arraybuffer'
					}
				)
				.then((response) => {
					const fileType = response.headers['content-type'] || 'application/octet-stream';
					const blob = new Blob([response.data], { type: fileType });
					const reader = new FileReader();

					return new Promise<FileData>((resolve) => {
						reader.onloadend = () => {
							const base64File = reader.result as string;
							const fileExists = images.find((fileData) => fileData.id === image.id);
							if (fileExists === undefined) {
								const fileData: FileData = {
									containerNumber: rowDataById.containerNumber,
									id: image.id,
									name: image.name,
									file: base64File,
									fileType: fileType
								};
								images.push(fileData);
								resolve(fileData);
							} else {
								resolve(null as any); // Return null if the file already exists to filter it out later
							}
						};

						reader.readAsDataURL(blob);
					});
				})
				.catch((e) => {
					console.log(e);
				});
		});
		Promise.all(imagePromises)
			.then((completedImages) => {
				filteredImages = completedImages.filter((image) => image !== null);
				setFileDataList([...filteredImages]);
				console.log('filteredImagesDoc', filteredImages);
			})
			.catch((error) => {
				console.log(error);
			});
	}; */

	useEffect(() => {
		getImages();
	}, []);

	useEffect(() => {
		const fetchData = async () => {
		  const transformedArray = await Promise.all(
			imageList.map(async (item) => {
				if (item.length !== 0) {
					try {
					// Make an API call using Axios when item is not empty
					const response = await axios.get(`/api/download/${item.s3ImageName}`, {
						method: 'GET',
						responseType: 'blob'
					});
					console.log(response)
					// Return the API response data or any specific property you need
					const blob = new Blob([response.data]);
					const imageUrl = URL.createObjectURL(blob);
					const imageDetails = {src: imageUrl, s3ImageName: item.s3ImageName, id: item.id}
					return imageDetails;
					} catch (error) {
					console.error('Error fetching data:', error);
					return null; // Return null or handle error case appropriately
					}
				} else {
					return null; // Return null for empty items
				}
				})
		  );
	
		  setContainerImage(transformedArray)
		};
	
		fetchData();
	  }, [imageList]);

	useEffect(() => {
		getPriceList();
	}, [rowDataById.condition]);

	/** get data for table with pagination */
	const getPriceList = () => {
		axios
			.get(
				`/api/container/priceLists/prices?condition=${rowDataById.condition}&containerNumber=${rowDataById.containerNumber}`
			)
			.then((response) => {
				setPriceListData(response.data);
			})
			.catch(() => {
				setPriceListData({ price: 0 });
			});
	};
	
	function removeSpaces(inputString: string) {
		let stringWithoutSpaces = inputString.replace(/\s/g, '');
		return stringWithoutSpaces;
	}
	/** get data for table with pagination */
	const getImages = () => {
		axios
			.get(
				`/api/container/laraContainers?containerNumber=${rowDataById.containerNumber}`
			)
			.then((response) => {
				setImageList(response.data[0].images);
			});
	};
	
	/**
	 * event to be triggered when uploading files
	 */
	const handleFileEvent = (e: any) => {
		setProgressBar(true);
		mediaFile = e.target.files[0];
		const name = removeSpaces(mediaFile.name)
		const key = new Date().valueOf();
		const s3ImageName = new File([mediaFile],`${key}-${rowDataById.containerNumber}-${name}`, { type: mediaFile.type });

		if (fileDataList.length + 1 > MAX_COUNT) {
			setMessageType('error');
			setMessageText('Max number of attached files Exceeded !');
			setOpenError(true);
			return;
		}

		if (mediaFile.size <= 3000000) {
			if (
				mediaFile.type === 'image/png' ||
				mediaFile.type === 'image/jpg' ||
				mediaFile.type === 'image/jpeg'
			) {
				//.current = uploadedSizes.current + mediaFile.size;
				/* if (uploadedSizes.current > 3000000) {
					uploadedSizes.current = uploadedSizes.current - mediaFile.size;
					setMessageType('error');
					setMessageText('Max uploaded file size should not exceed 3MB !');
					setOpenError(true);
					return;
				} */
				const formData = new FormData();
				formData.append('file', s3ImageName);
				axios.post('/api/upload', formData, {
					headers: {
					'Content-Type': 'multipart/form-data',
					},
				}).then(() => {
					axios
					.put(
						`/api/container/laraContainers/${rowDataById.containerNumber ?? ''}/images`, {
						userCcgid: userInfo.ccgId,
						name: mediaFile.name,
						s3ImageName: s3ImageName.name,
					})
					.then(() => {
						setMessageType('success');
						setMessageText('Successfully Attached');
						setProgressBar(false)
						getImages();
						return;
					})
					.catch((err: any) => {
						setMessageType('error');
						setMessageText(err.response.data.description ?? 'Error Uploading Image');
						setOpen(true);
						setProgressBar(false)
					})

				}).catch((error) =>{
					setMessageType('error');
					setMessageText('Not supported file!');
					setOpenError(true);
					setProgressBar(false)
					return;
				});
			} else {
				setMessageType('error');
				setMessageText('Please attach an image !');
				setOpenError(true);
				setProgressBar(false)
				return;
			}
		}
		else {
			setMessageType('error');
			setMessageText('Max uploaded file size should not exceed 3MB !');
			setOpenError(true);
			setProgressBar(false)
			return;
		}
		
		
		// clear file after upload is handled
		e.target.value = null;
	};

	/**
	 * method to be able to delete any uploaded file
	 */
	const handleClear = (fileDeleted: string) => {
		axios
			.delete(
				'/api/container/laraContainers/' + rowDataById.containerNumber + '/images/' + fileDeleted + '/users/' + userInfo.ccgId
			)
			.then(() => {
				setMessageType('success');
				setMessageText('Successfully Deleted');
				setOpenError(true);
				setProgressBar(false);
				getImages();
			})
			.catch((e) => {
				console.log('ERROR', e);
			});
	};

	const handleClose = () => {
		setOpen(false);
	};

	const onInputChange = (e: any, name: any) => {
		const val = (e.target && e.target.value) || '';
		let dataUpdated;
		if (name === 'address1') {
			dataUpdated = { ...rowDataById };
			dataUpdated['Depot'][`${name}`] = val;
		} else {
			dataUpdated = { ...rowDataById };
			dataUpdated[`${name}`] = val;
		}
		if (name === 'price' && e.target.value === '') {
			dataUpdated['price'] = 0;
		}

		setRowDataById({ ...dataUpdated });
	};

	const paramsContainers = {
		agentUid: userInfo.ccgId,
		containerDetails: [
			{
				comments: rowDataById.comments,
				condition: rowDataById.condition,
				containerNumber: rowDataById.containerNumber,
				price: rowDataById.price,
				status: 'awaiting confirmation'
			}
		]
	};

	const handleSaveModal = () => {
		const headers = { range: '0-9' };
		const data = [
			{
				comments: rowDataById.comments,
				condition: rowDataById.condition,
				containerNumber: rowDataById.containerNumber,
				price: rowDataById.price,
				status: 'awaiting confirmation'
			}
		];
		data.forEach((data) => {
			if (Number(data.price) === 0 || data.condition === 'none') {
				setConfirmError(true);
			} else {
				setConfirmError(false);
			}
		});
		setProgressBar(true);
		axios
			.put('/api/container/laraContainers', paramsContainers)
			.then((response) => {
				if (response.status === 200) {
					if (userInfo === undefined || userInfo === null) return;
					const userLocation = userInfo.location?.split(',')[0].toLowerCase();

					const userLocCode = countryList.find(
						(country: any) => country.label.toLowerCase() === userLocation
					) ?? { label: '', value: '' };
					setAgentCountry(userLocCode);

					let tempParam = param;
					if (userInfo?.role !== 'admin') {
						tempParam = {
							...param,
							country: userLocCode.value ?? ''
						};
					}
					if (userInfo?.role === 'admin' || (tempParam['country'] && tempParam['country'] !== '')) {
					axios
						.get('/api/container/laraContainers', {
							params: tempParam,
							headers,
							paramsSerializer: {
								serialize: (params) => {
									return qs.stringify(params, {
										arrayFormat: 'comma',
										encode: false
									});
								}
							}
						})
						.then((response) => {
							setAwaitingConfirmationData(response?.data);
							setProgressBar(false);
							setOpen(false);
						})
						.catch(() => {
							setAwaitingConfirmationData([]);
							setProgressBar(false);
							setOpen(false);
							setSelectedRows([]);
						});
					}
				}
				refreshContainer();
				setCurrentPage(1);
			})
			.catch((error) => {
				if(error.response.data.description === 'Agent location differs from container location'){
					//setOpen(false);
					setProgressBar(false);
					setMessageType('error');
					setMessageText(error.response.data.description ?? 'Agent location differs from container location');
					setOpenError(true);
				}
			})
	};

	return (
		<div>
			{progressBar && (
				<div>
					<Modal
						className="modal-popup-progress-bar"
						open={progressBar}
						onClose={() => setProgressBar(false)}
					>
						<Box>
							<CircularProgress />
						</Box>
					</Modal>
				</div>
			)}
			<AlertCmpt
				open={openError}
				setOpen={setOpenError}
				messageType={messageType}
				messageText={messageText}
			/>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<div style={{ marginBottom: '50px' }}>
						<h2>Manage</h2>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column'
						}}
					>
						<TextField
							disabled
							required
							id="containerNo"
							label="ID"
							value={rowDataById.containerNumber}
							onChange={(e) => onInputChange(e, 'containerNumber')}
							style={{ marginBottom: '15px' }}
						/>
						<TextField
							required
							disabled
							id="demo-simple-select"
							value={rowDataById.category}
							label="Category"
							style={{ marginBottom: '15px' }}
						/>
						{/* <FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">Category *</InputLabel>
							<Select
								required
								disabled
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={rowDataById.category}
								label="Category *"
								style={{ marginBottom: '15px' }}
							>
								<MenuItem value={'dry'}>Dry</MenuItem>
								<MenuItem value={'Reefer'}>Reefer</MenuItem>
								<MenuItem value={'Pallet Wide'}>Pallet Wide</MenuItem>
								<MenuItem value={'Tank'}>Tank</MenuItem>
							</Select>
						</FormControl> */}
						
						<TextField
							required
							disabled
							id="demo-simple-select"
							value={rowDataById.size}
							label="Size"
							style={{ marginBottom: '15px' }}
						/>
						{/* <FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">Size *</InputLabel>
							<Select
								required
								disabled
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={rowDataById.size}
								label="Size"
								style={{ marginBottom: '15px' }}
							>
								<MenuItem value={20}>20</MenuItem>
								<MenuItem value={40}>40</MenuItem>
								<MenuItem value={45}>45</MenuItem>
							</Select>
						</FormControl> */}
						<TextField
							required
							disabled
							id="demo-simple-select"
							value={rowDataById.containerType}
							label="Type"
							style={{ marginBottom: '15px' }}
						/>
						{/* <FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">Type *</InputLabel>
							<Select
								disabled
								required
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={rowDataById.containerType}
								label="Condition"
								onChange={(e) => onInputChange(e, 'containerType')}
								style={{ marginBottom: '15px' }}
							>
								<MenuItem value={'HC'}>HC</MenuItem>
								<MenuItem value={'ST'}>ST</MenuItem>
							</Select>
						</FormControl> */}
						<TextField
							disabled
							required
							id="containerDepot"
							label="Depot"
							value={rowDataById?.depot?.code}
							onChange={(e) => onInputChange(e, 'containerDepot')}
							style={{ marginBottom: '15px' }}
						/>
						<TextField
							id="containerDepotAddress"
							label="Depot Address"
							multiline
							rows={7}
							disabled
							value={handleAddressChange()}
							style={{ marginBottom: '15px' }}
						/>
						<FormControl fullWidth>
							<InputLabel id="demo-simple-select-label">Condition *</InputLabel>
							<Select
								required
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value={rowDataById.condition}
								label="Condition"
								onChange={(e) => onInputChange(e, 'condition')}
								style={{ marginBottom: '15px' }}
							>
								{conditionsOptions.map((condition: any) => {
									return <MenuItem value={condition.value}>{condition.label}</MenuItem>
								})}
							</Select>
						</FormControl>
						<TextField
							required
							id="price"
							disabled={rowDataById.condition === 'none'}
							label={
								rowDataById.condition === 'none'
									? 'Please Add a condition'
									: 'Suggested Price: ' + priceListData.price
							}
							value={Number(rowDataById.price)}
							onChange={(e) => onInputChange(e, 'price')}
							style={{ marginBottom: '15px' }}
							inputProps={{ maxLength: 4 }}
						/>
						<FormControl fullWidth>
							<TextField
								id="comments"
								label="Comments"
								multiline
								rows={4}
								value={comments}
								onChange={(e) => {
								if (e.target.value.length <= maxCharacters) {
									setComments(e.target.value);
									onInputChange(e, 'comments');
								}
								}}
							/>
							<Typography color={comments.length > maxCharacters ? 'error' : 'textSecondary'}
								style={{ marginBottom: '15px', fontSize: '13px' }}>
								{comments.length}/{maxCharacters} characters
							</Typography>
							{/* Row */}
							<Grid
								item
								xs={12}
								display="flex"
								className="filtre-title"
								style={{ display: 'flex', flexDirection: 'column' }}
							>
								<Button
									disabled={fileDataList.length >= 5}
									onClick={() => {
										// 👇 We redirect the click event onto the hidden input element
										uploadInputRef.current?.click();
									}}
									color="inherit"
									variant="outlined"
									component="label"
									endIcon={<CloudUploadOutlinedIcon color="action" />}
								>
									Upload
								</Button>
								<h6>Max file size should not exceed 3MB</h6>
								<input
									ref={uploadInputRef}
									multiple
									type="file"
									accept="image/jpeg, image/png, image/jpg"
									style={{ display: 'none' }}
									onChange={handleFileEvent}
								/>
							</Grid>
							
							{/* Row */}
							<Grid
								item
								xs={12}
								display="flex"
								className="filtre-title-clear"
								flexWrap="wrap"
							>
								{containerImage.map((blob: any, index: any) =>{
									return <Grid item display="flex" key={index}>
											<img style={{
												width: 50,
												height: 30,
												maxWidth: 50,
												maxHeight: 30,
												borderRadius: '10px'
											}} src={blob.src} alt={blob.s3ImageName} />
											<IconButton
												onClick={() => handleClear(blob.id)}
												color="inherit"
												component="label"
												size="small" 
											>
												<ClearOutlinedIcon color="action" fontSize="small"/>
											</IconButton>
									</Grid>
								})}
							</Grid>
						</FormControl>
						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-end',
								padding: '5px'
							}}
						>
							<Button
								variant="contained"
								className="manage-btn"
								onClick={handleSaveModal}
							>
								Save
							</Button>
							<Button
								variant="outlined"
								className="manage-btn-cancel"
								onClick={() => {
									setOpen(false); 
									setProgressBar(false)
								}}
							>
								Cancel
							</Button>
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	);
};

export default AgentEditAcModal;
