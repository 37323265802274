import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { countryList } from '../../../shared/constants/countryList';
import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	MenuItem,
	Modal,
	Select,
	Alert,
	AlertTitle,
	TextField
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import axios from 'axios';
import qs from 'qs';
import { useEffect, useRef, useState } from 'react';
import AlertCmpt from '../../buyer/Util/AlertCmpt';
import { Container } from '../../../shared/models/container';

const style = {
	position: 'absolute',
	top: '50%',
	left: '55%',
	transform: 'translate(-50%, -50%)',
	width: '50%',
	height: 600,
	maxHeight: 600,
	borderRadius: '10px',
	bgcolor: 'background.paper',
	border: '2px solid gray',
	boxShadow: 24,
	overflowY: 'auto',
	pt: 2,
	px: 4,
	pb: 3
};

type ContainertDetailsSummaryProps = {
	containerDetails: Container;
	handleModalClose: any;
	open: boolean;
};

interface FileData {
	name: string;
	file: string;
	fileType: string;
	id: number;
	containerNumber: string;
}

const AgentISModal = ({ containerDetails, handleModalClose, open }: ContainertDetailsSummaryProps) => {
	//get userinfo from session storage
	// we use this instead of redux in order to get userinfo faster
	let userInfo: any = window.sessionStorage.getItem('user');
	let mediaFile: File;
	userInfo = JSON.parse(userInfo);

	const MAX_COUNT = 5;
	const [progressBar, setProgressBar] = useState(false);
	const [priceListData, setPriceListData] = useState<{ price: number }>({ price: 0 });
	const [openError, setOpenError] = useState(false);
	/** when confirming a selected record if price is null and condition is none set this to true */
	const [messageType, setMessageType] = useState('');
	const [messageText, setMessageText] = useState('');
	const [containerImage, setContainerImage] = useState<any>([]);
	let imageList: any = containerDetails.images

	// Function to update the combined address when any of the address fields change
	const handleAddressChange = () => {
		let combinedAddress = '';
		if (containerDetails?.depot?.address1 !== null) {
			combinedAddress += `${containerDetails?.depot?.address1}\n`;
		}
		
		if (containerDetails?.depot?.address2 !== null) {
			combinedAddress += `${containerDetails?.depot?.address2}\n`;
		} 
		
		if (containerDetails?.depot?.address3 !== null) {
			combinedAddress += `${containerDetails?.depot?.address3}\n`;
		} 
		
		if (containerDetails?.depot?.postCode !== null) {
			combinedAddress += `${containerDetails?.depot?.postCode}\n`;
		} 
		
		if (containerDetails?.depot?.townName !== null) {
			combinedAddress += `${containerDetails?.depot?.townName}\n`;
		} 
		
		if (containerDetails?.depot?.countryName !== null) {
			combinedAddress += `${containerDetails?.depot?.countryName}\n`;
		} 
		
		if (containerDetails?.depot?.districtName !== null) {
			combinedAddress += `${containerDetails?.depot?.districtName}\n`;
		} 
		
		if (containerDetails?.depot?.name !== null) {
			combinedAddress += `${containerDetails?.depot?.name}\n`;
		} 
		return combinedAddress;
	};

	useEffect(() => {
		const fetchData = async () => {
			if (imageList !== null && imageList !== undefined){
				const transformedArray = await Promise.all(
					imageList.map(async (item: any) => {
						if (item.length !== 0) {
							try {
							// Make an API call using Axios when item is not empty
							const response = await axios.get(`/api/download/${item.s3ImageName}`, {
								method: 'GET',
								responseType: 'blob'
							});
							// Return the API response data or any specific property you need
							const blob = new Blob([response.data]);
							const imageUrl = URL.createObjectURL(blob);
							const imageDetails = {src: imageUrl, s3ImageName: item.s3ImageName, id: item.id}
							return imageDetails;
							} catch (error) {
							console.error('Error fetching data:', error);
							return null; // Return null or handle error case appropriately
							}
						} else {
							return null; // Return null for empty items
						}
					})
				);
				setContainerImage(transformedArray)
			}
		};
	
		fetchData();
	}, [imageList]);


	return (
		<div>
			{progressBar && (
				<div>
					<Modal
						className="modal-popup-progress-bar"
						open={progressBar}
						onClose={() => setProgressBar(false)}
					>
						<Box>
							<CircularProgress />
						</Box>
					</Modal>
				</div>
			)}
			<AlertCmpt
				open={openError}
				setOpen={setOpenError}
				messageType={messageType}
				messageText={messageText}
			/>
			<Modal
				open={open}
				onClose={handleModalClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Button
						onClick={handleModalClose}
						sx={{
							position: 'absolute',
							top: 10,
							right: 0,
							'&:hover': {
								backgroundColor: 'transparent'
							}
						}}
						disableRipple
					>
						<CloseIcon
							sx={{
								color: 'black'
							}}
						/>
					</Button>
					<div style={{ marginBottom: '50px' }}>
						<h2>In Stock Container Details</h2>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column'
						}}
					>
						<TextField
							disabled
							id="containerNo"
							label="ID"
							value={containerDetails.containerNumber}
							style={{ marginBottom: '15px' }}
						/>
						<TextField
							disabled
							id="category"
							label="Category"
							value={containerDetails.category}
							style={{ marginBottom: '15px' }}
						/>
						<TextField
							disabled
							id="size"
							label="Size"
							value={containerDetails.size}
							style={{ marginBottom: '15px' }}
						/>
						<TextField
							disabled
							id="type"
							label="Type"
							value={containerDetails.containerType}
							style={{ marginBottom: '15px' }}
						/>
						<TextField
							disabled
							id="containerDepot"
							label="Depot"
							multiline
							rows={8}
							value={handleAddressChange()}
							style={{ marginBottom: '15px' }}
						/>
						<TextField
							disabled
							id="containerDepot"
							label="Condition"
							value={containerDetails?.condition}
							style={{ marginBottom: '15px' }}
						/>
						<TextField
							disabled
							id="price"
							label="Price"
							value={containerDetails?.price}
							style={{ marginBottom: '15px' }}
						/>
						<TextField
							disabled
							id="comments"
							label="Comments"
							multiline
							rows={4}
							value={containerDetails?.comments ? containerDetails?.comments : ''}
							style={{ marginBottom: '15px' }}
						/>
							<Grid
								item
								xs={12}
								display="flex"
								className="filtre-title-clear"
								flexWrap="wrap"
							>
								{containerImage.map((blob: any, index: any) =>{
									return <Grid item display="flex" key={index}>
											<img style={{
												width: 50,
												height: 30,
												maxWidth: 50,
												maxHeight: 30,
												borderRadius: '10px',
												marginRight: 15
											}} src={blob.src} alt={blob.s3ImageName} />
									</Grid>
								})}
							</Grid>
					</div>
				</Box>
			</Modal>
		</div>
	);
};

export default AgentISModal;
