import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setMenuActive } from '../../../redux/features/menuActive';
import './Menu.css';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

const Menu = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const menuId = useSelector((state: any) => state.menuActive.value['menuId']);

	const [open, setOpen] = useState(true);

	const goToDashboard = () => {
		navigate('/agent');
		dispatch(setMenuActive('one'));
	};

	const goToContainersAC = () => {
		navigate('/agent/containers/awaiting-confirmation');
		dispatch(setMenuActive('two'));
	};

	const goToContainersIS = () => {
		navigate('/agent/containers/in-stock');
		dispatch(setMenuActive('three'));
	};

	const goToManageOrders = () => {
		navigate('/booking/manageorders');
		dispatch(setMenuActive('four'));
	};

	const goToSalesHistory = () => {
		navigate('/booking/salesHistory');
		dispatch(setMenuActive('five'));
	};

	const goToCompanies = () => {
		navigate('/booking/listCompanies');
		dispatch(setMenuActive('six'));
	};

	return (
		<nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
			<div className="position-sticky menu">
				{/* Dashboard */}
				<ListItemButton
					onClick={goToDashboard}
					className={menuId === 'one' ? 'list-active' : 'inactive'}
					style={{ color: 'white !important' }}
				>
					<ListItemText primary="Dashboard" className="menu-links-agent" />
				</ListItemButton>

				{/* Containers */}
				<ListItemButton onClick={() => setOpen(!open)}>
					<ListItemText primary="Containers" className="menu-links-agent" />
					{open ? <ExpandLess /> : <ExpandMore />}
				</ListItemButton>
				<Collapse in={open} timeout="auto" unmountOnExit>
					<List component="div" disablePadding>
						<ListItemButton
							onClick={goToContainersAC}
							className={
								`list-button ${menuId === "two" ? "list-active" : "inactive"}`
							}
						>
							Awaiting Confirmation
						</ListItemButton>

						<ListItemButton
							onClick={goToContainersIS}
							className={
								`list-button ${menuId === "three" ? "list-active" : "inactive"}`
							}
						>
							In Stock
						</ListItemButton>
					</List>
				</Collapse>

				{/* Manage Orders */}
				<ListItemButton
					onClick={goToManageOrders}
					className={menuId === 'four' ? 'list-active' : 'inactive'}
				>
					<ListItemText primary="Manage Orders" className="menu-links-agent" />
				</ListItemButton>
				{/* Manage Orders */}
				<ListItemButton
					onClick={goToSalesHistory}
					className={menuId === 'five' ? 'list-active' : 'inactive'}
				>
					<ListItemText primary="Sales History" className="menu-links-agent" />
				</ListItemButton>
				{/* <ListItemButton
					onClick={goToCompanies}
					className={menuId === 'six' ? 'list-active' : 'inactive'}
					key="companies"
				>
					<ListItemText primary="Companies" className="menu-links-agent" />
				</ListItemButton> */}
			</div>
		</nav>
	);
};

export default Menu;
