import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
const EmptyCart = () => {
	return (
		<>
			<div
				style={{
					position: 'relative',
					left: '40%',
					width: '300px',
					textAlign: 'center'
				}}
			>
				<ShoppingBasketIcon sx={{ fontSize: 160, color: '#04246a' }} />
				<h2>Your cart is empty</h2>
			</div>
		</>
	);
};

export default EmptyCart;
