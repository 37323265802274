import DeleteOutlineSharpIcon from '@mui/icons-material/DeleteOutlineSharp';
import { Button, TableCell, TextField } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { CartItem } from '../../../shared/models/cart';
import { setTrigger } from '../../../redux/features/trigger';

const ItemsSummaryQuantity = (props: {
	cartItem: CartItem;
	setMessageType: any;
	setMessageText: any;
	setOpen: any;
	setCartItems: any;
	cartItems: any;
}) => {
	const loggedInUser = useSelector((state: any) => state.loggedInUser.value);
	const { cartItem, setOpen, setMessageType, setMessageText } = props;
	const cart = useSelector((state: any) => state.cart.value);
	const [quantity, setQuantity] = useState(cart.quantity ?? 0);
	const trigger = useSelector((state: any) => state.trigger.value);
	const dispatch = useDispatch();

	const [param, setParam] = useState({
		customerUid: loggedInUser?.ccgId,
		productId: cartItem.productId,
		quantity: quantity
	});

	useEffect(() => {
		if (cart.length > 0) {
			const productInCart = cart.find(
				(p: { productId: any }) => p.productId === cartItem.productId
			);
			setQuantity(productInCart?.quantity);
		}
	}, [cartItem, cart]);

	const inputQuantity = document.getElementById('inputQuantity');

	const validChars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
	if (inputQuantity !== null) {
		inputQuantity.addEventListener('keydown', function (e) {
			if (!validChars.includes(e.key)) {
				e.preventDefault();
			}
		});
	}

	function updateQuantity(event: any) {
		if (event > (cartItem.inStock ?? 1)) {
			setMessageType('warning');
			setMessageText('Quantity exceeds quantity in Stock!');
			setOpen(true);
		} else {
			setQuantity(event);
			const tempParam = {
				customerUid: loggedInUser?.ccgId,
				productId: cartItem.productId,
				quantity: event
			};
			setParam(tempParam);
			axios
				.put('/api/container/orderDetails', tempParam)
				.then(() => {
					dispatch(setTrigger(!trigger));
					if (event === 0) {
						setMessageType('info');
						setMessageText('Removed from cart');
						setOpen(true);
					} else {
						setMessageType('success');
						setMessageText('Quantity changed in cart');
						setOpen(true);
					}
				})
				.catch((e) => {
					setMessageType('error');
					setMessageText('Could not change product quantity in cart');
					setOpen(true);
				});
		}
	}

	function removeFromCart() {
		updateQuantity(0);
	}

	return (
		<TableCell
			sx={{
				flex: 0.14,
				fontWeight: 500,
				borderBottom: 0,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'flex-start',
				borderLeft: '1px solid lightgray',
				'@media screen and (max-width: 1540px)': {
					flex: 0.164,
					fontSize: '12px',
					padding: '2'
				}
			}}
		>
			<TextField
				sx={{
					width: '8ch',
					color: '#314b85',
					'@media screen and (max-width: 1540px)': {
						width: '10ch'
					}
				}}
				size="small"
				type="number"
				id="inputQuantity"
				label="Qty"
				variant="outlined"
				onChange={(e) => updateQuantity(e.target.value)}
				value={quantity ?? 1}
				inputProps={{ min: '1' }}
			/>

			<span className="buttonCart">
				<Button onClick={removeFromCart}>
					<DeleteOutlineSharpIcon
						sx={{
							color: '#314b85'
						}}
					/>
				</Button>
			</span>
		</TableCell>
	);
};

export default ItemsSummaryQuantity;
