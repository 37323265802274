import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import EditOutlined from '@mui/icons-material/EditOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import { Box, Button, CircularProgress, IconButton, Modal, ThemeProvider, Tooltip, createTheme } from '@mui/material';
import axios from 'axios';
import { debounce } from 'lodash';
import MUIDataTable, {
	DisplayData,
	FilterType,
	MUIDataTableCustomHeadRenderer,
	SelectableRows
} from 'mui-datatables';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AdminLayout from '../../../modules/admin/Util/AdminLayout';
import AgentLayout from '../../../modules/agent/Util/AgentLayout';
import colors from '../../../shared/styles/_variables.module.scss';
import {
	dateHeaderTemplate,
	getMuiTheme,
	inputTextHeaderTemplate,
	multipleInputHeaderTemplate,
	noSelectHeaderTemplate,
	rangeHeaderTemplate,
	selectHeaderTemplate
} from '../../components/DataTable/TableUtils';
import {
	bookingStatusOptions,
	documentStatusOptions,
	paymentStatusOptions,
	totalAmountOptions
} from '../../constants/optionsConstants';
import { OrderDetail } from '../../models/orderDetail';
import { User } from '../../models/user';
import CancelBookingConfirmation from './CancelBookingConfirmation';
import './ManageBooking.css';
import EditBooking from './editBooking/EditBooking';
import { setMenuActive } from '../../../redux/features/menuActive';

const ManageBooking = (props: any) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	//get userinfo from session storage
	// we use this instead of redux in order to get userinfo faster
	const userinfo: any = window.sessionStorage.getItem('user');
	const [user, setUser] = useState<User>(JSON.parse(userinfo));
	const currency = useSelector((state: any) => state.currency.value);
	const [clickedOrderId, setClickedOrderId] = useState(-1);
	const [confirmed, setConfirmed] = useState(false);

	const paramInitialState = {
		sortBy: 'ID',
		sortOrder: 'desc',
		status: ['booked', 'inProgress', 'sold', 'closed'],
		totalAmount: [0, 10000]
	};

	type OrderSortingMap = {
		[key: string]: string;
	};

	const orderSortingMap: OrderSortingMap = {
		bookingDate: 'ORDER_DATE',
		bookingStatus: 'BOOKING_STATUS',
		documentStatus: 'DOCUMENT_STATUS',
		orderDate: 'ORDER_DATE',
		orderId: 'ID',
		originCountry: 'COUNTRY',
		paymentStatus: 'PAYMENT_STATUS',
		customerName: 'CUSTOMER_NAME',
		totalAmount: 'TOTAL_AMOUNT'
	};

	/** column definition */
	const columnDefs = [
		{
			label: 'Ref',
			name: 'orderId',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					inputTextHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						param,
						handleChange,
						sorting,
						setSorting,
						orderSortingMap[columnMeta.name]
					)
			}
		},
		{
			label: '',
			name: 'customerFirstName',
			options: {
				display: false
			}
		},
		{
			label: '',
			name: 'customerLastName',
			options: {
				display: false
			}
		},
		{
			label: 'Buyer',
			name: 'customerName',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return tableMeta?.rowData[1] + ' ' + tableMeta?.rowData[2];
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					inputTextHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						param,
						handleChange,
						sorting,
						setSorting,
						orderSortingMap[columnMeta.name]
					)
			}
		},
		{
			label: 'Total Amount',
			name: 'totalAmount',
			options: {
				customBodyRenderLite: (dataIndex: any) => {
					if (user?.role === 'admin') {
						const priceAfterCurrencyChange = manageOrdersData
							.at(dataIndex)
							?.amounts?.find((price: any) => price.currencyType === 'MAIN');
						return (
							(priceAfterCurrencyChange?.currency + ' ' ?? '') +
							priceAfterCurrencyChange?.price
						);
					} else {
						const priceAfterCurrencyChange = manageOrdersData
							.at(dataIndex)
							?.amounts?.find((price: any) => price.currency === currency);
						return (currency + ' ' ?? '') + priceAfterCurrencyChange?.price;
					}
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
				multipleInputHeaderTemplate(
					columnMeta.label || '',
					columnMeta.name,
					param,
					handleChange,
					sorting,
					setSorting,
					orderSortingMap[columnMeta.name]
				)
			}
		},
		{
			label: 'Total Amount',
			name: 'totalAmount',
			options:{
				display: false
			}
			/* options: {
				customBodyRenderLite: (dataIndex: any) => {
					if (user?.role === 'admin') {
						const priceAfterCurrencyChange = manageOrdersData
							.at(dataIndex)
							?.amounts?.find((price: any) => price.currencyType === 'MAIN');
						return (
							(priceAfterCurrencyChange?.currency + ' ' ?? '') +
							priceAfterCurrencyChange?.price
						);
					} else {
						const priceAfterCurrencyChange = manageOrdersData
							.at(dataIndex)
							?.amounts?.find((price: any) => price.currency === currency);
						return (currency + ' ' ?? '') + priceAfterCurrencyChange?.price;
					}
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					rangeHeaderTemplate(columnMeta.label || '', setPriceRange, priceRange)
			} */
		},
		{
			label: 'createDate',
			name: 'createDate',
			options: {
				display: false
			}
		},
		{
			label: 'Order Date',
			name: 'orderDate',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					dateHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						handleDateChange,
						param,
						sorting,
						setSorting,
						orderSortingMap[columnMeta.name]
					),
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return value ?? tableMeta.rowData[3] ?? '';
				}
			}
		},
		{
			label: 'Custom Clearance Status',
			name: 'documentStatus',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					selectHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						documentStatusOptions,
						param,
						handleChange,
						sorting,
						setSorting,
						orderSortingMap[columnMeta.name]
					)
			}
		},
		{
			label: 'Booking Status',
			name: 'bookingStatus',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					selectHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						bookingStatusOptions,
						param,
						handleChange,
						sorting,
						setSorting,
						orderSortingMap[columnMeta.name]
					)
			}
		},
		{
			label: 'Payment Status',
			name: 'paymentStatus',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					selectHeaderTemplate(
						columnMeta.label || '',
						columnMeta.name,
						paymentStatusOptions,
						param,
						handleChange,
						sorting,
						setSorting,
						orderSortingMap[columnMeta.name]
					)
			}
		},
		{
			label: 'Manage',
			name: 'manage',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					let hidden = false;
					if(tableMeta.rowData[9] === 'Picked Up'){
						hidden = true
					}
					return (
						<div>
							<IconButton hidden={hidden}
								sx={{ marginRight: '10px', padding: '0px' }}>
								<Tooltip title="Edit Booking Status">
									<EditOutlined
										onClick={() => onEditRow(tableMeta)}
									/>
								</Tooltip>
							</IconButton>
							<IconButton
								sx={{ marginRight: '10px', padding: '0px' }}>
								<Tooltip title="Manage Containers/Documents">
									<ManageAccountsOutlinedIcon
										onClick={() => manageData(tableMeta)}
									/>
								</Tooltip>
							</IconButton>
							<IconButton hidden={hidden}
								sx={{padding: '0px' }}>
								<Tooltip title="Delete">
									<DeleteOutlineIcon
										onClick={(e) => {
											e.stopPropagation();
											setClickedOrderId(tableMeta?.rowData[0]);
											setConfirmed(true);
										}}
									/>
								</Tooltip>
							</IconButton>
						</div>
					);
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '')
			}
		}
	];

	/** for filtering */
	const [param, setParam] = useState<any>(paramInitialState);
	/** for sorting */
	const [sorting, setSorting] = useState({ sortBy: 'ID', sortOrder: 'desc' });
	/** table data */
	const [manageOrdersData, setManageOrdersData] = useState<OrderDetail[]>([]);
	/** to show/ not show progress bar */
	const [isLoading, setIsLoading] = useState(true);
	/** to open/close pop up */
	const [open, setOpen] = useState<boolean>(false);
	/** how many record per page */
	const [pageSize, setPageSize] = useState<number>(10);
	/** total records returned by api */
	const [totalRecords, setTotalRecords] = useState<number>(0);
	/** current page number */
	const [currentPage, setCurrentPage] = useState(1);
	/** progress circular bar when editing a record and waiting setUserfor success */
	const [progressBar, setProgressBar] = useState(false);
	/** get row data by id */
	const [rowDataById, setRowDataById] = useState<any>();

	if (user?.role === 'admin') {
		dispatch(setMenuActive('three'));
	}
	else{
		dispatch(setMenuActive('four'));
	}

const getMuiTheme = () =>
	createTheme({
		components: {
			MuiPaper: {
				styleOverrides: {
					root: {
						boxShadow: 'none !important',
						backgroundColor: 'transparent',
						cursor: 'default !important',
					},
					elevation: {
						background: '#fafbfc'
					}
				}
			},
			MUIDataTable: {
				styleOverrides: {
					root: {
						borderRadius: '10px',
						margin: '0px 10px',
						cursor: 'default !important',
					}
				}
			},
			MuiInputBase: {
				styleOverrides: {
					root: { width: '109px', height: '35px' }
				}
			},
			MuiButton: {
				styleOverrides: {
					root: { marginRight: '10px' }
				}
			},
			MuiTableRow: {
				styleOverrides: {
					root: {cursor: 'default !important', '&:hover': { cursor: 'default !important', backgroundColor: 'transparent !important' } },
					head: {cursor: 'default !important',  '&:hover': { cursor: 'default !important', backgroundColor: 'transparent !important' } },
					footer: {cursor: 'default !important',  '&:hover': { cursor: 'default !important', backgroundColor: 'transparent !important' } }
				}
			},
			MUIDataTableBodyCell: {
				styleOverrides: {
					stackedCommon: {
						paddingTop: '10px',
						borderBottom: '0',
						height: '45px',
						justifyContent: 'center !important',
						display: 'flex',
						borderLeft: `1px solid ${colors.colorFillPrimaryRedHover}`,
						whiteSpace: 'normal',
						textOverflow: 'ellipsis',
						overflow: 'hidden'
					},
					stackedHeader: {
						display: 'none !important'
					},
					root: {
						textAlign: 'center',
						color: colors.colorFillPrimaryBlue
					}
				}
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						position: 'relative',
						borderBottom: '0',
						padding: '10px 0px !important',
						minWidth: '120px',
						maxWidth: '300px',
						width: '300px',
						verticalAlign: 'bottom'
					}
				}
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						height: '35px',
						width: '80%',
						justifyContent: 'center',
						alignSelf: 'center'
					}
				}
			},
			MUIDataTableToolbar: {
				styleOverrides: {
					root: {
						color: colors.colorFillPrimaryBlue
					}
				}
			},
			MUIDataTableBodyRow: {
				styleOverrides: {
					root: {
						cursor: 'default !important', '&:hover': { cursor: 'default !important', backgroundColor: 'transparent' },
						boxShadow: 'rgb(221, 222, 223) 5px 5px 5px'
					}
				}
			},
			MuiTablePagination: {
				styleOverrides: {
					selectLabel: {
						marginTop: 'auto'
					},
					displayedRows: {
						marginTop: 'auto'
					}
				}
			},
			MuiList: {
				styleOverrides: {
					root: {
						backgroundColor: 'white'
					}
				}
			},
			MUIDataTableSelectCell: {
				styleOverrides: {
					headerCell: {
						backgroundColor: 'transparent'
					},
					root: {
						width: '50px !important',
						maxWidth: '50px !important'
					}
				}
			},
			MuiSvgIcon: {
				styleOverrides: {
					root: {
						':hover': {
							cursor: 'pointer'
						},
						color: '#04246a'
					}
				}
			}
		}
	});

	const handleDateChange = (newValue: any) => {
		const filterTemp = {
			...param,
			orderDate: newValue
		};
		setParam(filterTemp);
	};

	/** get data for table with pagination */
	const getData = (currentPageInner?: number) => {
		let tempParam = param;
		if (user?.role !== 'admin') {
			tempParam = {
				...param,
				originCountry: user?.location.split(',')[0]
			};
		}
		let headers = undefined;
		const minValue = ((currentPageInner ?? currentPage) - 1) * pageSize;
		const maxValue = minValue + pageSize - 1;
		headers = { range: `${minValue}-${maxValue}` };
		setIsLoading(true);
		axios
			.get('/api/container/orders', {
				params: tempParam,
				headers,
				paramsSerializer: {
					serialize: (params) => {
						return qs.stringify(params, { arrayFormat: 'comma', encode: false });
					}
				}
			})
			.then((response) => {
				const rangeVal = response?.headers['content-range'];
				const rangeSplit = rangeVal.toString().split('/');
				const totalElements = parseInt(rangeSplit[1]);
				setTotalRecords(totalElements);
				setPageSize(10);
				setManageOrdersData(response.data);
				// Check if filtered data is empty
				const newData = response.data;
				if (newData.length === 0) {
					setManageOrdersData([]);
					setIsLoading(false);
					setTotalRecords(0);
				}
			})
			.catch(() => {
				setManageOrdersData([]);
				setIsLoading(false);
				setTotalRecords(0);
			});
	};

	// Debounce the API call with a delay of 200 milliseconds
	const debouncedGetData = debounce(getData, 200);
	useEffect(() => {
		debouncedGetData();
	}, [param]);
	
	useEffect(() => {
		if (param.sortBy !== sorting.sortBy || param.sortOrder !== sorting.sortOrder)
		setCurrentPage(1);
			setParam({
				...param,
				sortBy: sorting.sortBy,
				sortOrder: sorting.sortOrder
			});
	}, [sorting]);

	/** mui datatable options */
	const options = {
		selectableRows: 'none' as SelectableRows,
		textLabels: {
			body: {
				noMatch: isLoading ? <CircularProgress /> : 'No orders match your search'
			},
			pagination: {
				next: 'Next >',
				previous: '< Previous',
				rowsPerPage: 'Total items per page',
				displayRows: 'of'
			}
		},
		page: currentPage - 1,
		rowsPerPageOptions: [],
		count: totalRecords,
		serverSide: true,
		onChangePage(currentPage: number) {
			setCurrentPage(currentPage + 1);
			setIsLoading(true);
			getData(currentPage + 1);
		},
		customToolbar: () => {
			return (
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
					<Button onClick={clearFilters} sx={{ color: colors.colorFillPrimaryBlue }}>
						Clear Filters
					</Button>
				</div>
			);
		},
		customToolbarSelect: (
			selectedRows: {
				data: Array<{ index: number; dataIndex: number }>;
				lookup: { [key: number]: boolean };
			},
			displayData: DisplayData,
			setSelectedRows: (rows: number[]) => void
		) => {
			return (
				<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
					<Button onClick={clearFilters} sx={{ color: colors.colorFillPrimaryBlue }}>
						Clear Filters
					</Button>
				</div>
			);
		},
		filterType: 'dropdown' as FilterType,
		viewColumns: false,
		print: false,
		download: false,
		search: false,
		filter: false
	};

	/** this is called on filtering */
	const handleChange = (event: any) => {

		if (event.target.name === 'status' && event.target.value.length === 0) return;

		setCurrentPage(1);
		const filterTemp = { ...param, [event.target.name]: event.target.value };

		setParam(filterTemp);
	};

	/** clear all filters */
	const clearFilters = () => {
		setCurrentPage(1);
		if (user?.role !== 'admin') {
			setParam(() => {
				return {
					...paramInitialState,
					originCountry: user?.location.split(',')[0]
				};
			});
		} else {
			setParam(paramInitialState);
		}
		getData();
	};

	/** when click on edit button get the row data by id to be displayed in edit popup */
	const onEditRow = (rowMetaData: any) => {
		setOpen(true);
		const order = manageOrdersData[rowMetaData.rowIndex] ?? null;

		setRowDataById(order);
	};

	/** on manage data */
	const manageData = (rowMetaData: any) => {
		const order = manageOrdersData[rowMetaData.rowIndex] ?? null;
		if (order !== null && order.orderId !== null) {
			navigate(`/booking/ManageOrder/${order.orderId}`, {
				state: user, 
			});
		}
	};

	const manageBookingTemplate: JSX.Element = (
		<>
			{open && (
				<EditBooking
					open={open}
					setOpen={setOpen}
					rowDataById={rowDataById}
					setRowDataById={setRowDataById}
					setManageOrdersData={setManageOrdersData}
					setIsLoading={setIsLoading}
					param={sorting}
				/>
			)}
			{progressBar && (
				<div>
					<Modal
						className="modal-popup-progress-bar"
						open={progressBar}
						onClose={() => setProgressBar(false)}
					>
						<Box>
							<CircularProgress />
						</Box>
					</Modal>
				</div>
			)}
			<div className="manageBooking">
				{/* Title */}
				<div className="manageBooking-top">
					<p>Manage Orders</p>
				</div>
				{/* Table */}
				<div>
					<ThemeProvider theme={getMuiTheme()}>
						<MUIDataTable
							title=""
							data={manageOrdersData ? manageOrdersData : []}
							columns={columnDefs}
							options={options}
						/>
					</ThemeProvider>
				</div>
				<CancelBookingConfirmation
					confirmed={confirmed}
					setConfirmed={setConfirmed}
					clickedOrderId={clickedOrderId}
					agentUid={user?.ccgId}
					getData={getData}
				/>
			</div>
		</>
	);

	return (
		<>
			{user?.role === 'agent' && 
			<AgentLayout>
				{manageBookingTemplate} 
			</AgentLayout>}
			{user?.role === 'admin' && <AdminLayout>{manageBookingTemplate}</AdminLayout>}
		</>
	);
};

export default ManageBooking;
