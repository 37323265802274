import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export interface Sizes {
	size: Number
}

export interface SizesState {
	sizes: Sizes[];
    isLoading: boolean;
    error: string;
}

const initialState: SizesState = {
	sizes: [] as Sizes[],
	isLoading: false,
	error: '',
};



export const fetchSizes = createAsyncThunk(
	'sizes/fetchSizes',
	async () => {
		const response = await axios.get(`/api/container/referentials/sizes`);
		const { data } = response;
		return data;
	}
);

export const sizesSlice = createSlice({
	name: 'sizes',
	initialState,
	reducers: {
	},
	extraReducers: (builder) => {
		builder.addCase(fetchSizes.pending, (state) => {
			return {
				...state,
				error: '',
				isLoading: true
			};
		});
		builder.addCase(fetchSizes.fulfilled, (state, action) => {
			return {
				...state,
				isLoading: false,
				sizes: action.payload,
			};
		});
		builder.addCase(fetchSizes.rejected, (state, action) => {
			return {
				...state,
				isLoading: false,
				error: action.error.message !== undefined ? action.error.message : 'Error'
			};
		});
	}

});

export default sizesSlice.reducer;
