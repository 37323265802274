import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import BuyerLayout from '../BuyerDashboard/BuyerLayout';
import './Thankyou.css';

const Thankyou = () => {
	const navigate = useNavigate();
	return (
		<BuyerLayout>
			<div className="thankyou">
				{/* Top */}
				<div className="thankyou-top">
					{/* Image */}
					<div className="thankyou-top-image">
						<ThumbUpAltIcon className="thankyou-top-icon" />
					</div>
				</div>

				{/* Bottom */}
				<div className="thankyou-bottom">
					{/* Row */}
					<div className="thankyou-bottom-title">
						<p>Thank you !</p>
					</div>

					{/* Row */}
					<div className="thankyou-bottom-message">
						<CheckCircleIcon className="thankyou-checkIcon" />
						<p>Order Done Successfully</p>
					</div>

					{/* Row */}
					<div className="thankyou-bottom-buttons">
						{/* Button */}
						<div>
							<Button
								variant="contained"
								startIcon={<HomeOutlinedIcon  />}
								onClick={() => navigate('/')}
								className="thankyou-button"
							>
								Home
							</Button>
						</div>

						{/* Button */}
						<div>
							<Button
								variant="contained"
								startIcon={<ListAltOutlinedIcon />}
								onClick={() => navigate('/myorders')}
								className="thankyou-button"
							>
								Orders
							</Button>
						</div>
					</div>
				</div>
			</div>
		</BuyerLayout>
	);
};

export default Thankyou;
