import { faSignIn, faSignOut } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MenuIcon from '@mui/icons-material/Menu';
import { Badge, IconButton, MenuItem, ThemeProvider, createTheme } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import ShoppingCart from '../../../modules/buyer/OrderManagement/ShoppingCart';
import { setCurrency } from '../../../redux/features/currency';
import { CartItem } from '../../models/cart';
import './Nav.scss';
import axios from 'axios';

const pages = [
	{ label: 'Home', value: '/' },
	{ label: 'Products', value: '/listProducts' },
	/* { label: 'How It Works', value: '/howitworks' },
	{ label: 'Container Type', value: '/containertypes' },
	{ label: 'Container Uses', value: '/containeruses' },
	{ label: 'Contact us', value: '/contactus' } */
];

const activeStyle = {
	textDecoration: 'none',
	color: '#e20101',
	borderBottom: '2px solid #e20101',
	fontSize: '16px',
	fontFamily: 'Antonio-Bold !important'
};

const inactiveStyle = {
	textDecoration: 'none',
	color: '#04246a',
	fontSize: '16px',
	fontFamily: 'Antonio-Bold !important'
};

function logout() {
	(async () => {
		try {
			window.sessionStorage.removeItem('user');
			window.location.replace(
				`${process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : ''}/auth/sso/logout`
			);
		} catch (e) {
			console.log('Could not log out');
		}
	})();
}

function Nav() {
	//get userinfo from session storage
	// we use this instead of redux in order to get userinfo faster
	let user: any = window.sessionStorage.getItem('user');
	user = JSON.parse(user);
	const [clicked, setClicked] = useState(false);
	const [cartItems, setCartItems] = useState<CartItem[]>([]);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const currency = useSelector((state: any) => state.currency.value);

	const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
	const rates = ['USD', 'EUR', 'GBP'];

	const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElNav(event.currentTarget);
	};

	const handleLogo = () => {
		navigate('/');
	};

	const menuList = pages.map(({ label, value }, index) => {
		return (
			<li key={index}>
				<NavLink to={value} style={({ isActive }) => (isActive ? activeStyle : {})}>
					{label ? label : ''}
				</NavLink>
			</li>
		);
	});

	const [anchorEl, setAnchorEl] = React.useState(null);
	const handleClick = (e: any) => {
		setAnchorEl(e.currentTarget);
	};

	useEffect(() => {
		if (currency === null) {
			(async () => {
				try {
					const { data } = await axios.get('/api/getUserInfo');
					if (data) {
						dispatch(setCurrency(user.defaultCurrency));
					} else {
						dispatch(setCurrency('USD'));
					}
				} catch (e) {
					console.log(e);
				}
			})();
		}
	}, []);

	const handleClose = (e: any) => {
		const curr = e.target.textContent === '' ? currency : e.target.textContent;
		dispatch(setCurrency(curr));
		setCurrency(curr);
		setAnchorEl(null);
	};

	const ssoRedirect = () => {
		const url = window.location.href;
		const redirectSsoURL = url.split('/')[0].concat('/auth/sso/login');

		window.open(redirectSsoURL, '_self');
	};

	const getMuiTheme = () =>
		createTheme({
			components: {
				MuiAvatar: {
					styleOverrides: {
						root: {
							border: '1px solid #04246a',
							backgroundColor: 'white',
							color: '#04246a'
						}
					}
				}
			}
		});

	return (
		<ThemeProvider theme={getMuiTheme()}>
			<AppBar position="fixed" sx={{ background: '#fafbfc' }}>
				<Toolbar
					disableGutters
					sx={{
						display: 'flex',
						justifyContent: 'flex-start',
						px: '10px'
					}}
				>
					<img
						alt="CMA Logo"
						src="/media/CMA_CGM_logo.svg"
						width="80px"
						onClick={handleLogo}
						className="header-image"
					/>

					<Box
						sx={{
							flexGrow: 1,
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'flex-end'
						}}
					>
						<IconButton
							onClick={handleOpenNavMenu}
							color="inherit"
							sx={{
								flexGrow: 1,
								display: { xs: 'flex', md: 'none' },
								maxWidth: 'fit-content',
								color: '#04246a'
							}}
						>
							<MenuIcon />
						</IconButton>
						<Menu
							id="menu-appbar"
							anchorEl={anchorElNav}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'left'
							}}
							keepMounted
							transformOrigin={{
								vertical: 'top',
								horizontal: 'left'
							}}
							open={Boolean(anchorElNav)}
							onClose={() => setAnchorElNav(null)}
							sx={{
								display: { xs: 'block', md: 'none' },
								minHeight: '700px'
							}}
						>
							<div className="header-list">
								<ul className={clicked ? 'menu-list' : 'menu-list close'}>
									{menuList}
								</ul>
							</div>
						</Menu>
					</Box>
					<Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
						<div className="header-list">
							<ul className="menu-list">{menuList}</ul>
						</div>
					</Box>

					<Box className="myOrders">
						{location.pathname.toLowerCase() !== '/myorders' &&
							(user?.role === 'buyer' || user?.role === 'agent') && (
								<>
									<IconButton
										aria-controls="simple-menu"
										aria-haspopup="true"
										aria-label="Open to show more"
										title="Open to show more"
										onClick={handleClick}
										disabled={user?.role === 'agent'}
										sx={{
											fontSize: '16px',
											paddingTop: '10px'
										}}
									>
										{currency ? currency : user.defaultCurrency}
									</IconButton>
									<Menu
										id="menu-appbar"
										anchorEl={anchorEl}
										keepMounted
										open={Boolean(anchorEl)}
										onClose={handleClose}
										sx={{
											maxWidth: '100px'
										}}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'left'
										}}
										transformOrigin={{
											vertical: 'top',
											horizontal: 'right'
										}}
									>
										{rates?.map((item: string, index: number) => (
											<MenuItem
												onClick={handleClose}
												key={index}
												value={item}
											>
												{item}
											</MenuItem>
										))}
									</Menu>
								</>
							)}
					</Box>
					{/* <ShoppingCart /> */}
					{user?.role === 'buyer' && (
						<div className="myOrders">
							<NavLink
								to={'/MyOrders'}
								style={({ isActive }) => (isActive ? activeStyle : inactiveStyle)}
							>
								My Orders
							</NavLink>
							<Badge badgeContent={cartItems?.length} color="primary">
								<ShoppingCart
									user={user}
									cartItems={cartItems}
									setCartItems={setCartItems}
								/>
							</Badge>
						</div>
					)}
					{user ? (
						<Button
							onClick={() => {
								logout();
							}}
							sx={{
								color: '#04246a',
								padding: '10px 0px'
							}}
						>
							<FontAwesomeIcon icon={faSignOut} className="nav-icon" />
						</Button>
					) : (
						<div>
							<Button onClick={() => {
								window.location.replace(`${process.env.NODE_ENV === 'development' ? 'http://localhost:3001' : ''}/auth/sso/login`)
							}}>
								<FontAwesomeIcon
									size="lg"
									icon={faSignIn}
									className="nav-icon"
									style={{ color: '#04246a' }}
								/>
							</Button>
						</div>
					)}
				</Toolbar>
			</AppBar>
		</ThemeProvider>
	);
}
export default Nav;
