import { Button, Grid, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { MultiSelect } from 'react-multi-select-component';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setMenuActive } from '../../../redux/features/menuActive';
import AdminLayout from '../Util/AdminLayout';
import { Country, Depots } from '../Util/params';
import './AdminDepotsUser.css';

const AssignDepotsUser = () => {
	const navigate = useNavigate();
	const [Countries, SetCountries] = useState([]);
	const [DepotsData, SetDepots] = useState([]);
	const [lstDepots, SetListDepots] = useState(Array<any>());
	const [savedDepotsData, SetsDepots] = useState(Array<any>());
	const [countryNames, SetCountryNames] = useState(Array<any>());
	const [selected, setSelected] = useState(Array<any>());
	const [isEdit, setisEdit] = useState(Array<any>());

	const [isLoading, setIsLoading] = useState(true);
	const [isEmpty, setIsEmpty] = useState(Array<boolean>(false));

	const currenturl = window.location.href;
	let UrlArray: Array<string> = [];
	UrlArray = currenturl.split('/');
	const ccgid = UrlArray[UrlArray.length - 1];

	const [param, setParam] = useState({
		filter: {
			agentUid: ccgid
		}
	});

	const depots: Array<Array<Depots>> = [];
	const lstdepotscode: Array<Array<any>> = [];
	const Selecteddepots: Array<Array<any>> = [];

	useEffect(() => {
		const fetchCountryData = async () => {
			const { data } = await axios.get('/api/container/locations/countries', {});
			if (data != undefined) SetCountries(data);
		};

		const fetchDepotsData = async () => {
			const { data } = await axios.get('/api/container/locations');

			Countries.map((country: Country) => {
				countryNames.push(country.isoCountryName);
			});
			//SetDepots(data);
			await data.map(async (depotinfo: Depots, i: number) => {
				const countryname: string = depotinfo.country;
				const index = countryNames.indexOf(countryname);
				if (depots[index] !== undefined) {
					lstdepotscode[index].push({
						label: depotinfo.depot,
						value: depotinfo.depot
					});
					depots[index].push(depotinfo);
				} else {
					depots[index] = Array<any>();
					lstdepotscode[index] = [];
					depots[index][0] = depotinfo;
					lstdepotscode[index][0] = {
						label: depotinfo.depot,
						value: depotinfo.depot
					};
				}
			});

			SetListDepots(lstdepotscode);
		};

		const fetchSavedDepotsData = async () => {
			const filters = param.filter;
			//filters
			const querystring = new URLSearchParams(param.filter).toString();
			const { data } = await axios.get('/api/container/locations/' + ccgid);
			setSelected(data.data);
		};

		fetchCountryData().catch(console.error);
		if (lstDepots.length == 0) {
			fetchDepotsData().catch(console.error);
		}

		fetchSavedDepotsData().catch(console.error);

		if (lstDepots.length > 0) {
			setIsLoading(false);
		}
	}, [lstDepots]);

	const dispatch = useDispatch();
	dispatch(setMenuActive('two'));

	return (
		<>
			<AdminLayout>
				<div className="adminDepots">
					<p className="adminDepots-title">Admin Assign Depots</p>
					<Table className="adminDepots-table">
						<TableHead className="adminDepots-head">
							<TableRow className="adminDepots-head-row">
								<TableCell className="adminDepots-head-row-item">Country</TableCell>
								<TableCell className="adminDepots-head-row-item">
									Depots Assigned
								</TableCell>
								<TableCell className="adminDepots-head-row-item">Actions</TableCell>
							</TableRow>
						</TableHead>
						<TableBody className="adminDepots-body">
							{Countries.map((country: Country, index: number) => {
								let displaydepots = '';
								if (selected[index] && selected[index][0]) {
									displaydepots = selected[index][0].label;

									if (selected[index][1])
										displaydepots += ',' + selected[index][1].label;
									if (selected[index][2])
										displaydepots += ',' + selected[index][2].label;
									if (selected[index][3])
										displaydepots += ',' + selected[index][3].label;
									if (selected[index].length > 3)
										displaydepots += '...(' + selected[index].length + ')';
								}

								return (
									<TableRow key={index} className="adminDepots-body-row">
										<TableCell className="adminDepots-body-row-item">
											{country.isoCountryName}
										</TableCell>
										<TableCell className="adminDepots-body-row-item">
											{isLoading && <div>Loading...</div>}
											{!isLoading &&
											isEdit[index] &&
											isEdit[index] === true ? (
												<>
													<MultiSelect
														key={index}
														options={
															lstDepots[index] ? lstDepots[index] : []
														}
														value={
															selected[index] != undefined
																? selected[index]
																: []
														}
														closeOnChangedValue={false}
														className="SelectDepots adminDepots-body-row-select"
														onChange={(values: never) => {
															const selectedvalue = [...selected];
															selectedvalue[index] = values;
															setSelected(selectedvalue);
														}}
														labelledBy="Select Depots"
													/>
												</>
											) : selected.length > 0 ? (
												selected[index] == null ? (
													!isLoading && <div>No depots...</div>
												) : (
													<>{!isLoading && displaydepots}</>
												)
											) : !isLoading && displaydepots.length == 0 ? (
												<div>No depots...</div>
											) : (
												<></>
											)}
										</TableCell>
										<TableCell className="adminDepots-body-row-item">
											<a
												style={{ cursor: 'pointer' }}
												onClick={() => {
													let isedittmp = [];
													isedittmp = [...isEdit];
													isedittmp[index] = true;
													setisEdit(isedittmp);
												}}
											>
												<img src="/media/edit.svg" className="editBtn" />
											</a>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
					<Grid container spacing={2} padding={0} className="adminDepots-grid">
						<Grid item md={8}></Grid>
						<Grid item md={4} className="adminDepots-grid-item">
							<Button
								variant="contained"
								onClick={async () => {
									const selecteddepots = Array<object>();
									selected &&
										selected.map((countrydepots: Array<object>, i: number) => {
											countrydepots &&
												countrydepots.map((depotcode: any, i: number) => {
													selecteddepots.push({
														depotCode: depotcode.label
													});
												});
										});
									// send request to save depots
									await axios
										.put('/api/container/locations/agent/' + ccgid, selecteddepots)
										.then(result => {
											navigate('/admin/manageusers');
										})
										.catch(error => {
											console.log('save depots request :' + error);
										});
								}}
								className="adminDepots-grid-button"
							>
								Save Depots
							</Button>
						</Grid>
					</Grid>
				</div>
			</AdminLayout>
		</>
	);
};

export default AssignDepotsUser;
