import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from '@mui/material';

const FileConfirmationDialog = (props: any) => {
	const { openDialog, setOpenDialog, handleAction, currentDocumentName, action } = props;

	const handleClose = () => {
		setOpenDialog(false);
	};

	if (action === 'validate'){
		return (
			<div>
				<Dialog
					open={openDialog}
					onClose={handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						Are you sure you want to validate this file?
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{currentDocumentName} will be {action}d
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose}>Cancel</Button>
						<Button onClick={handleAction} autoFocus>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
	else{
		return (
			<div>
				<Dialog
					open={openDialog}
					onClose={handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle id="alert-dialog-title">
						Are you sure you want to ask for Re-upload?
					</DialogTitle>
					<DialogContent>
						<DialogContentText id="alert-dialog-description">
							{currentDocumentName} will be deleted!
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={handleClose}>Cancel</Button>
						<Button onClick={handleAction} autoFocus>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	}
};

export default FileConfirmationDialog;
