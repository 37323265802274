import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setMenuActive } from '../../../redux/features/menuActive';

import './Menu.css';

const Menu = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const menuId = useSelector((state: any) => state.menuActive.value['menuId']);
	//get userinfo from session storage
	// we use this instead of redux in order to get userinfo faster
	let userinfo: any = window.sessionStorage.getItem('user');
	userinfo = JSON.parse(userinfo);

	const goToDashboard = () => {
		if (userinfo.role !== undefined && userinfo.role !== null && userinfo.role === 'agent') {
			navigate('/agent');
		} else {
			navigate('/admin');
		}
		dispatch(setMenuActive('one'));
	};

	const gotToManageUsers = () => {
		navigate('/admin/manageusers');
		dispatch(setMenuActive('two'));
	};

	const gotToManageOrders = () => {
		navigate('/booking/manageorders');
		dispatch(setMenuActive('three'));
	};

	const goToSalesHistory = () => {
		navigate('/booking/salesHistory');
		dispatch(setMenuActive('four'));
	};

	const goToCompanies = () => {
		navigate('/booking/listCompanies');
		dispatch(setMenuActive('five'));
	};

	const goToVatRules = () => {
		navigate('/booking/vatRules');
		dispatch(setMenuActive('six'));
	};

	const goToCurrencyRules = () => {
		navigate('/booking/currencyRules');
		dispatch(setMenuActive('seven'));
	};

	const goToPriceLists = () => {
		navigate('/booking/priceLists');
		dispatch(setMenuActive('eight'));
	};

	const goToDocumentTypes = () => {
		navigate('/booking/documentTypes');
		dispatch(setMenuActive('nine'));
	};

	return (
		<nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
			<div className="position-sticky menu">
				{/* Dashboard */}
				<ListItemButton
					onClick={goToDashboard}
					className={menuId === 'one' ? 'list-active' : 'inactive'}
					style={{ color: 'white !important' }}
					key="dashboard"
				>
					<ListItemText primary="Dashboard" className="menu-links-agent" />
				</ListItemButton>
				<ListItemButton
					onClick={gotToManageUsers}
					className={menuId === 'two' ? 'list-active' : 'inactive'}
					key="manage-users"
				>
					<ListItemText primary="List of Agents" className="menu-links-agent" />
				</ListItemButton>
				<ListItemButton
					onClick={gotToManageOrders}
					className={menuId === 'three' ? 'list-active' : 'inactive'}
					key="manage-orders"
				>
					<ListItemText primary="Manage Orders" className="menu-links-agent" />
				</ListItemButton>
				<ListItemButton
					onClick={goToSalesHistory}
					className={menuId === 'four' ? 'list-active' : 'inactive'}
					key="sales-history"
				>
					<ListItemText primary="Sales History" className="menu-links-agent" />
				</ListItemButton>
				<ListItemButton
					onClick={goToCompanies}
					className={menuId === 'five' ? 'list-active' : 'inactive'}
					key="companies"
				>
					<ListItemText primary="Companies" className="menu-links-agent" />
				</ListItemButton>
				<ListItemButton
					onClick={goToVatRules}
					className={menuId === 'six' ? 'list-active' : 'inactive'}
					key="vat-rules"
				>
					<ListItemText primary="VAT Rules" className="menu-links-agent" />
				</ListItemButton>
				<ListItemButton
					onClick={goToCurrencyRules}
					className={menuId === 'seven' ? 'list-active' : 'inactive'}
					key="currency-rules"
				>
					<ListItemText primary="Currency Rules" className="menu-links-agent" />
				</ListItemButton>
				<ListItemButton
					onClick={goToPriceLists}
					className={menuId === 'eight' ? 'list-active' : 'inactive'}
					key="price-list"
				>
					<ListItemText primary="Price List" className="menu-links-agent" />
				</ListItemButton>
				<ListItemButton
					onClick={goToDocumentTypes}
					className={menuId === 'nine' ? 'list-active' : 'inactive'}
					key="document-types"
				>
					<ListItemText primary="Document Types" className="menu-links-agent" />
				</ListItemButton>
			</div>
		</nav>
	);
};

export default Menu;
