import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

export interface Conditions {
	id: Number,
	condition: string
}

export interface ConditionsState {
	conditions: Conditions[];
    isLoading: boolean;
    error: string;
}

const initialState: ConditionsState = {
	conditions: [] as Conditions[],
	isLoading: false,
	error: '',
};


export const fetchConditions = createAsyncThunk(
	'conditions/fetchConditions',
	async () => {
		const response = await axios.get(`/api/container/referentials/conditions`);
		const { data } = response;
		return data;
	}
);

export const conditionsSlice = createSlice({
	name: 'conditions',
	initialState,
	reducers: {
	},
	extraReducers: (builder) => {
		builder.addCase(fetchConditions.pending, (state) => {
			return {
				...state,
				error: '',
				isLoading: true
			};
		});
		builder.addCase(fetchConditions.fulfilled, (state, action) => {
			return {
				...state,
				isLoading: false,
				conditions: action.payload,
			};
		});
		builder.addCase(fetchConditions.rejected, (state, action) => {
			return {
				...state,
				isLoading: false,
				error: action.error.message !== undefined ? action.error.message : 'Error'
			};
		});
	}

});
export default conditionsSlice.reducer;
