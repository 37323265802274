import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import Button from '@mui/material/Button';
import { useSelector } from 'react-redux';
import { CartItem } from '../../../shared/models/cart';
import { OrderDetail } from '../../../shared/models/orderDetail';
import './OrderSummary.css';
import { useEffect, useState } from 'react';
import axios from 'axios';

const OrderSummary = (props: {
	cartItems: CartItem[];
	orderDetail: OrderDetail[];
	setConfirmed: any;
	setSelected: any;
}) => {
	const { cartItems, orderDetail, setConfirmed, setSelected } = props;
	const currency = useSelector((state: any) => state.currency.value);

	return (
		<div className="order">
			{/* Row */}
			<div className="order-row">
				<p>Order Summary</p>
			</div>

			{/* Row */}
			<div className="order-row">
				<div className="OrderInfo">
					{cartItems.map((p: CartItem, i: number) => {
						return (
							<div className="order-row-item">
								{/* Item left */}
								<div className="order-rowLeft">
									<p>
										{p.productCategory ?? ''}, {p.productSize}"{' '}
										{p.productType}, {p.productCondition ?? ''}
									</p>
									<p>
										{p.productCity ?? ''}
										,&nbsp;
										{p.productCountry ?? ''}
									</p>

									<p className="order-row-item-test">{p.productDepotName}</p>
								</div>

								{/* Item middle */}
								<div className="order-rowMiddle">
									<p>{p.quantity}</p>
								</div>

								{/* Item right */}
								<div className="order-rowRight">
									<p>
										{currency + ' '}
										{Math.round(
											p?.totals?.find(
												(amount: { currency: any }) =>
													amount.currency === currency
											)?.price ?? 0
										)}
									</p>
								</div>
							</div>
						);
					})}
				</div>
			</div>

			{/* Row */}
			<div className="order-row">
				{/* Left */}
				<div className="order-rowLeft">
					<p>VAT</p>
				</div>

				{/* Middle */}
				<div className="order-rowMiddle">
					<p>
					{cartItems !== undefined
						? '% ' +
						cartItems[0].vatPercentage
						: ''}
					</p>
				</div>

				{/* Right */}
				<div className="order-rowRight">
					<p>
						{orderDetail.length > 0
							? currency +
							  ' ' +
							  Math.round(
									orderDetail[0]?.taxAmounts?.find(
										(amount: any) => amount.currency === currency
									)?.price ?? 0
							  )
							: ''}
					</p>
				</div>
			</div>

			{/* Row */}
			<div className="order-row">
				{/* Left */}
				<div className="order-rowLeft">
					<p>Total Cost</p>
				</div>

				{/* Right */}
				<div className="order-rowRight">
					<p>
						{orderDetail && orderDetail[0]?.amounts && orderDetail[0]?.taxAmounts
							? currency +
							  ' ' +
							  (Math.round(
									orderDetail[0].taxAmounts.find(
										(amount: any) => amount.currency === currency
									)?.price || 0
							  ) +
									Math.round(
										orderDetail[0].amounts.find(
											(amount: any) => amount.currency === currency
										)?.price || 0
									))
							: ''}
					</p>
				</div>
			</div>

			{/* Row */}
			<div className="order-row">
				<Button
					variant="contained"
					endIcon={<ShoppingCartOutlinedIcon />}
					onClick={() => {
						setConfirmed(true);
						setSelected(1);
					}}
					className="order-row-button"
				>
					Checkout
				</Button>
			</div>
		</div>
	);
};

export default OrderSummary;
