import MuiAlert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React from 'react';

const Alert = React.forwardRef<HTMLDivElement, any>(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function AlertCmpt(props: any) {
	const { open, setOpen, messageType, messageText } = props;

	const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	return (
		<div>
			{open && (
				<Snackbar
					anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
					open={open}
					autoHideDuration={3000}
					onClose={handleClose}
					sx={{
						width: '35%',
						marginTop: '80px'
					}}
				>
					<Alert onClose={handleClose} severity={messageType} sx={{ width: '100%' }}>
						{messageText}
					</Alert>
				</Snackbar>
			)}
		</div>
	);
}

export default AlertCmpt;
