/**
 * CSP - Containers API
 * CMA CGM API - Endpoints needed to retrieve update and delete containers
 *
 * OpenAPI spec version: 1.0.0
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export class CartItem {
	/**
	 * Order ID
	 */
	orderId?: number;
	/**
	 * Order_details ID
	 */
	orderDetailsId?: number;
	/**
	 * Price of the product
	 */
	prices?: [
		{
			currency: string;
			price: number;
			currencyType: string;
		}
	];
	/**
	 * Quantity if the product to buy
	 */
	quantity?: number;
	/**
	 * Total price
	 */
	totals?: [
		{
			currency: string;
			price: number;
			currencyType: string;
		}
	];
	/**
	 * Product ID
	 */
	productId?: number;
	/**
	 * Size of the product
	 */
	productSize?: number;
	/**
	 * Type to filter
	 */
	productType?: string;
	/**
	 * Category of the product
	 */
	productCategory?: string;
	/**
	 * Condition of the product
	 */
	productCondition?: string;
	/**
	 * Depot of the product
	 */
	productDepotName?: string;
	/**
	 * Depot of the product
	 */
	productDepotAddress?: string;
	/**
	 * Pool Full Name of the product
	 */
	productFullPoolName?: string;
	/**
	 * City of the product
	 */
	productCity?: string;
	/**
	 * Country of the product
	 */
	productCountry?: string;
	/**
	 * Number of the product in stock
	 */
	inStock?: number;
	/**
	 * price of the product
	 */
	productPrices?: [
		{
			currency: string;
			price: number;
			currencyType: string;
		}
	];
	/**
	 * starting at price
	 */
	startingAtPrice?: number;
	/**
	 * currency of the product
	 */
	productCurrency?: string;
	/**
	 * Assigned agent UID
	 */
	assignedAgentUid?: string;
	vatPercentage?: string;
	
	defaultImage?:
	{
		id: number;
		name: string;
		s3ImageName: string;
	};
}
