import BentoOutlinedIcon from '@mui/icons-material/BentoOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import TaskOutlinedIcon from '@mui/icons-material/TaskOutlined';
import { Button, CircularProgress, ThemeProvider, createTheme } from '@mui/material';
import axios from 'axios';
import { ArcElement, Chart as ChartJS, Tooltip } from 'chart.js';
import MUIDataTable, { FilterType, SelectableRows } from 'mui-datatables';
import qs from 'qs';
import { useEffect, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { countryList } from '../../../shared/constants/countryList';
import colors from '../../../shared/styles/_variables.module.scss';
import AgentLayout from '../Util/AgentLayout';

import './AgentDashboard.css';
import { setMenuActive } from '../../../redux/features/menuActive';

ChartJS.register(ArcElement, Tooltip);

const AgentDashboard = () => {
	const [ACContainers, setACContainers] = useState<any>();
	const [IsContainers, setIsContainers] = useState<any>();
	const [soldContainers, setSoldContainers] = useState<any>();
	const [orders, setOrders] = useState<any>();
	const [bookings, setBookings] = useState<any>();
	/** to show/ not show progress bar */
	const [isLoading, setIsLoading] = useState(true);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	dispatch(setMenuActive('one'));
	
	let userInfo: any = window.sessionStorage.getItem('user');
	userInfo = JSON.parse(userInfo);
	const loggedInUser = useSelector((state: any) => state.loggedInUser.value);

	const [agentCountry, setAgentCountry] = useState<{ label: string; value: string }>({
		label: '',
		value: ''
	});

	const [aparams, setAparams] = useState({
		country: agentCountry.value ?? '',
		status: 'awaiting confirmation'
	});

	const [isparams, setIsParams] = useState({
		country: agentCountry.label ?? '',
		status: 'available'
	});

	const [sparams, setsParams] = useState({
		country: agentCountry.label ?? '',
		status: 'sold'
	});

	const [ordersparams, setOrdersParams] = useState({
		originCountry: agentCountry.label ?? '',
		status: ['booked', 'inProgress'],
		sortBy: 'orderId', sortOrder: 'desc'
	});

	const [soldBySizeKeysContainers, setSoldBySizeKeysContainers] = useState<String[]>();
	const [soldBySizeValuesContainers, setSoldBySizeValuesContainers] = useState<any[]>();

	const [soldByCategoryKeysContainers, setSoldByCategoryKeysContainers] = useState<String[]>();
	const [soldByCategoryValuesContainers, setSoldByCategoryValuesContainers] = useState<any[]>();
	/** how many record per page */
	const [pageSize, setPageSize] = useState<number>(5);
	const [errorMessage, setErrorMessage] = useState<string>('');

	

	useEffect(() => {
		if (userInfo === undefined || userInfo === null) {
			if (loggedInUser !== undefined || loggedInUser !== null) {
				const userLocation = loggedInUser.location?.split(',')[0].toLowerCase();

				const userLocCode = countryList.find(
					(country: any) => country.label.toLowerCase() === userLocation
				) ?? { label: '', value: '' };

				setAgentCountry(userLocCode);
			}
		} else {
			const userLocation = userInfo.location?.split(',')[0].toLowerCase();

			const userLocCode = countryList.find(
				(country: any) => country.label.toLowerCase() === userLocation
			) ?? { label: '', value: '' };

			setAgentCountry(userLocCode);
		}
	}, [userInfo]);

	useEffect(() => {
		if (agentCountry === undefined || agentCountry === null || agentCountry.label === '')
			return;
		setOrdersParams((prevOrders) => {
			return { ...prevOrders, originCountry: agentCountry.label };
		});
		setsParams((prevSparams) => {
			return { ...prevSparams, country: agentCountry.label };
		});
		setIsParams((prevIsParams) => {
			return { ...prevIsParams, country: agentCountry.label };
		});
		setAparams((prevAparams) => {
			return { ...prevAparams, country: agentCountry.value };
		});
	}, [agentCountry]);

	useEffect(() => {
		if (
			ordersparams.originCountry === undefined ||
			ordersparams.originCountry === null ||
			ordersparams.originCountry === ''
		)
			return;
		if (sparams.country === undefined || sparams.country === null || sparams.country === '')
			return;
		if (isparams.country === undefined || isparams.country === null || isparams.country === '')
			return;
		if (aparams.country === undefined || aparams.country === null || aparams.country === '')
			return;
		setIsLoading(true);
		let headers = undefined;
		const minValue = (currentPage - 1) * pageSize;
		const maxValue = minValue + pageSize - 1;
		headers = { range: `${minValue}-${maxValue}` };
		axios
			.get('/api/container/laraContainers', { params: aparams })
			.then((response) => {
				const length = response.headers['content-range'].split('/')[1];
				if (length !== undefined && length !== null) {
					setACContainers(length);
				} else {
					setACContainers(0);
				}
			})
			.catch((err) => {
				return err;
			});
		axios
			.get('/api/container/containers', { params: isparams })
			.then((response) => {
				const length = response.headers['content-range'].split('/')[1];
				if (length !== undefined && length !== null) {
					setIsContainers(length);
				} else {
					setIsContainers(0);
				}
			})
			.catch((err) => {
				return err;
			});
		axios
			.get('/api/container/containers', { params: sparams })
			.then((response) => {
				const length = response.headers['content-range'].split('/')[1];
				if (length !== undefined && length !== null) {
					setSoldContainers(length);
				} else {
					setSoldContainers(0);
				}
			})
			.catch((err) => {
				return err;
			});
		axios
			.get('/api/container/orders', {
				params: ordersparams,
				headers,
				paramsSerializer: {
					serialize: (params) => {
						return qs.stringify(params, { arrayFormat: 'comma', encode: false });
					}
				}
			})
			.then((response) => {
				const length = response.headers['content-range'].split('/')[1];
				setOrders(response.data);
				const rangeVal = response?.headers['content-range'];
				const rangeSplit = rangeVal.toString().split('/');
				const totalElements = parseInt(rangeSplit[1]);
				setTotalRecords(totalElements);
				setPageSize(10);
				if (length !== undefined && length !== null) {
					setBookings(length);
				} else {
					setBookings(0);
				}
				setIsLoading(false);
			})
			.catch((err) => {
				setIsLoading(false);
				setOrders([]);
			});
		axios
			.get('/api/container/containers/soldBySize?countryName=' + agentCountry.label)
			.then((response) => {
				setSoldBySizeKeysContainers(Object.keys(response.data).map(key =>
					key.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/\b\w/g, char => char.toUpperCase())
				  ))
				setSoldBySizeValuesContainers(Object.values(response.data).map(Number))
			})
			.catch((err) => {
				setErrorMessage('No Sold Containers');
			});
		axios
			.get('/api/container/containers/soldByCategory?countryName=' + agentCountry.label)
			.then((response) => {
				setSoldByCategoryKeysContainers(Object.keys(response.data).map(key =>
					key.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/\b\w/g, char => char.toUpperCase())
				  ))
				setSoldByCategoryValuesContainers(Object.values(response.data).map(Number))
			})
			.catch((err) => {
				setErrorMessage('No Sold Containers');
			});
	}, [aparams, isparams, sparams, ordersparams])
	
	const navigateToSalesHistory = () => {
		navigate('/booking/salesHistory');
		dispatch(setMenuActive('five'));
	};
	const navigateToManageOrders = () => {
		navigate('/booking/manageorders');
		dispatch(setMenuActive('four'));
	};
	const navigateToInStock = () => {
		navigate('/agent/containers/in-stock');
		dispatch(setMenuActive('three'));
	};
	const navigateToAwConfirmation= () => {
		navigate('/agent/containers/awaiting-confirmation');
		dispatch(setMenuActive('two'));
	};

	const getMuiTheme = () =>
		createTheme({
			components: {
				MuiPaper: {
					styleOverrides: {
						root: {
							boxShadow: 'none !important',
							backgroundColor: 'transparent',
							width: '100%'
						}
					}
				},
				MuiToolbar: {
					styleOverrides: {
						root: { paddingLeft: '0 !important' }
					}
				},
				MUIDataTable: {
					styleOverrides: {
						root: { borderRadius: '10px', margin: '0px 10px' },
						tableRoot: {
							display: 'block !important',
							height: '282px',
							maxHeight: '282px'
						}
					}
				},

				MuiInputBase: {
					styleOverrides: {
						root: { width: '109px', height: '35px' }
					}
				},
				MuiButton: {
					styleOverrides: {
						root: { marginRight: '10px' }
					}
				},
				MUIDataTableBodyCell: {
					styleOverrides: {
						stackedCommon: {
							paddingTop: '10px',
							borderBottom: '0',
							height: '45px',
							justifyContent: 'center !important',
							display: 'flex',
							borderLeft: `1px solid ${colors.colorFillPrimaryRedHover}`
						},
						stackedHeader: {
							display: 'none !important'
						},
						root: {
							textAlign: 'center',
							color: colors.colorFillPrimaryBlue
						}
					}
				},
				MuiButtonBase: {
					styleOverrides: {
						root: {
							marginRight: '0 !important'
						}
					}
				},
				MuiTableCell: {
					styleOverrides: {
						root: {
							borderBottom: '0',
							padding: '10px 0px !important',
							maxWidth: '170px',
							width: '170px'
						}
					}
				},
				MUIDataTableToolbar: {
					styleOverrides: {
						root: {
							color: colors.colorFillPrimaryBlue
						}
					}
				},
				MUIDataTableBodyRow: {
					styleOverrides: {
						root: {
							boxShadow: 'rgb(221, 222, 223) 5px 5px 5px'
						}
					}
				},
				MuiTablePagination: {
					styleOverrides: {
						selectLabel: {
							marginTop: 'auto'
						},
						displayedRows: {
							marginTop: 'auto'
						}
					}
				},
				MuiList: {
					styleOverrides: {
						root: {
							backgroundColor: 'white'
						}
					}
				},
				MUIDataTableSelectCell: {
					styleOverrides: {
						headerCell: {
							backgroundColor: 'transparent'
						}
					}
				},
				MuiSvgIcon: {
					styleOverrides: {
						root: {
							':hover': {
								cursor: 'pointer'
							},
							color: '#04246a'
						}
					}
				}
			}
		});

	/** total records returned by api */
	const [totalRecords, setTotalRecords] = useState<number>(0);
	/** current page number */
	const [currentPage, setCurrentPage] = useState(1);

	/** mui datatable options */
	const options = {
		selectableRows: 'none' as SelectableRows,
		textLabels: {
			body: {
				noMatch: isLoading ? <CircularProgress /> : 'No orders found'
			}
		},
		customFooter: () => {
			return (
				<tfoot>
					<tr>
						<td colSpan={1}>
							<Button
								onClick={navigateToManageOrders}
								style={{
									textDecoration: 'none',
									display: 'inline-block',
									float: 'right'
								}}
							>
								<label
									style={{
										color: '#314b85',
										marginLeft: '10px',
										cursor: 'pointer'
									}}
								>
									View Orders
								</label>
								<IosShareOutlinedIcon className="agent-topLeft-shareIcon" />
							</Button>
						</td>
					</tr>
				</tfoot>
			);
		},
		rowsPerPageOptions: [],
		count: totalRecords,
		serverSide: true,
		onChangePage(currentPage: number) {
			setCurrentPage(currentPage + 1);
		},
		filterType: 'dropdown' as FilterType,
		viewColumns: false,
		print: false,
		download: false,
		search: false,
		filter: false,
		pagination: false
	};

	const columnDefs = [
		{
			label: 'createDate',
			name: 'createDate',
			options: {
				display: false
			}
		},
		{
			label: 'Order #',
			name: 'orderId',
			options: {
				filter: false,
				display: true
			}
		},
		{
			label: 'Quantity',
			name: 'totalContainerQuantity',
			options: {
				filter: false,
				display: true
			}
		},
		{
			label: 'Order Date',
			name: 'orderDate',
			options: {
				filter: false,
				display: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return value ?? tableMeta.rowData[0] ?? '';
				}
			}
		},
		{
			label: 'Buyer',
			name: 'customerName',
			options: {
				filter: false,
				display: true,
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					const rowIndex = tableMeta.rowIndex;
					const firstName = orders[rowIndex].customerFirstName;
					const lastName = orders[rowIndex].customerLastName;
					const fullName = `${firstName} ${lastName}`;
					return fullName;
				},
			}
		},
		{
			label: 'Status',
			name: 'paymentStatus',
			options: {
				filter: false,
				display: false
			}
		}
	];

	const soldByCategory = {
		labels: soldByCategoryKeysContainers,
		datasets: [
			{
				label: 'Containers sold by category',
				data: soldByCategoryValuesContainers,
				
				backgroundColor: [
					'rgba(62, 100, 184, 0.6)',
					'rgba(3, 23, 69, 0.6)',
					'rgba(178, 1, 1, 0.6)',
					'rgba(4, 135, 103, 0.6)',
					'rgba(42, 43, 44, 0.6)',
					'rgba(107, 119, 144, 0.6)',
					'rgba(194, 168, 62, 0.6)',
					'rgba(58, 16, 90, 0.6)',
					'rgba(175, 34, 106, 0.6)',
					'rgba(245, 152, 152, 0.6)',

				],
				borderColor: [
					'rgba(62, 100, 184, 1)',
					'rgba(3, 23, 69, 1)',
					'rgba(178, 1, 1, 1)',
					'rgba(4, 135, 103, 1)',
					'rgba(42, 43, 44, 1)',
					'rgba(107, 119, 144, 1)',
					'rgba(194, 168, 62, 1)',
					'rgba(58, 16, 90, 1)',
					'rgba(175, 34, 106, 1)',
					'rgba(245, 152, 152, 1)',

				],
				borderWidth: 1
			}
		]
	};

	const soldBySizeData = {
		labels: ['20 Ft', '40 Ft', '45 Ft', '48 Ft'],
		datasets: [
			{
				label: 'Containers sold by size',
				data: soldBySizeValuesContainers,
				
				backgroundColor: [
					'rgba(62, 100, 184, 0.6)',
					'rgba(3, 23, 69, 0.6)',
					'rgba(178, 1, 1, 0.6)',
					'rgba(4, 135, 103, 0.6)',
					'rgba(42, 43, 44, 0.6)',
					'rgba(107, 119, 144, 0.6)',
					'rgba(194, 168, 62, 0.6)',
					'rgba(58, 16, 90, 0.6)',
					'rgba(175, 34, 106, 0.6)',
					'rgba(245, 152, 152, 0.6)',

				],
				borderColor: [
					'rgba(62, 100, 184, 1)',
					'rgba(3, 23, 69, 1)',
					'rgba(178, 1, 1, 1)',
					'rgba(4, 135, 103, 1)',
					'rgba(42, 43, 44, 1)',
					'rgba(107, 119, 144, 1)',
					'rgba(194, 168, 62, 1)',
					'rgba(58, 16, 90, 1)',
					'rgba(175, 34, 106, 1)',
					'rgba(245, 152, 152, 1)',

				],
				borderWidth: 1
			}
		]
	};

	return (
		<AgentLayout>
			<div className="agent">
				{/* Top */}
				<div className="agent-top">
					{/* Left */}
					<div className="agent-topLeft">
						{/* Row */}
						<div className="agent-topLeft-row">
							{/* Item */}
							<div className="agent-topLeft-item">
								<div className="agent-topLeft-itemRow title">
									<p>Awaiting Confirmation</p>
								</div>

								<div className="agent-topLeft-itemRow">
									<div className="agent-topLeft-itemRow-left">
										<TaskOutlinedIcon className="agent-topLeft-icon" />
									</div>

									<div className="agent-topLeft-itemRow-right">
										<p>{ACContainers ? ACContainers : 0}</p>
									</div>
								</div>

								<div className="agent-topLeft-itemRow">
									<a
										onClick={navigateToAwConfirmation}
										style={{
											textDecoration: 'none', cursor: 'pointer'
										}}
									>
										<p>View Containers</p>
									</a>
									&nbsp;
									<IosShareOutlinedIcon className="agent-topLeft-shareIcon" />
								</div>
							</div>

							{/* Item */}
							<div className="agent-topLeft-item">
								<div className="agent-topLeft-itemRow title">
									<p>In Stock</p>
								</div>

								<div className="agent-topLeft-itemRow">
									<div className="agent-topLeft-itemRow-left">
										<BentoOutlinedIcon className="agent-topLeft-icon" />
									</div>

									<div className="agent-topLeft-itemRow-right">
										<p>{IsContainers ? IsContainers : 0}</p>
									</div>
								</div>

								<div className="agent-topLeft-itemRow">
									<a
										onClick={navigateToInStock}
										style={{
											textDecoration: 'none', cursor: 'pointer'
										}}
									>
										<p>View Containers</p>
									</a>
									&nbsp;
									<IosShareOutlinedIcon className="agent-topLeft-shareIcon" />
								</div>
							</div>
						</div>

						{/* Row */}
						<div className="agent-topLeft-row">
							{/* Item */}
							<div className="agent-topLeft-item">
								<div className="agent-topLeft-itemRow title">
									<p>Bookings</p>
								</div>

								<div className="agent-topLeft-itemRow">
									<div className="agent-topLeft-itemRow-left">
										<CreditCardOutlinedIcon className="agent-topLeft-icon" />
									</div>

									<div className="agent-topLeft-itemRow-right">
										<p>{bookings ? bookings : 0}</p>
									</div>
								</div>

								<div className="agent-topLeft-itemRow">
									<a
										onClick={navigateToManageOrders}
										style={{
											textDecoration: 'none', cursor: 'pointer'
										}}
									>
										<p>View Orders</p>
									</a>
									&nbsp;
									<IosShareOutlinedIcon className="agent-topLeft-shareIcon" />
								</div>
							</div>

							{/* Item */}
							<div className="agent-topLeft-item">
								<div className="agent-topLeft-itemRow title">
									<p>Sold Containers</p>
								</div>

								<div className="agent-topLeft-itemRow">
									<div className="agent-topLeft-itemRow-left">
										<FactCheckOutlinedIcon className="agent-topLeft-icon" />
									</div>

									<div className="agent-topLeft-itemRow-right">
										<p>{soldContainers ? soldContainers : 0}</p>
									</div>
								</div>

								<div className="agent-topLeft-itemRow">
									<a
										onClick={navigateToSalesHistory}
										style={{
											textDecoration: 'none', cursor: 'pointer'
										}}
									>
										<p>View Containers</p>
									</a>
									&nbsp;
									<IosShareOutlinedIcon className="agent-topLeft-shareIcon" />
								</div>
							</div>
						</div>
					</div>

					{/* Right */}
					<div className="agent-topRight">
						{/* Orders */}
						<ThemeProvider theme={getMuiTheme()}>
							<MUIDataTable
								title={'New Orders'}
								data={orders ? orders : []}
								columns={columnDefs}
								options={options}
							/>
						</ThemeProvider>
					</div>
				</div>

				{/* Bottom */}
				<div className="agent-bottom">
					{/* Left */}
					<div className="agent-bottomLeft">
						{/* Orders */}
						<div className="agent-bottomLeft-type">
							{/* Title */}
							<div className="agent-bottomLeft-typeTitle">
								<p>Sold Containers: By Category</p>
								{errorMessage &&
								errorMessage !== null &&
								errorMessage !== undefined &&
								errorMessage !== '' ? (
									<h2 style={{ margin: '145px', color: '#bb0101' }}>
										{' '}
										{errorMessage}{' '}
									</h2>
								) : (
									<Doughnut data={soldByCategory} className="doughnut-chart" style={{ width: '500px', maxWidth: '500px', maxHeight: '300px' }} width={500}/>
								)}
							</div>
						</div>
					</div>

					{/* Right */}
					<div className="agent-bottomRight">
						{/* Orders */}
						<div className="agent-bottomRight-type">
							{/* Title */}
							<div className="agent-bottomRight-typeTitle">
								<p>Sold Containers: By Size</p>
								{errorMessage &&
								errorMessage !== null &&
								errorMessage !== undefined &&
								errorMessage !== '' ? (
									<h2 style={{ margin: '145px', color: '#bb0101' }}>
										{' '}
										{errorMessage}{' '}
									</h2>
								) : (
									<Doughnut data={soldBySizeData} className="doughnut-chart" style={{ width: '300px', maxWidth: '300px', maxHeight: '300px' }} width={500} />
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</AgentLayout>
	);
};

export default AgentDashboard;
