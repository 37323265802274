 
/* eslint-disable   */
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import CloseIcon from '@mui/icons-material/Close';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	Grid,
	IconButton,
	InputLabel,
	ListItemText,
	MenuItem,
	Modal
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { Document } from '../../../shared/models/document';
import { DocumentTypeDetails } from '../../../shared/models/documentTypeDetails';
import { NotificationTypeEnum } from '../../../shared/models/notificationType';
import { useCallOrdersNotificationApi } from '../../../shared/util/SharedMethods';
import AlertCmpt from '../Util/AlertCmpt';
import './OrderDetailSummary.css';

type UploadFilesPopoverProps = {
	orderId: number;
	handleClose: any;
	profileUser: any;
	downloadDocument: any;
};

const UploadFilesPopover = ({
	orderId,
	handleClose,
	profileUser,
	downloadDocument
}: UploadFilesPopoverProps) => {
	const MAX_COUNT = 5;
	const [progressBar, setProgressBar] = useState(false);
	const uploadInputRef = useRef<HTMLInputElement | null>(null);
	const values = useSelector((state: any) => state?.info?.value);
	const [documents, setDocuments] = useState<Document[]>([]);
	const [documentTypes, setDocumentTypes] = useState<DocumentTypeDetails[]>([]);
	const [open, setOpen] = useState(false);
	const [messageType, setMessageType] = useState('');
	const [messageText, setMessageText] = useState('');
	let mediaFile: File;
	const [uploadedFiles, setUploadedFiles] = useState<any[]>(
		values?.filesArr?.length > 0 ? values?.filesArr : []
	);
	const [selectedFileType, setSelectedFileType] = useState<{ id: number; name: string }>({
		id: 0,
		name: ''
	});

	useEffect(() => {
		getDocumentsInner();
	}, [uploadedFiles]);

	useEffect(() => {
		axios
			.get(`/api/documents/documentTypes?userRole=${profileUser.role}`)
			.then((response) => {
				const filteredData = response.data.filter((item: any) => item.deleted === false);
				setDocumentTypes(filteredData);
			})
			.catch((e) => console.log(e));
	}, []);

	const getDocumentsInner = () => {
		setProgressBar(false);

		axios
			.get('/api/documents/documents', { params: { orderId: orderId, excludeStatus: 'deleted' } })
			.then((response) => {
				setDocuments(response.data);
				setProgressBar(false);
			})
			.catch((e) => {
				setProgressBar(false);
				setDocuments([]);
			});
	};

	function removeSpaces(inputString: string) {
		let stringWithoutSpaces = inputString.replace(/\s/g, '');
		return stringWithoutSpaces;
	}

	/**
	 * event to be triggered when uploading files
	 */
	const handleFileEvent = (e: any) => {
		
		setProgressBar(true)
		mediaFile = e.target.files[0];
		const key = new Date().valueOf();
		const type = mediaFile.type?.split('/')[1] ?? ''
		let fullMediaFile: any = '', s3DocumentName: any = '';
		if(type === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
			fullMediaFile= new File([mediaFile],`${orderId}-${profileUser.firstName}-${profileUser.lastName}-${selectedFileType.name}.xlsx`, { type: mediaFile.type });
			s3DocumentName = new File([mediaFile],`${key}-${orderId}-${profileUser.firstName}-${profileUser.lastName}-${selectedFileType.name}.xlsx`, { type: mediaFile.type });
		}else if(type === 'vnd.openxmlformats-officedocument.wordprocessingml.document'){
			fullMediaFile= new File([mediaFile],`${orderId}-${profileUser.firstName}-${profileUser.lastName}-${selectedFileType.name}.docx`, { type: mediaFile.type });
			s3DocumentName = new File([mediaFile],`${key}-${orderId}-${profileUser.firstName}-${profileUser.lastName}-${selectedFileType.name}.docx`, { type: mediaFile.type });
		}else{
			fullMediaFile = new File([mediaFile],`${orderId}-${profileUser.firstName}-${profileUser.lastName}-${selectedFileType.name}.${type}`, { type: mediaFile.type });
			s3DocumentName = new File([mediaFile],`${key}-${orderId}-${profileUser.firstName}-${profileUser.lastName}-${selectedFileType.name}.${type}`, { type: mediaFile.type });
		}

		if(mediaFile.size > 3000000){
			setOpen(true);
			setProgressBar(false)
			setMessageType('error');
			setMessageText('File exceeded the 3MB max file size');
		} else{
			const formData = new FormData();
			formData.append('file', s3DocumentName);
			axios
				.post('/api/upload', formData, {
					headers: {
					'Content-Type': 'multipart/form-data',
					},
				})
				.then(() => {
					axios
					.post('api/documents/documents', {
						userCcgid: profileUser.ccgId,
						name: fullMediaFile.name,
						s3DocumentName: s3DocumentName.name,
						orderId: orderId,
						docTypeId: selectedFileType.id
					})
					.then(() => {
						setOpen(true);
						setMessageType('success');
						setMessageText('Successfully Attached');
						setProgressBar(false)
						getDocumentsInner();
						if(selectedFileType.name === 'INVOICE'){
							useCallOrdersNotificationApi(NotificationTypeEnum.INVOICEUPLOADED, orderId);
						}
						else if(selectedFileType.name === 'PAYMENT PROOF'){
							useCallOrdersNotificationApi(NotificationTypeEnum.AWAITINGCONFIRMATION, orderId);
							useCallOrdersNotificationApi(NotificationTypeEnum.NEWPAYMENTPROOF, orderId);
						}
						else if(selectedFileType.name === 'CUSTOM CLEARANCE'){
							useCallOrdersNotificationApi(NotificationTypeEnum.NEWCUSTOMSCLEARANCEPROOF, orderId);
						}

					})
					.catch((err: any) => {
						setMessageType('error');
						setMessageText(err.response.data.description ?? 'Error Uploading Document');
						setOpen(true);
						setProgressBar(false)
					});
				})
				.catch((err: any) => {
					setMessageType('error');
					setMessageText(err.response.data ?? 'Error Uploading Document on server');
					setOpen(true);
					setProgressBar(false)
				})

		}
		if (documents.length + 1 > MAX_COUNT) {
			setMessageType('error');
			setMessageText('Max number of attached files Exceeded !');
			setOpen(true);
			return;
		}
		
		// clear file after upload is handled
		e.target.value = null;
	};

	/**
	 * method to be able to delete any uploaded file
	 */
	const handleClear = (fileDeleted: Document) => {
		axios
			.delete(
				'/api/documents/documents/' + fileDeleted.id + '/orders/' + orderId + '/users/' + profileUser.ccgId
			)
			.then(() => {
				getDocumentsInner();
				setMessageType('success');
				setMessageText('Successfully Deleted');
				setOpen(true);
				setProgressBar(false);
			})
			.catch((e) => {
				setProgressBar(false);
				setMessageType('error');
				setMessageText(e.response.data.description ?? 'Error Deleting Document');
				setOpen(true);
			});
	};

	const handleSelectChange = (event: SelectChangeEvent, key: string) => {
		// Use regular expression to extract the numeric part
		const numericPart: any = key?.match(/\d+/);
		const numericValue = numericPart && parseInt(numericPart[0], 10);
		setSelectedFileType({
			id: numericValue,
			name: event.target.value
		});
	};

	return (
		<div style={{ height: '400px', width: '600px' }}>
			{progressBar && (
				<div>
					<Modal
						className="modal-popup-progress-bar"
						open={progressBar}
						onClose={() => setProgressBar(false)}
					>
						<Box>
							<CircularProgress />
						</Box>
					</Modal>
				</div>
			)}
			<AlertCmpt
				open={open}
				setOpen={setOpen}
				messageType={messageType}
				messageText={messageText}
			/>
			<Button
				onClick={handleClose}
				sx={{
					position: 'absolute',
					top: 10,
					right: 0,
					'&:hover': {
						backgroundColor: 'transparent'
					}
				}}
				disableRipple
			>
				<CloseIcon
					sx={{
						color: 'black'
					}}
				/>
			</Button>
			<h4 style={{ paddingTop: '20px', marginLeft: '10px', textDecoration: 'underline' }}>
				Upload Files for Order #{orderId}
			</h4>
			<main style={{ marginLeft: '10px', marginTop: '20px' }}>
				<Grid item xs={12}>
					<FormControl>
						<Select
							onChange={(e: any, k: any) => handleSelectChange(e, k.key)}
							value={selectedFileType.name}
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							label="Document Type"
							sx={{
								width: '200px',
								marginRight: '5px'
							}}
						>
							{documentTypes.map((o: any) => (
								<MenuItem key={o.id} value={o.name}>
									<ListItemText primary={o.name} />
								</MenuItem>
							))}
						</Select>
						<InputLabel
							style={{ padding: '15.5px, 14px' }}
							id="demo-simple-select-label"
						>
							Document Type
						</InputLabel>
					</FormControl>
					<Button
						component="label"
						startIcon={<UploadFileIcon />}
						sx={{ color: '#314b85', fontSize: '1rem', marginTop: '5px' }}
						disabled={uploadedFiles.length >= 5 || selectedFileType.name === ''}
						onClick={() => {
							// 👇 We redirect the click event onto the hidden input element
							uploadInputRef.current?.click();
						}}
					>
						Upload Document
					</Button>
					<input
						ref={uploadInputRef}
						type="file"
						accept="image/jpeg, image/png, application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
						style={{ display: 'none' }}
						onChange={handleFileEvent}
					/>
				</Grid>
				<div style={{ marginTop: '20px' }}>
					<h5 style={{ textDecoration: 'underline' }}>Uploaded Files</h5>
					<Grid item xs={12} marginTop={'15px'}>
						{documents.map((document: Document, index: number) => (
							<Grid item display="flex" key={index}>
								<p
									onClick={() => {
										downloadDocument(document);
									}}
									style={{
										marginBottom: '0px',
										marginTop: '5px',
										cursor: 'pointer'
									}}
								>
									{document?.name}
								</p>
								<IconButton
									onClick={() => handleClear(document)}
									color="inherit"
									component="label"
									size="small"
								>
									<ClearOutlinedIcon color="action" fontSize="small" />
								</IconButton>
							</Grid>
						))}
					</Grid>
				</div>
			</main>
		</div>
	);
};

export default UploadFilesPopover;
