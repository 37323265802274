 
/* eslint-disable   */
import CloseIcon from '@mui/icons-material/Close';
import {
	Backdrop,
	Button,
	CircularProgress,
	Table,
	TableBody,
	TableCell,
	TableRow,
	TextField
} from '@mui/material';
import { CSSProperties } from '@mui/material/styles/createTypography';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FreeMode, Navigation, Thumbs } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Container } from '../../../shared/models/container';
import { Product } from '../../../shared/models/product';
import colors from '../../../shared/styles/_variables.module.scss';
import CartButton from '../Checkout/CartButton';
import AlertCmpt from '../Util/AlertCmpt';

type ProductDetailsSummaryProps = {
	productDetails: Product;
	handleClose: any;
};

interface FileData {
	src: string;
	s3ImageName: string;
	id: number
}

type FileDataMap = Map<number, FileData>;

const ProductDetailsSummary = ({ productDetails, handleClose }: ProductDetailsSummaryProps) => {
	const [isLoading, setIsLoading] = useState(true);
	const [isEmpty, setIsEmpty] = useState(true);
	const [open, setOpen] = useState(false);
	const [messageType, setMessageType] = useState('');
	const [messageText, setMessageText] = useState('');
	const [containers, setContainers] = useState<Container[]>([]);
	const currency = useSelector((state: any) => state.currency.value);
	const priceAfterCurrencyChange = productDetails.prices?.find(
		(price: any) => price.currency === currency
	);
	const priceUSD = productDetails.prices?.find(
		(price: any) => price.currency === 'USD'
	);
	const [swiperIndex, setSwiperIndex] = useState<number>(0);
	const [productDetailsDisplay, setProductDetailsDisplay] = useState({});
	const [fileDataList, setFileDataList] = useState<any>([]);
	const [swiperItems, setSwiperItems] = useState<React.ReactNode[]>([]);
	const loggedInUser = useSelector((state: any) => state.loggedInUser.value);
	const [imageList, setImageList] = useState<any[]>([]);
	const [containerImage, setContainerImage] = useState<any>([]);
	const [productContainer, setProductContainer] = useState<any[]>([]);
	let imageSource = '/media/container-logo.png';
	let imageAlt = 'Default Container Image'

	useEffect(() => {
		if (productDetails.id !== undefined && productDetails.id !== -1) {
			const params = {
				productId: productDetails?.id ?? -1
			};
			axios
				.get('/api/container/containers', {
					params: params
				})
				.then((response) => {
					setContainers(response.data);
				})
				.catch((response) => {
					setMessageType('error');
					setMessageText(
						response?.response?.data?.description ??
							'Could not get containers for product!'
					);
					setOpen(true);
				});
		}
	}, []);

	useEffect(() => {
		setProductDetailsDisplay((prev) => {
			return {
				...prev,
				Country: productDetails?.country,
				City: productDetails?.city,
				Category: productDetails?.category,
				'Size/Type':
				productDetails?.size + 'ft, ' + productDetails.productType,
				Condition: productDetails?.condition,
				'Pool Full Name': productDetails?.depotName,
				Price: loggedInUser
					? (priceAfterCurrencyChange?.currency + ' ' ?? '') +
					  priceAfterCurrencyChange?.price
					: 'USD ' + Math.round(priceUSD?.price ?? 0),
				'Depot Address': productDetails?.depotAddress,
				'Quantity in Stock': productDetails?.inStock
			};
		});
		//getContainerImages();
	}, []);

	/* useEffect(() => {
		getSwiperItems();
	}, [productDetailsDisplay, fileDataList]); */


	/* const getContainerImages = () => {
		containers?.forEach((container: Container) => {
			setProductContainer([container])
			if (container?.images?.length !== undefined && container?.images?.length > 0) {
				const images = container.images
				setImageList(images);
			}
		});
	}; */
	useEffect(() => {
		const fetchData = async () => {
		const images: any[] = []
		containers?.forEach(async (container: Container) => {
			setProductContainer([container])
			if (container?.images?.length !== undefined && container?.images?.length > 0) {
				container.images.map((image:any) => {
					const imageDetails = {image: image, comment: container.comments}
					images.push(imageDetails)
				})
			}
			else{
				const imageDetails = {image: null, comment: container.comments}
				images.push(imageDetails)
			}
		});
		const transformedArray = await Promise.all(
			images.map(async (item:any) => {				
				let imageDetails = {image: '/media/container-logo.png' , comment: item.comment}
				if(item.image !== null){
					try {
						// Make an API call using Axios when item is not empty
						const response = await axios.get(`/api/download/${item.image.s3ImageName}`, {
							method: 'GET',
							responseType: 'blob'
						});
						// Return the API response data or any specific property you need
						const blob = new Blob([response.data]);
						const imageUrl = URL.createObjectURL(blob);									
						imageDetails = {image: imageUrl, comment: item.comment}
						} 
					catch (error) {
						console.error('Error fetching data:', error);
						return null; // Return null or handle error case appropriately
					}
				}
				return imageDetails
			})
		);
		if(transformedArray.length > 0){
			setIsEmpty(false)
			const swipeItems = transformedArray.map((image: any, index:any) => {
				return (
					<SwiperSlide
						key={index}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							maxHeight: '500px'
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center'
							}}
						>
							<div>
								<img
									style={{
										width: 500,
										height: 300,
										maxWidth: 500,
										maxHeight: 300,
										borderRadius: '10px'
									}}
									src={image.image}
									alt={image.comment}
								/>
							</div>
							<TextField
								label="Agent Comment"
								value={image.comment ?? ''}
								multiline
								rows={2}
								disabled
								style={{ marginTop: '20px', width: '100%', }}
								InputLabelProps={{
									style: { color: '#000000b3', fontWeight: 'bold' },
								}}
								InputProps={{
									style: { color: '#000000b3', fontWeight: 'bold' },
									sx: { textarea: { WebkitTextFillColor: '#000000b3 !important', fontWeight: 'bold' } },
								}}
							/>
						</div>
					</SwiperSlide>
				);
			});
			setSwiperItems(swipeItems);
		}
		};
		setTimeout(() => {
			setIsLoading(false)
		}, 2500);
		fetchData();
	  }, [containers]);

	/* const getSwiperItems = () => {
		setIsLoading(false)
		if(containerImage.length > 0){
			setIsEmpty(false)
			const swipeItems = productContainer[0].images.map((image: any, index:any) => {
				return (
					<SwiperSlide
						key={index}
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							maxHeight: '500px'
						}}
					>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'center',
								alignItems: 'center'
							}}
						>
							<div>
								{<img style={{
									width: 450,
									height: 300,
									maxWidth: 450,
									maxHeight: 300,
									borderRadius: '10px'
								}} src={containerImage[index].src} alt={containerImage[index].s3ImageName}/>}
							</div>
							<TextField
								label="Agent Comment"
								value={productContainer[0]?.comments ?? ''}
								multiline
								disabled
								style={{ marginTop: '20px', width: '100%', }}
								InputLabelProps={{
									style: { color: '#00000099' },
								}}
							/>
						</div>
					</SwiperSlide>
				);
			});
			console.log('swipeItems', swipeItems)
			//if (swipeItems.length > 0) setIsLoading(false);
			setSwiperItems(swipeItems);
		}
	} */

	return (
		<div>
			{isLoading ? (
				<Backdrop
					sx={{
						color: colors.colorFillPrimaryBlue,
						zIndex: (theme) => theme.zIndex.drawer + 1
					}}
					open={isLoading}
				>
					<CircularProgress color="inherit" />
				</Backdrop>
			) : (
			<>
			<AlertCmpt
				open={open}
				setOpen={setOpen}
				messageType={messageType}
				messageText={messageText}
			/>
			<Button
				onClick={handleClose}
				sx={{
					position: 'absolute',
					top: 10,
					right: 0,
					'&:hover': {
						backgroundColor: 'transparent'
					}
				}}
				disableRipple
			>
				<CloseIcon
					sx={{
						color: 'black'
					}}
				/>
			</Button>
			<h4
				style={{
					paddingTop: '20px',
					marginLeft: '10px',
					textDecoration: 'underline'
				}}
			>
				Product Details
			</h4>
			<main style={{ display: 'flex' }}>
				<Table title={'Product Details'}>
					<TableBody>
						{Object.entries(productDetailsDisplay)?.map(([key, value]) => (
							<TableRow key={key}>
								<TableCell
									variant="head"
									sx={{ color: colors.colorFillPrimaryBlue }}
								>
									{key
										.replace(/([a-z])([A-Z])/g, '$1 $2')
										.charAt(0)
										.toUpperCase() +
										key.replace(/([a-z])([A-Z])/g, '$1 $2').slice(1)}
								</TableCell>
								<TableCell sx={{ color: colors.colorFillPrimaryBlue }}>
									{value?.toString()}
								</TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
				<span
					style={{
						maxHeight: '500px',
						maxWidth: '800px',
						marginLeft: '50px',
						display: 'flex',
						justifyContent: 'space-evenly',
						flexDirection: 'column',
						alignItems: 'center'
					}}
				>{isEmpty ? (
					<>
					<img style={{
						width: 450,
						height: 300,
						maxWidth: 450,
						maxHeight: 300,
						borderRadius: '10px'
					}} src={imageSource} alt={imageAlt} />
					<TextField
						label="Agent Comment"
						value={productContainer[0]?.comments ?? ''}
						multiline
						rows={2}
						disabled
						style={{ marginTop: '20px', width: '100%', }}
						InputLabelProps={{
							style: { color: '#000000b3', fontWeight: 'bold' },
						}}
						InputProps={{
							style: { color: '#000000b3', fontWeight: 'bold' },
							sx: { textarea: { WebkitTextFillColor: '#000000b3 !important', fontWeight: 'bold' } },
						}}
					/>
					</>
				) : (
					<Swiper
						spaceBetween={200}
						navigation={true}
						modules={[FreeMode, Navigation, Thumbs]}
						style={
							{
								padding: '10px',
								maxWidth: '500px',
								maxHeight: '800px',
								'--swiper-navigation-color': '#314b85',
								'--swiper-navigation-size': '35px'
							} as CSSProperties
						}
						onSlideChange={(swiper) => {
							setSwiperIndex(swiper.activeIndex);
						}}
					>
						{swiperItems}
					</Swiper>
				)}
					<CartButton
						product={productDetails}
						setOpen={setOpen}
						setMessageText={setMessageText}
						setMessageType={setMessageType}
						hideLabel={false}
					/>
				</span>
			</main>
			</>
			)}
		</div>
	);
};

export default ProductDetailsSummary;
