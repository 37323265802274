import {
	Box,
	Button,
	CircularProgress,
	FormControl,
	InputLabel,
	MenuItem,
	Modal,
	Select,
	TextField
} from '@mui/material';
import axios from 'axios';
import qs from 'qs';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import AlertCmpt from '../../../../modules/buyer/Util/AlertCmpt';
import { NotificationTypeEnum } from '../../../models/notificationType';
import { useCallOrdersNotificationApi } from '../../../util/SharedMethods';
import './EditBooking.scss';
import { User } from '../../../models/user';

const style = {
	position: 'absolute',
	top: '50%',
	left: '55%',
	transform: 'translate(-50%, -50%)',
	width: '50%',
	height: 600,
	maxHeight: 600,
	borderRadius: '10px',
	bgcolor: 'background.paper',
	border: '2px solid gray',
	boxShadow: 24,
	overflowY: 'auto',
	pt: 2,
	px: 4,
	pb: 3
};

const EditBooking = (props: any) => {
	const { open, setOpen, rowDataById, setRowDataById, setManageOrdersData, param, setIsLoading } =
		props;

	const [openError, setOpenError] = useState(false);
	const [messageType, setMessageType] = useState('');
	const [messageText, setMessageText] = useState('');
	const [progressBar, setProgressBar] = useState(false);
	//get userinfo from session storage
	// we use this instead of redux in order to get userinfo faster
	const userinfo: any = window.sessionStorage.getItem('user');
	const [user, setUser] = useState<User>(JSON.parse(userinfo));

	const currency = useSelector((state: any) => state.currency.value);

	const handleClose = () => {
		setOpen(false);
	};

	const onInputChange = (e: any, name: any) => {
		const val = (e.target && e.target.value) || '';
		const dataUpdated = { ...rowDataById };
		dataUpdated[`${name}`] = val;

		setRowDataById({ ...dataUpdated });
	};

	const handleSaveModal = () => {
		const headers = { range: '0-9' };

		const data = {
			bookingStatus: rowDataById?.bookingStatus
		};
		if (
			(data.bookingStatus.toLowerCase() === 'ready for pick up' ||
				data.bookingStatus.toLowerCase() === 'picked up') &&
			rowDataById.paymentStatus.toLowerCase() !== 'validated' &&
			rowDataById.documentStatus.toLowerCase() !== 'validated'
		) {
			setMessageType('error');
			setMessageText(
				'Cannot change booking status to ' +
					data.bookingStatus +
					' before approving payment proof and custom clearance'
			);
			setOpenError(true);
			return;
		}
		setProgressBar(true);
		axios
			.put(`/api/container/orders/${rowDataById.orderId}`, data)
			.then(() => {
				if (data.bookingStatus.toLowerCase() === 'ready for pick up') {
					useCallOrdersNotificationApi(
						NotificationTypeEnum.ORDERREADYFORPICKUP,
						rowDataById.orderId
					);
				}
				let tempParam = param;
				if (user?.role !== 'admin') {
					tempParam = {
						...param,
						originCountry: user?.location.split(',')[0]
					};
				}
				axios
					.get('/api/container/orders', {
						params: tempParam,
						headers,
						paramsSerializer: {
							serialize: (params) => {
								return qs.stringify(params, {
									arrayFormat: 'comma',
									encode: false
								});
							}
						}
					})
					.then((response) => {
						setManageOrdersData(response?.data);
						setProgressBar(false);
						setOpen(false);
						setIsLoading(false);
					})
					.catch(() => {
						setManageOrdersData([]);
						setProgressBar(false);
						setIsLoading(false);
						setOpen(false);
					});
			})
			.catch((error) => {
				setMessageType('error');
				setMessageText(error.response.data.description ?? 'Could not edit');
				setOpenError(true);
				setProgressBar(false);
				setIsLoading(false);
			});
	};
	return (
		<div>
			{progressBar && (
				<div>
					<Modal
						className="modal-popup-progress-bar"
						open={progressBar}
						onClose={() => setProgressBar(false)}
					>
						<Box>
							<CircularProgress />
						</Box>
					</Modal>
				</div>
			)}
			<AlertCmpt
				open={openError}
				setOpen={setOpenError}
				messageType={messageType}
				messageText={messageText}
			/>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<div style={{ marginBottom: '50px' }}>
						<h2>Edit</h2>
					</div>
					<div
						style={{
							display: 'flex',
							flexDirection: 'column'
						}}
					>
						<TextField
							disabled
							required
							id="orderId"
							label="ID"
							value={rowDataById?.orderId}
							onChange={(e) => onInputChange(e, 'orderId')}
							style={{ marginBottom: '15px' }}
						/>
						<TextField
							disabled
							required
							id="customerFirstName"
							label="Buyer"
							value= {rowDataById?.customerFirstName + ' ' + rowDataById?.customerLastName}
							onChange={(e) => onInputChange(e, 'customerFirstName')}
							style={{ marginBottom: '15px' }}
						/>
						<TextField
							disabled
							required
							id="amount"
							label="Total Amount"
							value={
								rowDataById?.amounts?.find(
									(amount: any) => amount.currencyType === 'MAIN'
								)?.currency +
								' ' +
								rowDataById?.amounts?.find(
									(amount: any) => amount.currencyType === 'MAIN'
								)?.price
							}
							onChange={(e) => onInputChange(e, 'amount')}
							style={{ marginBottom: '15px' }}
						/>
						<TextField
							disabled
							id="orderDate"
							label="Order Date"
							value={rowDataById?.orderDate ?? rowDataById?.createdDate ?? ''}
							onChange={(e: any) => onInputChange(e, 'orderDate')}
							style={{ marginBottom: '15px' }}
						/>
						<FormControl fullWidth>
							<InputLabel id="bookingStatus">Booking Status *</InputLabel>
							<Select
								required
								labelId="bookingStatus"
								id="bookingStatus"
								value={rowDataById?.bookingStatus}
								label="Booking Status *"
								onChange={(e) => onInputChange(e, 'bookingStatus')}
								style={{ marginBottom: '15px' }}
							>
								<MenuItem value={'Ready For Pick Up'}>Ready For Pick Up</MenuItem>
								<MenuItem value={'None'}>None</MenuItem>
								<MenuItem value={'Picked Up'}>Picked Up</MenuItem>
							</Select>
						</FormControl>

						<div
							style={{
								display: 'flex',
								flexDirection: 'row',
								justifyContent: 'flex-end',
								padding: '5px'
							}}
						>
							<Button
								variant="contained"
								className="manage-btn"
								onClick={handleSaveModal}
							>
								Save
							</Button>
							<Button
								variant="outlined"
								className="manage-btn-cancel"
								onClick={() => setOpen(false)}
							>
								Cancel
							</Button>
						</div>
					</div>
				</Box>
			</Modal>
		</div>
	);
};

export default EditBooking;
