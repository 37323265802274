import { createSlice } from '@reduxjs/toolkit';

export const menuActiveSlice = createSlice({
	name: 'menuActive',
	initialState: {
		value: {
			menuId: 'one'
		}
	},
	reducers: {
		setMenuActive: (state, action) => {
			state.value.menuId = action.payload;
		}
	}
});
export const { setMenuActive } = menuActiveSlice.actions;
export default menuActiveSlice.reducer;
