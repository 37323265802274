import EditIcon from '@mui/icons-material/Edit';
import { debounce } from 'lodash';
import { createTheme, Popover, ThemeProvider } from '@mui/material';
import axios from 'axios';
import MUIDataTable, { MUIDataTableCustomHeadRenderer, SelectableRows } from 'mui-datatables';
import colors from '../../../shared/styles/_variables.module.scss';
import { useEffect, useState } from 'react';
import {
	getMuiTheme,
	noSelectHeaderTemplate
} from '../../../shared/components/DataTable/TableUtils';
import AdminLayout from '../Util/AdminLayout';
import UpdateCurrency from './UpdateCurrency';
import { useDispatch } from 'react-redux';
import { setMenuActive } from '../../../redux/features/menuActive';

type CurrencyType = {
	mainCurrency: string;
	toCurrency: string;
	rate: number;
	id: number;
};

const CurrencyRules = () => {
	
	const dispatch = useDispatch();
	dispatch(setMenuActive('seven'));
	/* const param = {
		country: ''
	}; */
	const getMuiTheme = () =>
	createTheme({
		components: {
			MuiPaper: {
				styleOverrides: {
					root: {
						boxShadow: 'none !important',
						backgroundColor: 'transparent',
						cursor: 'default !important',
					},
					elevation: {
						background: '#fafbfc'
					}
				}
			},
			MUIDataTable: {
				styleOverrides: {
					root: {
						borderRadius: '10px',
						margin: '0px 10px',
						cursor: 'default !important',
					}
				}
			},
			MuiInputBase: {
				styleOverrides: {
					root: { width: '109px', height: '35px' }
				}
			},
			MuiButton: {
				styleOverrides: {
					root: { marginRight: '10px' }
				}
			},
			MuiTableRow: {
				styleOverrides: {
					root: {cursor: 'default !important', '&:hover': { cursor: 'default !important', backgroundColor: 'transparent !important' } },
					head: {cursor: 'default !important',  '&:hover': { cursor: 'default !important', backgroundColor: 'transparent !important' } },
					footer: {cursor: 'default !important',  '&:hover': { cursor: 'default !important', backgroundColor: 'transparent !important' } }
				}
			},
			MUIDataTableBodyCell: {
				styleOverrides: {
					stackedCommon: {
						paddingTop: '10px',
						borderBottom: '0',
						height: '45px',
						justifyContent: 'center !important',
						display: 'flex',
						borderLeft: `1px solid ${colors.colorFillPrimaryRedHover}`,
						whiteSpace: 'normal',
						textOverflow: 'ellipsis',
						overflow: 'hidden'
					},
					stackedHeader: {
						display: 'none !important'
					},
					root: {
						textAlign: 'center',
						color: colors.colorFillPrimaryBlue
					}
				}
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						position: 'relative',
						borderBottom: '0',
						padding: '10px 0px !important',
						minWidth: '120px',
						maxWidth: '300px',
						width: '300px',
						verticalAlign: 'bottom'
					}
				}
			},
			MuiOutlinedInput: {
				styleOverrides: {
					root: {
						height: '35px',
						width: '80%',
						justifyContent: 'center',
						alignSelf: 'center'
					}
				}
			},
			MUIDataTableToolbar: {
				styleOverrides: {
					root: {
						color: colors.colorFillPrimaryBlue
					}
				}
			},
			MUIDataTableBodyRow: {
				styleOverrides: {
					root: {
						cursor: 'default !important', '&:hover': { cursor: 'default !important', backgroundColor: 'transparent' },
						boxShadow: 'rgb(221, 222, 223) 5px 5px 5px'
					}
				}
			},
			MuiTablePagination: {
				styleOverrides: {
					selectLabel: {
						marginTop: 'auto'
					},
					displayedRows: {
						marginTop: 'auto'
					}
				}
			},
			MuiList: {
				styleOverrides: {
					root: {
						backgroundColor: 'white'
					}
				}
			},
			MUIDataTableSelectCell: {
				styleOverrides: {
					headerCell: {
						backgroundColor: 'transparent'
					},
					root: {
						width: '50px !important',
						maxWidth: '50px !important'
					}
				}
			},
			MuiSvgIcon: {
				styleOverrides: {
					root: {
						':hover': {
							cursor: 'pointer'
						},
						color: '#04246a'
					}
				}
			}
		}
	});
	const [currencyData, setCurrencyData] = useState<CurrencyType[]>([]);
	const [currencyObject, setCurrencyObject] = useState<CurrencyType>({
		mainCurrency: '',
		toCurrency: '',
		rate: 0,
		id: -1
	});
	//const [openAddPopover, setOpenAddPopover] = useState(false);
	const [openEditPopover, setOpenEditPopover] = useState(false);

	const onEditRule = (tableMeta: any) => {
		setCurrencyObject((prev) => currencyData[tableMeta.rowIndex] ?? prev);
		setOpenEditPopover(true);
	};

	/*const addVatRule = () => {
		setVatObject({ country: '', vat: '' });

		setOpenAddPopover(true);
	};*/

	const updateVatCountryData = (newCurrency: CurrencyType) => {
		axios
			.patch('/api/container/rates/' + newCurrency.id, { rate: newCurrency.rate })
			.then(() => getData());
	};

	/** get data for table with pagination */
	const getData = () => {
		axios
			.get('/api/container/rates')
			.then((response) => {
				setCurrencyData(response.data);
			})
			.catch(() => {
				setCurrencyData([]);
			});
	};

	// Debounce the API call with a delay of 100 milliseconds
	const debouncedGetData = debounce(getData, 100);
	useEffect(() => {
		debouncedGetData();
	}, []);

	/*const addVatCountryData = (newCurrency: {
		mainCurrency: string;
		toCurrency: string;
		conversionRatio: number;
	}) => {
		setCurrencyData([...currencyData, newCurrency]);
	};*/

	/** column definition */
	const columnDefs = [
		{
			label: 'Main Currency',
			name: 'mainCurrency',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '')
			}
		},
		{
			label: 'To Currency',
			name: 'toCurrency',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '')
			}
		},
		{
			label: 'Rate',
			name: 'rate',
			options: {
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '')
			}
		},
		{
			label: 'Manage',
			name: '',
			options: {
				customBodyRender: (value: any, tableMeta: any, updateValue: any) => {
					return (
						<EditIcon
							onClick={(e) => {
								e.stopPropagation();
								onEditRule(tableMeta);
							}}
						/>
					);
				},
				customHeadRender: (columnMeta: MUIDataTableCustomHeadRenderer) =>
					noSelectHeaderTemplate(columnMeta.label || '')
			}
		}
	];

	const options = {
		selectableRows: 'none' as SelectableRows,
		viewColumns: false,
		print: false,
		download: false,
		search: false,
		filter: false
	};

	return (
		<>
			<AdminLayout>
				<ThemeProvider
					theme={createTheme({
						components: {
							MuiPopover: {
								styleOverrides: {
									paper: {
										borderRadius: '20px',
										border: '1px solid #04246a',
										padding: '10px'
									}
								}
							}
						}
					})}
				>
					{/* 					<Popover
						open={openAddPopover}
						onClose={() => setOpenAddPopover(false)}
						anchorReference={'none'}
						disableAutoFocus={true}
						disableEnforceFocus={true}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<UpdateCurrency
							open={openAddPopover}
							setOpen={setOpenAddPopover}
							vatObject={vatObject}
							updateVatCountryData={addCountryData}
							action={'Add'}
						/>
					</Popover> */}
					<Popover
						open={openEditPopover}
						onClose={() => setOpenEditPopover(false)}
						anchorReference={'none'}
						disableAutoFocus={true}
						disableEnforceFocus={true}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center'
						}}
					>
						<UpdateCurrency
							open={openEditPopover}
							setOpen={setOpenEditPopover}
							vatObject={currencyObject}
							updateVatCountryData={updateVatCountryData}
							action={'Edit'}
						/>
					</Popover>
				</ThemeProvider>
				<div>
					{/* Title */}
					<div>
						<p style={{ fontSize: '22px', fontWeight: '600', color: '#04246a' }}>
							Currency Rules
						</p>
					</div>
					<div>
						<ThemeProvider theme={getMuiTheme()}>
							<MUIDataTable
								title=""
								data={currencyData}
								columns={columnDefs}
								options={options}
							/>
						</ThemeProvider>
					</div>
				</div>
			</AdminLayout>
		</>
	);
};

export default CurrencyRules;
